/**
 * Adds shadows below the header element
 */
export const headerShadow = () => {
  return `
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 40px rgb(0 0 0 / 80%);
      bottom: 20%;
      width: 90%;
      height: 10%;
      border-radius: 100%;
    }

    &:before {
      content: "";
      z-index: 0;
      position: absolute;
      background-color: #FFFFFF;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  `;
};
