import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { IApplicationV3Entity } from "models/ApplicationV3.model";
import {
  FetchApplicationV3ByApplicationNumberAndAuthorityArgs,
  FetchPaginatedApplicationsV3Args,
} from "services/application-v3/ApplicationV3Service.types";
import { createDeepEqualSelector } from "store/utils";
import { ParticipantAccessLevel, rankAccessLevel } from "models/Participant.model";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import { batch } from "react-redux";
import { removeApplicationTemplate } from "store/domain-data/application-template/applicationTemplate";
import { removeParticipantsByApplicationId } from "store/domain-data/participant/participant";
import { removeApplicationDocumentContainerRelationsByApplicationId } from "store/domain-data/application-document-containers-relation/applicationDocumentContainersRelation";
import { removeApplicationDocumentRelationsByApplicationId } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { removeApplicationStepRelationsByApplicationId } from "store/domain-data/application-step-relation/applicationStepRelation";
import { removeApplicationStepDataByApplicationId } from "store/domain-data/application-step-data/applicationStepData";
import { removeApplicationValidationResultsByApplicationId } from "store/domain-data/application-validation-result/applicationValidationResult";
import { DEFAULT_PAGE_SIZE } from "constants/configs";
import { first } from "lodash";

// Entity Adapter

const applicationV3Adapter = createEntityAdapter<IApplicationV3Entity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    return b.modifiedDate.localeCompare(a.modifiedDate);
  },
});

// Action & Thunks

export const loadApplications = createAction<IApplicationV3Entity[]>("domainData/applicationV3/loadApplications");
export const loadApplication = createAction<IApplicationV3Entity>("domainData/applicationV3/loadApplication");

export const fetchPaginatedOutstandingApplicationsV3ForCurrentUser = createAsyncThunk(
  "domainData/applicationV3/fetchPaginatedOutstandingApplicationsV3ForCurrentUser",
  async (args: FetchPaginatedApplicationsV3Args, thunkAPI) => {
    const applicationV3Service = serviceContainer.cradle.applicationV3Service;
    const { applications } = await applicationV3Service.fetchPaginatedOutstandingApplicationsV3ForCurrentUser(args);
    thunkAPI.dispatch(loadApplications(applications));
    return applications;
  }
);

export const fetchPaginatedApplicationsV3ForCurrentUser = createAsyncThunk(
  "domainData/applicationV3/fetchPaginatedApplicationsV3ForCurrentUser",
  async (args: FetchPaginatedApplicationsV3Args, thunkAPI) => {
    const applicationV3Service = serviceContainer.cradle.applicationV3Service;
    const { applications } = await applicationV3Service.fetchPaginatedApplicationsV3ForCurrentUser(args);
    thunkAPI.dispatch(loadApplications(applications));
    return applications;
  }
);

export const fetchPaginatedChildApplicationsV3ForCurrentUser = createAsyncThunk(
  "domainData/applicationV3/fetchPaginatedChildApplicationsV3ForCurrentUser",
  async (args: FetchPaginatedApplicationsV3Args, thunkAPI) => {
    const applicationV3Service = serviceContainer.cradle.applicationV3Service;
    const { applications } = await applicationV3Service.fetchPaginatedChildApplicationsV3ForCurrentUser(args);
    thunkAPI.dispatch(loadApplications(applications));
    return applications;
  }
);

export const deleteApplicationV3ById = createAsyncThunk(
  "domainData/applicationV3/deleteApplication",
  async (applicationId: number, thunkAPI) => {
    await serviceContainer.cradle.applicationV3Service.deleteApplicationV3ById(applicationId);
    batch(() => {
      thunkAPI.dispatch(removeApplicationTemplate(applicationId));
      thunkAPI.dispatch(removeParticipantsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationDocumentContainerRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationDocumentRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationStepRelationsByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationStepDataByApplicationId(applicationId));
      thunkAPI.dispatch(removeApplicationValidationResultsByApplicationId(applicationId));
    });
    return applicationId;
  }
);

export const fetchApplicationV3ByApplicationNumberAndAuthority = createAsyncThunk(
  "domainData/applicationV3/fetchApplicationV3ByApplicationNumberAndAuthority",
  async (args: FetchApplicationV3ByApplicationNumberAndAuthorityArgs) => {
    const { applicationNumber, authorityId } = args;

    const applicationV3Service = serviceContainer.cradle.applicationV3Service;
    const { applications } = await applicationV3Service.fetchPaginatedApplicationsV3ForCurrentUser({
      pageIndex: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      searchText: applicationNumber,
      authorities: [authorityId.toString()],
    });

    // Filter applications
    const filteredApplications = applications.filter(
      (app) =>
        (applicationNumber.startsWith("BLD")
          ? app.applicationNumber === applicationNumber
          : app.consentNumber === applicationNumber) && app.authorityId === authorityId
    );

    // Select parent application
    let selectedApplication = filteredApplications.find((app) => app.hasChildApplications);
    if (!selectedApplication) {
      selectedApplication = first(filteredApplications);
    }

    return selectedApplication;
  }
);

// Reducer
export const defaultApplicationState = applicationV3Adapter.getInitialState();

export const applicationV3Reducer = createReducer<typeof defaultApplicationState>(defaultApplicationState, (builder) =>
  builder
    .addCase(loadApplications, applicationV3Adapter.upsertMany)
    .addCase(loadApplication, applicationV3Adapter.upsertOne)
    .addCase(deleteApplicationV3ById.fulfilled, applicationV3Adapter.removeOne)
);

// Selectors
export const {
  selectById: selectApplicationV3EntityById,
  selectIds: selectApplicationV3EntityIds,
  selectEntities: selectApplicationsV3Entities,
  selectAll: selectAllApplicationV3Entities,
  selectTotal: selectTotalApplicationV3Entities,
} = applicationV3Adapter.getSelectors((state: RootState) => state.domainData.applicationV3);

export const selectChildApplicationByApplicationId = createDeepEqualSelector(
  [selectAllApplicationV3Entities, (state: RootState, applicationId: number) => applicationId],
  (entities, applicationId) => {
    return entities.filter((entity) => entity.parentApplicationId === applicationId).map((entity) => entity.id);
  }
);

export const selectCanUserManageApplicationV3ById = createDeepEqualSelector(
  [selectApplicationV3EntityById],
  (application) => {
    if (!application) {
      return false;
    }

    return rankAccessLevel(application.accessLevel) >= rankAccessLevel(ParticipantAccessLevel.Manage);
  }
);

export const selectCanEditApplicationV3FormForApplication = createDeepEqualSelector(
  [selectApplicationV3EntityById],
  (application) => {
    const allowedStatuses = [ApplicationStatus.Draft, ApplicationStatus.VRFISuspended];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectCanDeleteApplicationV3 = createDeepEqualSelector([selectApplicationV3EntityById], (application) => {
  if (!application) {
    return false;
  }

  return application.status === ApplicationStatus.Draft;
});

export const selectCanDuplicateApplicationV3 = createDeepEqualSelector(
  [selectApplicationV3EntityById],
  (application) => {
    const allowedStatuses = [
      ApplicationStatus.Draft,
      ApplicationStatus.Submitting,
      ApplicationStatus.Submitted,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
      ApplicationStatus.Completed,
      ApplicationStatus.Refused,
      ApplicationStatus.Rejected,
      ApplicationStatus.Lapsed,
      ApplicationStatus.Withdrawn,
    ];

    if (!application) {
      return false;
    }

    return allowedStatuses.includes(application.status);
  }
);

export const selectIsApplicationV3Migrated = createDeepEqualSelector([selectApplicationV3EntityById], (application) => {
  if (!application) {
    return false;
  }

  return application.isMigrated;
});

export const selectIsApplicationV3RequiresAttention = createDeepEqualSelector(
  [selectApplicationV3EntityById],
  (application) => {
    if (!application) {
      return false;
    }

    return Boolean(application.hasOutstandingPayments || application.hasOutstandingRFIs);
  }
);
