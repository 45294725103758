import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import FlexBox from "components/FlexBox/FlexBox";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import { useStoreSelector } from "store/hooks";
import { useFormikContext } from "formik";
import { IQualificationEntity, QualificationUtil } from "models/Qualification.model";
import {
  selectAllQualificationTypeEntities,
  selectLicensingClassesByQualificationName,
} from "store/domain-data/qualification-type/qualificationType";
import toString from "lodash/toString";
import isEmpty from "lodash/isEmpty";
import { useStore } from "react-redux";
import { RootState } from "store/types";
import { QualificationFormField } from "components/ParticipantEditDialog/models/QualificationFormField";
import uniq from "lodash/uniq";
import { QualificationViewContext } from "components/ParticipantEditDialog/contexts/QualificationViewContext";
import { SelectChangeEvent } from "@mui/material/Select";

type Props = {};

const ParticipantQualificationTypeSelect: React.FC<Props> = () => {
  const { t } = useTranslation();
  const store = useStore<RootState>();
  const { setQualificationName, currentQualifications } = React.useContext(QualificationViewContext);

  const { values, handleChange, setFieldValue } = useFormikContext<IQualificationEntity>();

  const qualificationTypes = useStoreSelector(selectAllQualificationTypeEntities);

  const filteredQualificationTypes = React.useMemo(() => {
    const qualificationNames = uniq(
      currentQualifications.map((currentQualification) => currentQualification.qualificationName)
    );
    return qualificationTypes.filter((qualificationType) => {
      return qualificationType.name === "Other" || !qualificationNames.includes(qualificationType.name);
    });
  }, [qualificationTypes, currentQualifications]);

  const handleChangeQualificationType = React.useCallback(
    (event: SelectChangeEvent<unknown>) => {
      // TODO: Fix typing of select change event to string;
      const newValue = toString(event.target.value);
      setFieldValue(QualificationFormField.QualificationType, newValue);

      // Cascade change other form fields based on QualificationFormField.QualificationType change
      const availableLicensingClasses = selectLicensingClassesByQualificationName(store.getState(), newValue);
      if (isEmpty(availableLicensingClasses)) {
        setFieldValue(QualificationFormField.LicensingClasses, []);
      }
      if (!QualificationUtil.isOtherQualification(newValue)) {
        setFieldValue(QualificationFormField.OtherQualificationType, "");
      }

      handleChange(event);
      setQualificationName(newValue);
    },
    [setFieldValue, store, handleChange, setQualificationName]
  );

  return (
    <FlexBox direction="column" spacing={2} flexGrow={1}>
      <StyledHeader>{t(`Add a license, qualification or registration`)}</StyledHeader>
      <StyledSelect
        data-testid={"ParticipantQualificationTypeSelect"}
        name={QualificationFormField.QualificationType}
        value={values.qualificationName}
        onChange={handleChangeQualificationType}
        aria-label={"Qualification type"}
      >
        {filteredQualificationTypes.map((qualificationType) => (
          <MenuItem key={qualificationType.name} title={qualificationType.displayName} value={qualificationType.name}>
            {qualificationType.displayName}
          </MenuItem>
        ))}
      </StyledSelect>
    </FlexBox>
  );
};

const StyledHeader = styled("span")(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledSelect = styled(Select)(
  ({ theme }) => css`
    .MuiSelect-root {
      color: ${theme.palette.objective.dark.night};
    }
  `
);

export default ParticipantQualificationTypeSelect;
