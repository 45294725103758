import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import { Form, Formik } from "formik";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import FlexBox from "components/FlexBox/FlexBox";
import { styled } from "@mui/material/styles";
import { useToast } from "hooks/useToast";
import { unwrapResult } from "@reduxjs/toolkit";
import { useStoreDispatch } from "store/hooks";
import ImageUploadDropzone from "components/Dropzone/ImageUploadDropzone";
import isEqual from "lodash/isEqual";
import { createApplicationTemplateFromApplication } from "store/domain-data/application/application";
import { IEncodedImageFile } from "models/EncodedImageFile.model";
import FormikTextArea from "components/FormikTextArea/FormikTextArea";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import { usePostHog } from "posthog-js/react";
import { useEventStopPropCallback } from "hooks/useEventStopPropCallback";
import { css } from "@emotion/react";

type Props = {
  applicationId?: number;
  onClose: () => any;
};

const applicationTemplateFormSchema = yup.object({
  name: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
  description: yup.string(),
});

type ApplicationTemplateFormValues = yup.Asserts<typeof applicationTemplateFormSchema>;

const NewApplicationTemplateDialog: React.FC<Props> = ({ onClose, applicationId }) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = useToast();
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();

  const defaultApplicationTemplateValues: ApplicationTemplateFormValues = React.useMemo(() => {
    return {
      name: "",
      description: "",
    };
  }, []);

  const [templateThumbnail, setTemplateThumbnail] = React.useState<IEncodedImageFile | null>();
  const eventStopPropCallback = useEventStopPropCallback();

  const isSaveDisabled = React.useCallback(
    (isSubmitting: boolean, isValid: boolean, values: ApplicationTemplateFormValues) => {
      return isSubmitting || !isValid || isEqual(defaultApplicationTemplateValues, values);
    },
    [defaultApplicationTemplateValues]
  );

  const handleSubmit = React.useCallback(
    async (values: ApplicationTemplateFormValues) => {
      try {
        if (!applicationId) {
          throw new Error("Not supported yet");
        }

        await dispatch(
          createApplicationTemplateFromApplication({
            applicationId: applicationId,
            templateName: values.name,
            templateDescription: values.description,
            templateThumbnail: templateThumbnail?.base64Image,
            templateThumbnailName: templateThumbnail?.name,
          })
        ).then(unwrapResult);
        posthog?.capture(`user created-application-template`);
        toastSuccess(t(`A new template has been created based on your selected application`));
        onClose();
      } catch (e) {
        toastError(t(`Failed to create template`));
      }
    },
    [
      applicationId,
      dispatch,
      onClose,
      posthog,
      t,
      templateThumbnail?.base64Image,
      templateThumbnail?.name,
      toastError,
      toastSuccess,
    ]
  );

  return (
    <Dialog
      title={t(`Save a new template`)}
      open={true}
      onClose={onClose}
      data-testid={"NewApplicationTemplateDialog"}
      onClick={eventStopPropCallback}
    >
      <Formik<ApplicationTemplateFormValues>
        initialValues={defaultApplicationTemplateValues}
        validationSchema={applicationTemplateFormSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, values, isSubmitting }) => (
          <Form>
            <DialogContent>
              <FlexBox spacing={6} direction={"column"}>
                <FormikTextInput label={"Name"} fieldName={"name"} required={true} />
                <FormikTextArea label={"Description"} fieldName={"description"} rows={3} />

                <FlexBox direction={"column"} spacing={4}>
                  <StyledThumbnailTitle>{t(`Upload template thumbnail`)}</StyledThumbnailTitle>
                  <ImageUploadDropzone
                    maxSize={25 * 1024 * 1024}
                    onImageChange={setTemplateThumbnail}
                    scaleImageMaxWidth={250}
                    scaleImageMaxHeight={150}
                  />
                </FlexBox>
              </FlexBox>
            </DialogContent>
            <DialogActions>
              <DialogActionCancel onClick={onClose} data-testid={"CancelButton"}>
                {t(`Cancel`)}
              </DialogActionCancel>

              <DialogActionPrimary
                type="submit"
                data-testid={"ConfirmButton"}
                disabled={isSaveDisabled(isSubmitting, isValid, values)}
              >
                {t(`Save`)}
              </DialogActionPrimary>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const StyledThumbnailTitle = styled("div")(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 16px;
  `
);

export default NewApplicationTemplateDialog;
