import React from "react";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { styled } from "@mui/material/styles";
import { IconButton, IconButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import { css } from "@emotion/react";

type Props = IconButtonProps & {
  icon?: typeof ODL_ICONS[keyof typeof ODL_ICONS];
  rectSize?: number;
  "data-testid"?: string;
};

const MenuTriggerIconButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <StyledIconButton
      onClick={props.onClick}
      aria-label={props["aria-label"] || t(`Actions`)}
      data-testid={props["data-testid"] || "MenuTriggerIconButton"}
      disableRipple={true}
      $rectSize={props.rectSize}
      {...omit(props, ["icon", "data-testid", "aria-label", "onClick", "rectSize"])}
    >
      <StyledFontIcon name={props.icon || "i-navigation-overflow-menu-horizontal"} />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== "$rectSize" })<{
  $rectSize: Props["rectSize"];
}>(
  ({ theme, $rectSize = 44 }) => css`
    height: ${$rectSize}px;
    width: ${$rectSize}px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.text.primary};
    font-size: 20px;
  `
);

export default MenuTriggerIconButton;
