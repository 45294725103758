import React from "react";
import ApplicantGuide from "pages/authentication/ApplicantGuide";
import Spacer from "components/Spacer/Spacer";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Announcements from "pages/authentication/Announcements";
import { AnnouncementLocation } from "models/Announcement.model";
import LogoImage from "assets/images/logo-dark.svg";
import { css } from "@emotion/react";

const AuthenticationPage: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <StyledPageContainer data-testid="LoginPage" component="main">
      <StyledApplicantGuideContainer data-testid="ApplicantGuideContainer">
        <ApplicantGuide />
      </StyledApplicantGuideContainer>

      <StyledAuthenticationSectionContainer data-testid="AuthenticationSectionContainer">
        <StyledAuthenticationSection data-testid="AuthenticationSection">
          <StyledLogoContainer aria-label={t(`Objective Build`)}>
            <StyledLogoImage src={LogoImage} alt={"Objective logo"} />
          </StyledLogoContainer>
          <Spacer y={7} />
          {children && <StyledChildSectionContainer>{children}</StyledChildSectionContainer>}
          <Spacer y={7} />
          <StyledAnnouncementsContainer>
            <Announcements location={AnnouncementLocation.Authentication} />
          </StyledAnnouncementsContainer>
        </StyledAuthenticationSection>
      </StyledAuthenticationSectionContainer>
    </StyledPageContainer>
  );
};

const StyledPageContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: stretch;
  `
);

const StyledAnnouncementsContainer = styled("div")(
  ({ theme }) => css`
    max-width: 500px;
  `
);

const StyledApplicantGuideContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    justify-content: center;
    align-items: stretch;

    // Applicant guide will only display in desktop
    ${theme.breakpoints.down("lg")} {
      display: none;
    }
  `
);

const StyledAuthenticationSectionContainer = styled("div")(
  ({ theme }) => css`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    background-color: ${theme.palette.objective.dark.night};
  `
);

const StyledAuthenticationSection = styled("div")(
  ({ theme }) => css`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  `
);

const StyledLogoContainer = styled("h1")(
  ({ theme }) => css`
    width: 256px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
);

const StyledChildSectionContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    border-radius: 0;
    box-shadow: ${theme.shadows[3]};
    background-color: ${theme.palette.background.paper};
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 32px;
    padding-top: 12px;

    ${theme.breakpoints.up("sm")} {
      width: 500px;
      border-radius: 6px;
    }
  `
);

const StyledLogoImage = styled("img")(
  ({ theme }) => css`
    cursor: pointer;
  `
);

export default AuthenticationPage;
