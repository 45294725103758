import { css } from "@emotion/react";
import { defaultTheme } from "styles/defaultTheme";

/**
 * CSS mixin for disabled styles
 *
 * @param disabled
 */
export const disabledMixin = (disabled: boolean = false) => {
  if (!disabled) {
    return ``;
  }

  return css`
    border: none !important;
    color: ${defaultTheme.palette.objective.light.allspice} !important;
    background-color: ${defaultTheme.palette.objective.light.day} !important;
  `;
};
