import { CONVERT_IMAGE_TO_PDF_ENABLED, DOCUMENT_CONTAINER_UPLOAD_CONFIG } from "constants/configs";
import React from "react";
import { prettyBytes } from "utils/prettyBytes";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";

const SupportedFileInformation: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <StyledSupportedFileInformation>
      {CONVERT_IMAGE_TO_PDF_ENABLED && (
        <StyledContent>
          <p>
            {t(`JPG, PNG and PDF are supported.`)} {t(`JPG and PNG are converted to PDF on upload.`)}
          </p>
          <p>
            {t(`Maximum file size is {{fileSize}}.`, {
              fileSize: prettyBytes(DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MAX_FILE_SIZE),
            })}
          </p>
        </StyledContent>
      )}
      {!CONVERT_IMAGE_TO_PDF_ENABLED && (
        <StyledContent>
          <p>
            {t(`Only PDF files are supported.`)}
            {t(`Maximum file size is {{fileSize}}.`, {
              fileSize: prettyBytes(DOCUMENT_CONTAINER_UPLOAD_CONFIG.ACCEPT_MAX_FILE_SIZE),
            })}
          </p>
        </StyledContent>
      )}
    </StyledSupportedFileInformation>
  );
};

const StyledSupportedFileInformation = styled("div")(
  ({ theme }) => css`
    margin-top: 12px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  `
);

const StyledContent = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("4px")}
  `
);

export default SupportedFileInformation;
