import React from "react";
import { List, useTheme } from "@mui/material";
import NotificationSubscriptionLevelListItem from "components/NotificationSubscriptionLevelDialog/NotificationSubscriptionLevelListItem";
import { styled } from "@mui/material/styles";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import { useTranslation } from "react-i18next";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  selectParticipantUserByApplicationId,
  updateApplicationNotificationSubscriptionLevel,
} from "store/domain-data/participant/participant";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { css } from "@emotion/react";
import { ReactComponent as TimeNormalIcon } from "assets/images/time-normal-icon.svg";
import { ReactComponent as TimeMutedIcon } from "assets/images/time-muted-icon.svg";

type Props = {
  applicationId: number;
  onClose: () => any;
};

const NotificationSubscriptionLevelDialog: React.FC<Props> = ({ applicationId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastError, toastSuccess } = useToast();
  const theme = useTheme();

  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const myParticipant = useStoreSelector((state) => selectParticipantUserByApplicationId(state, applicationId));

  const updateSubscriptionLevel = React.useCallback(
    async (level: NotificationLevel) => {
      if (!myParticipant) {
        return;
      }

      try {
        await dispatch(
          updateApplicationNotificationSubscriptionLevel({
            applicationId: applicationId,
            level: level,
            participantId: myParticipant.id,
          })
        ).then(unwrapResult);
        toastSuccess(t(`Notification subscription level has been updated`));
        onClose();
      } catch (e) {
        toastError(t(`Failed to update notification subscription level`));
      }
    },
    [dispatch, applicationId, myParticipant, toastError, toastSuccess, t, onClose]
  );

  return (
    <Dialog
      open={true}
      title={t(`Set notification level for application {{applicationNumber}}`, {
        applicationNumber: application?.applicationNumber,
      })}
      onClose={onClose}
      data-testid={"NotificationSubscriptionLevelDialog"}
    >
      <StyledDialogContent>
        <StyledList>
          <NotificationSubscriptionLevelListItem
            title={t(`Watching`)}
            description={t(`You will be notified of only key status updates or when mentioned`)}
            icon={"i-time-alarm"}
            selected={NotificationLevel.Watching === application?.notificationLevel}
            onSelect={() => updateSubscriptionLevel(NotificationLevel.Watching)}
            data-testid={"NotificationSubscriptionLevelItemWatching"}
            button={true}
          />
          <NotificationSubscriptionLevelListItem
            title={t(`Normal`)}
            description={t(`You will be notified of every update`)}
            icon={<TimeNormalIcon width={28} height={28} fill={theme.palette.objective.dark.night} />}
            selected={NotificationLevel.Normal === application?.notificationLevel}
            onSelect={() => updateSubscriptionLevel(NotificationLevel.Normal)}
            dataTestId={"NotificationSubscriptionLevelItemNormal"}
            button={true}
          />
          <NotificationSubscriptionLevelListItem
            title={t(`Muted`)}
            description={t(`You will never be notified of anything`)}
            icon={<TimeMutedIcon width={28} height={28} fill={theme.palette.objective.dark.night} />}
            selected={NotificationLevel.Muted === application?.notificationLevel}
            onSelect={() => updateSubscriptionLevel(NotificationLevel.Muted)}
            dataTestId={"NotificationSubscriptionLevelItemMuted"}
            button={true}
          />
        </StyledList>
      </StyledDialogContent>
    </Dialog>
  );
};

const StyledList = styled(List)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 0;
  `
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    padding: 0;
  `
);

export default NotificationSubscriptionLevelDialog;
