import React from "react";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import { cloneDeep } from "lodash";
import { css } from "@emotion/react";
import { Chip } from "@mui/material";

type LbpLicenseClassTagProps = {
  displayName: string;
  name: string;
};

const LbpLicenseClassTag: React.FC<LbpLicenseClassTagProps> = ({ displayName, name }) => {
  const { values, setFieldValue } = useFormikContext<IQualificationEntity>();

  const isSelected = React.useMemo(() => {
    return values.licensingClasses.includes(name);
  }, [name, values.licensingClasses]);

  const handleLicenseTagClicked = React.useCallback(() => {
    let licenseValues = cloneDeep(values.licensingClasses);
    if (licenseValues.includes(name)) {
      licenseValues = licenseValues.filter((val) => val !== name);
    } else {
      licenseValues.push(name);
    }
    setFieldValue("licensingClasses", licenseValues);
  }, [name, setFieldValue, values.licensingClasses]);

  return (
    <StyledLicenseClassTag
      onClick={handleLicenseTagClicked}
      variant={isSelected ? "filled" : "outlined"}
      title={displayName}
      label={displayName}
      $isSelected={isSelected}
      data-testid={"LbpLicenseClassTag"}
      aria-pressed={isSelected}
      {...(isSelected ? { onDelete: handleLicenseTagClicked, color: "blue" } : {})}
    />
  );
};

const StyledLicenseClassTag = styled(Chip, { shouldForwardProp: (prop) => prop !== "$isSelected" })<{
  $isSelected: boolean;
}>(
  ({ theme, $isSelected }) => css`
    &:hover {
      color: ${theme.palette.grey[900]};
      background-color: ${theme.palette.grey[400]} !important;
    }

    ${$isSelected &&
    css`
      color: ${theme.palette.text.primary};
    `}
  `
);

export default LbpLicenseClassTag;
