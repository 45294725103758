import React from "react";
import { Fade, Tooltip, TooltipProps } from "@mui/material";
import { withStyles } from "@mui/styles";

const ActionItemTooltip: React.FC<TooltipProps> = ({ children, title, ...tooltipProps }) => {
  return (
    <StyledTooltip title={title} arrow={true} TransitionComponent={Fade} {...tooltipProps}>
      <span>{children}</span>
    </StyledTooltip>
  );
};

const StyledTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.objective.light.white,
  },
  tooltip: {
    backgroundColor: theme.palette.objective.light.white,
    boxShadow:
      "0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)",
    color: theme.palette.text.primary,
    padding: "16px",
    maxWidth: 500,
  },
  popper: {
    maxWidth: 500,
  },
}))(Tooltip);

export default ActionItemTooltip;
