import React from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { css } from "@emotion/react";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";

type Props = {
  displayName: string;
  isLocked: boolean;
  isClickable: boolean;
  onClick?: React.MouseEventHandler;
};

const ParticipantTypeTagStatic: React.FC<Props> = ({ displayName, isLocked, isClickable, onClick, ...otherProps }) => {
  return (
    <StyledChip
      label={displayName}
      color={"blue"}
      clickable={isClickable}
      $isClickable={isClickable}
      onClick={onClick}
      data-testid={"ParticipantTypeTag"}
      {...(isLocked
        ? { variant: "outlined", deleteIcon: <StyledFontIcon name="i-toggle-locked" />, onDelete: onClick }
        : {})}
    />
  );
};

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: #0f307a !important;
    font-weight: 600;
  `
);

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "$isClickable",
})<{
  $isClickable: boolean;
}>(
  ({ theme, $isClickable }) => css`
    padding: 16px 12px;
    cursor: ${$isClickable ? "pointer" : "default"};
    ${theme.mixins.flexGap("8px")};
  `
);

export default ParticipantTypeTagStatic;
