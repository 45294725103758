// Color palette should align with ODLv2
// Refer: https://www.figma.com/file/B31nweVy3o7NJhEGH7Pmud/ODL-Pro-Light-Colours?node-id=0%3A1
export const objectivePalette = {
  dark: {
    night: "#32373F",
    fiordland: "#525965",
    neutral: "#707070",
    neutral2: "#ACACAC",
    black: "#000000",
  },
  light: {
    allspice: "#D1D1D1",
    taro: "#E8E8E8",
    mid: "#EFEFEF",
    day: "#F5F5F5",
    daylight: "#F7F7F7",
    white: "#FFFFFF",
  },
  blue: {
    main: "#3560C1",
    dark: "#00277F",
    darker: "#0138A7",
    mid: "#65A9D0",
    light: "#E0F3FE",
    tonal: "#DAE8FF",
    900: "#173553",
  },
  red: {
    main: "#D0000A",
    light: "#DE4D54",
    salmon: "#F3D7DA",
    900: "#752730",
  },
  green: {
    main: "#2A7D2A",
    light: "#4A914A",
    dark: "#0F4D0F",
    900: "#1F3815",
  },
  yellow: {
    main: "#F3AD2E",
    light: "#FBE6C0",
    900: "#735324",
  },
  orange: {
    ripeCarrot: "#C63906",
    freshCarrot: "#E95829",
  },
  purple: {
    main: "#9D4098",
  },
  brown: {
    main: "#E1D5C7",
    dark: "#4F3E34",
  },
  background: {
    freshMango: "#FEE094",
    objectiveYellowLight: "#FDEED3",
    freshCabbage: "#9BE1DC",
    objectiveCardamon: "#DAE3C1",
    freshBlueberry: "#DAD9FC",
    freshLime: "#A2EC9F",
    freshRaspberry: "#F7CAD7",
  },
  brandColors: {
    build: {
      legacyPrimary: "#5CA3FF",
      web: "#5DA10C",
      primary: "#5DA10C",
    },
    perform: {
      primary: "#BD2030",
      web: "#E95829",
    },
    keystone: {
      primary: "#E95829",
      web: "#C63906",
    },
    trapeze: {
      primary: "#F3AD2E",
      web: "#F3AD2E",
    },
    connect: {
      primary: "#4AAC51",
      web: "#4AAC51",
    },
    ecm: {
      primary: "#007DC3",
      web: "#007DC3",
    },
    inform: {
      primary: "#9D3F97",
      web: "#9D3F97",
    },
    teams: {
      primary: "#5558AF",
      web: "#5558AF",
    },
    office: {
      primary: "#D83B00",
      web: "#D83B00",
    },
  },
};
