import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormikContext } from "formik";
import { IQualificationEntity } from "models/Qualification.model";
import { QualificationViewContext } from "components/ParticipantEditDialog/contexts/QualificationViewContext";
import Guard from "components/Guard/Guard";
import { css } from "@emotion/react";

type LicenseFormButtonsProps = {};

const LicenseFormButtons: React.FC<LicenseFormButtonsProps> = () => {
  const { isValid, values } = useFormikContext<IQualificationEntity>();
  const { applyNewQualification, cancelNewQualification } = React.useContext(QualificationViewContext);

  const handleClick = React.useCallback(() => {
    applyNewQualification(values);
  }, [applyNewQualification, values]);

  return (
    <FlexBox justifyContent={"space-between"}>
      <Button onClick={cancelNewQualification}>Cancel</Button>
      <Guard condition={values.qualificationName}>
        <StyledButton variant="contained" onClick={handleClick} disabled={!isValid} aria-disabled={!isValid}>
          Apply license
        </StyledButton>
      </Guard>
    </FlexBox>
  );
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    background-color: ${theme.palette.objective.blue.tonal};
    color: ${theme.palette.objective.dark.black};
    &:hover {
      color: ${theme.palette.objective.light.white};
      background-color: ${theme.palette.objective.blue.mid};
    }
  `
);

export default LicenseFormButtons;
