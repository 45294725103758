import React from "react";
import { MenuItem as MuiMenuItem, MenuItemProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import Guard from "components/Guard/Guard";
import Spacer from "components/Spacer/Spacer";
import omit from "lodash/omit";
import toString from "lodash/toString";
import { css } from "@emotion/react";
import FlexBox from "components/FlexBox/FlexBox";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";

type Props = MenuItemProps & {
  /**
   * Name of icon:
   *   undefined - no icon
   *   empty string or invalid icon name - empty placeholder
   */
  icon?: typeof ODL_ICONS[keyof typeof ODL_ICONS] | JSX.Element;

  /**
   * Text of tooltip
   */
  tooltip?: string;
};

const MenuItem: React.FC<Props> = React.forwardRef(
  (
    {
      children,
      disabled = false,
      icon,
      tooltip = "",
      selected = false,
      onClick,
      onKeyPress,
      "aria-label": _ariaLabel,
      ...props
    },
    ref
  ) => {
    const handleClick = React.useCallback<React.MouseEventHandler<HTMLLIElement>>(
      (event) => {
        event.stopPropagation();

        if (disabled) {
          return;
        }

        if (onClick) {
          onClick(event);
        }
      },
      [disabled, onClick]
    );

    const handleKeyPress = React.useCallback<React.KeyboardEventHandler<HTMLLIElement>>(
      (event) => {
        event.stopPropagation();

        if (event.key === "Enter" || event.key === " ") {
          const el = event.currentTarget;
          if (el && typeof el.click === "function") {
            el.click();
          }
        }

        if (onKeyPress) {
          onKeyPress(event);
        }
      },
      [onKeyPress]
    );

    const ariaLabel = React.useMemo(() => {
      // If aria-label specified, use it
      if (_ariaLabel) {
        return _ariaLabel;
      }

      // If aria-label not specified, stringify children
      return toString(children);
    }, [_ariaLabel, children]);

    return (
      <StyledMuiMenuItem
        ref={ref}
        disableRipple={true}
        disableGutters={true}
        selected={selected}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        disabled={disabled}
        {...omit(props, ["autoFocus", "icon", "tooltip"])}
      >
        <HoverTooltip title={tooltip}>
          <FlexBox data-testid={"MenuItemContent"}>
            <Guard condition={typeof icon !== "undefined"}>
              <StyledIconContainer>
                {typeof icon === "string" && <FontIcon name={icon} fontSize={14} />}
                {typeof icon === "object" && icon}
              </StyledIconContainer>
              <Spacer x={2} />
            </Guard>

            <div>{children}</div>
          </FlexBox>
        </HoverTooltip>
      </StyledMuiMenuItem>
    );
  }
);

const StyledMuiMenuItem = styled(MuiMenuItem)(
  ({ theme, selected }) => css`
    min-width: 200px;
    min-height: 36px;
  `
);

const StyledIconContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  `
);

export default MenuItem;
