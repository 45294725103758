import React from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { css } from "@emotion/react";

type Props = {
  icon: typeof ODL_ICONS[keyof typeof ODL_ICONS];
  colour: string;
  iconColour: string;
  size?: number;
};

const ItemStatusIcon: React.FC<Props> = ({ icon, colour, iconColour, size = 24 }) => {
  return (
    <StyledStatusIconBackground $colour={colour} $size={size}>
      <StyledFontIcon name={icon} color={iconColour} $size={size} data-testid="FontIcon" data-icon={icon} />
    </StyledStatusIconBackground>
  );
};

const StyledFontIcon = styled(FontIcon, {
  shouldForwardProp: (prop) => prop !== "$size",
})<{ $size: number }>(
  ({ theme, $size }) => css`
    font-size: ${$size}px;
    width: ${$size}px;
    height: ${$size}px;
  `
);

const StyledStatusIconBackground = styled(Box, {
  shouldForwardProp: (prop) => !["$colour", "$size"].includes(prop.toString()),
})<{ $colour: string; $size: number }>(
  ({ theme, $colour, $size }) => css`
    width: calc(${$size}px * 2);
    height: calc(${$size}px * 2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${$colour};
    border-radius: 50%;
  `
);

export default ItemStatusIcon;
