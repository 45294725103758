import * as awilix from "awilix";
import { asClass, asFunction, asValue } from "awilix";
import { CognitoService } from "services/cognito/CognitoService";
import { developmentLogger } from "services/logger/developmentLogger";
import { productionLogger } from "services/logger/productionLogger";
import { AWS_COGNITO_USE_MOCK } from "constants/configs";
import { Cradle } from "services/serviceContainer.types";
import { I18nInitializer } from "services/i18n/I18nInitializer";
import { makeI18n } from "services/i18n/makeI18n";
import { I18nInitializerMock } from "services/i18n/I18nInitializer.mock";
import { JurisdictionService } from "services/jurisdiction/JurisdictionService";
import { FormIOService } from "services/formio/FormIOService";
import { DBImageService } from "services/db-image/DBImageService";
import { ApplicationService } from "services/application/ApplicationService";
import { ApplicationDocumentService } from "services/application-document/ApplicationDocumentService";
import { UserInfoService } from "services/user-info/UserInfoService";
import { ApiClient } from "services/api-client/ApiClient";
import { ContactService } from "services/contact/ContactService";
import { PropertyService } from "services/property/PropertyService";
import { AuthorityService } from "services/authority/AuthorityService";
import { ParticipantService } from "services/participant/ParticipantService";
import { DocumentTypeService } from "services/document-type/DocumentTypeService";
import { DocumentTagService } from "services/document-tag/DocumentTagService";
import { ApplicationValidationResultService } from "services/application-validation/ApplicationValidationResultService";
import { ParticipantTypeService } from "./participant-type/ParticipantTypeService";
import { AuthenticationService } from "services/authentication/AuthenticationService";
import { NZPostAddressCheckerService } from "services/address-checker/NZPostAddressCheckerService";
import { OrganisationMembersService } from "services/organisation-members/OrganisationMembersService";
import { OrganisationMemberInvitationService } from "services/organisation-member-invitation/OrganisationMemberInvitationService";
import { OrganisationMergeInvitationService } from "services/organisation-merge-invitation/OrganisationMergeInvitationService";
import { CountryService } from "services/country/CountryService";
import { OrganisationService } from "services/organisation/OrganisationService";
import { RFIPointService } from "services/rfi-point/RFIPointService";
import { DocumentService } from "services/document/DocumentService";
import { PaymentService } from "services/payment/PaymentService";
import { NotificationService } from "services/notifications/NotificationService";
import { NotificationUserProfileService } from "services/notifiation-user-profile/NotificationUserProfileService";
import { ICognitoService } from "services/cognito/CognitoService.types";
import { QualificationTypeService } from "services/qualification-type/QualificationTypeService";
import { ApplicationFilterService } from "services/application-filter/ApplicationFilterService";
import { RequiredDocumentsService } from "./required-documents/RequiredDocumentsService";
import { ApplicationV3Service } from "services/application-v3/ApplicationV3Service";
import { AnnouncementService } from "services/announcement/AnnouncementService";
import { MaintenanceAlertService } from "services/maintenance/MaintenanceAlertService";
import { ApplicationActionItemService } from "services/application-action-item/ApplicationActionItemService";
import { RegisteredApplicantInvitationService } from "services/registered-applicant-invitation/RegisteredApplicantInvitationService";
import { ParcelService } from "services/parcels/ParcelService";
import { PersonSearchService } from "./person-search/PersonSearchService";
import { ApplicationAccessRequestService } from "services/application-access-request/ApplicationAccessRequestService";

const serviceContainer = awilix.createContainer<Cradle>({
  injectionMode: awilix.InjectionMode.PROXY,
});

// Register default services
serviceContainer.register({
  cognitoService: asClass(CognitoService).singleton(),
  authenticationService: asClass(AuthenticationService).singleton(),
  i18n: asFunction(makeI18n).singleton(),
  i18nInitializer: asClass(I18nInitializer).singleton(),
  logger: asValue(productionLogger),
  jurisdictionService: asClass(JurisdictionService).singleton(),
  formIOService: asClass(FormIOService).singleton(),
  dbImageService: asClass(DBImageService).singleton(),
  applicationService: asClass(ApplicationService).singleton(),
  applicationV3Service: asClass(ApplicationV3Service).singleton(),
  applicationFilterService: asClass(ApplicationFilterService).singleton(),
  applicationDocumentService: asClass(ApplicationDocumentService).singleton(),
  announcementService: asClass(AnnouncementService).singleton(),
  documentService: asClass(DocumentService).singleton(),
  userInfoService: asClass(UserInfoService).singleton(),
  apiClient: asClass(ApiClient).singleton(),
  contactService: asClass(ContactService).singleton(),
  propertyService: asClass(PropertyService).singleton(),
  authorityService: asClass(AuthorityService).singleton(),
  participantService: asClass(ParticipantService).singleton(),
  participantTypeService: asClass(ParticipantTypeService).singleton(),
  qualificationTypeService: asClass(QualificationTypeService).singleton(),
  documentTypeService: asClass(DocumentTypeService).singleton(),
  documentTagService: asClass(DocumentTagService).singleton(),
  applicationValidationService: asClass(ApplicationValidationResultService).singleton(),
  applicationActionItemService: asClass(ApplicationActionItemService).singleton(),
  // TODO: Address checker service should be based on jurisdiction
  addressCheckerService: asClass(NZPostAddressCheckerService).singleton(),
  organisationMembersService: asClass(OrganisationMembersService).singleton(),
  organisationMemberInvitationService: asClass(OrganisationMemberInvitationService).singleton(),
  organisationMergeInvitationService: asClass(OrganisationMergeInvitationService).singleton(),
  countryService: asClass(CountryService).singleton(),
  organisationService: asClass(OrganisationService).singleton(),
  rfiPointService: asClass(RFIPointService).singleton(),
  paymentService: asClass(PaymentService).singleton(),
  maintenanceService: asClass(MaintenanceAlertService).singleton(),
  notificationService: asClass(NotificationService).singleton(),
  notificationUserProfileService: asClass(NotificationUserProfileService).singleton(),
  requiredDocumentsService: asClass(RequiredDocumentsService).singleton(),
  registeredApplicantInvitationService: asClass(RegisteredApplicantInvitationService).singleton(),
  parcelService: asClass(ParcelService).singleton(),
  personSearchService: asClass(PersonSearchService).singleton(),
  applicationAccessRequestService: asClass(ApplicationAccessRequestService).singleton(),
});

// Register services on test env
if (process.env.NODE_ENV === "test") {
  // Exclude CognitoService.mock file from production build
  const { CognitoServiceMock } = require("services/cognito/CognitoService.mock");
  serviceContainer.register({
    cognitoService: asClass<ICognitoService>(CognitoServiceMock).singleton(),
    i18nInitializer: asClass(I18nInitializerMock).singleton(),
  });
}

// Register services on development env
if (process.env.NODE_ENV === "development") {
  serviceContainer.register({
    logger: asValue(developmentLogger),
  });

  // Use mocked cognito service if required
  if (AWS_COGNITO_USE_MOCK) {
    // Exclude CognitoService.mock file from production build
    const { CognitoServiceMock } = require("services/cognito/CognitoService.mock");
    serviceContainer.register({
      cognitoService: asClass<ICognitoService>(CognitoServiceMock).singleton(),
    });
  }
}

export { serviceContainer };
