import React, { Fragment } from "react";
import NotificationCard from "components/NotificationCard/NotificationCard";
import { useStoreSelector } from "store/hooks";
import { selectNotificationEntityById } from "store/domain-data/notification/notification";
import { styled } from "@mui/material/styles";
import { BoxProps, IconButton } from "@mui/material";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import notificationIcon from "assets/images/notification-envelope-icon.svg";
import NotificationDetailsDialog from "components/NotificationDetailsDialog/NotificationDetailsDialog";
import { usePostHog } from "posthog-js/react";
import { css } from "@emotion/react";

type Props = BoxProps & {
  notificationId: string;
};

const BellNotificationCard: React.FC<Props> = ({ notificationId, ...otherProps }) => {
  const posthog = usePostHog();
  const notification = useStoreSelector((state) => selectNotificationEntityById(state, notificationId));
  const [isNotificationDetailsDialogOpen, setIsNotificationDetailsDialogOpen] = React.useState(false);

  const openNotificationDetailsDialog = React.useCallback(() => {
    setIsNotificationDetailsDialogOpen(true);
    posthog?.capture(`user viewed-notification`);
  }, [posthog, setIsNotificationDetailsDialogOpen]);

  const closeNotificationDetailsDialog = React.useCallback(() => {
    setIsNotificationDetailsDialogOpen(false);
  }, [setIsNotificationDetailsDialogOpen]);

  if (!notification) {
    return null;
  }

  return (
    <Fragment>
      <NotificationCard
        data-notification-id={notification.id}
        icon={<img src={notificationIcon} alt="Bell notification card icon" />}
        subject={notification.message.shortText}
        datetime={notification.createdDate}
        accessory={
          <StyledIconButton
            aria-label={"Bell notification icon button"}
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
          >
            <StyledFontIcon name={ODL_ICONS.NAVIGATION_CHEVRON_RIGHT} />
          </StyledIconButton>
        }
        onCardClick={openNotificationDetailsDialog}
        {...otherProps}
      />
      <NotificationDetailsDialog
        notificationId={notificationId}
        isOpen={isNotificationDetailsDialogOpen}
        onClose={closeNotificationDetailsDialog}
      />
    </Fragment>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 100%;
    height: 32px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};
  `
);

export default React.memo(BellNotificationCard);
