import { css } from "@emotion/react";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import FlexBox from "components/FlexBox/FlexBox";
import React from "react";

type Props = {
  icon: React.ReactNode;
  title: string;
  subTitle: string;
  onClick: () => void;
};

const StartApplicationIntentCard: React.FC<Props> = ({ icon, title, subTitle, onClick }) => {
  return (
    <StyledCard onClick={onClick} data-testid={"ApplicationIntentCardMinimal"} aria-label={title}>
      <CardActionArea>
        <StyledCardContent>
          <ApplicationTypeContainer>
            <div>{icon}</div>
            <StyledContent>
              <StyledTitle>{title}</StyledTitle>
              <FlexBox spacing={2} direction={"column"}>
                <StyledDescription>{subTitle}</StyledDescription>
              </FlexBox>
            </StyledContent>
          </ApplicationTypeContainer>

          <StyledFontIcon name={"i-navigation-arrow-right"} />
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
};

const StyledCard = styled(Card)(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    border-radius: 8px;

    ${theme.mixins.flexGap("16px")};
  `
);

const StyledCardContent = styled(CardContent)(
  ({ theme }) => css`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    height: 100%;

    ${theme.mixins.flexGap("4px")}
  `
);

const ApplicationTypeContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    height: 100%;

    ${theme.mixins.flexGap("16px")};
  `
);

const StyledContent = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    ${theme.mixins.flexGap("4px")};
  `
);

const StyledTitle = styled("h4")(
  ({ theme }) => css`
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledDescription = styled("p")(
  ({ theme }) => css`
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: ${theme.palette.objective.dark.fiordland};
    margin: 0;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    align-self: flex-end;
  `
);

export default StartApplicationIntentCard;
