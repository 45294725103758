import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import clsx from "clsx";

// Formio Setup
import "./FormIOForm.setup";
import { Formio, WebForm } from "@objective_build/formiojs";
import { IFormIOSchemaEntity } from "models/FormIOSchema.model";
import get from "lodash/get";

declare global {
  interface Window {
    __OBJECTIVE_BUILD_FORMIO_CUSTOM_DATA__: {
      applicationId: number;
    };
  }
}

type Props = {
  applicationId?: number;
  formSchema?: IFormIOSchemaEntity;
  options?: Object;
  children?: (form: WebForm) => JSX.Element;
  onSetForm?: (form: WebForm) => void;
} & BoxProps;

const EMPTY_OBJECT = {};

// This is a very thin wrapper that creates form.io Form instance
const FormIOForm: React.FC<Props> = ({
  applicationId = 0,
  formSchema = EMPTY_OBJECT,
  options = EMPTY_OBJECT,
  children,
  onSetForm,
  ...otherProps
}) => {
  const formioDivRef = React.useRef<HTMLDivElement | null>(null);
  const formRef = React.useRef<WebForm | null>(null);
  const [formId, setFormId] = React.useState("");

  // Save state: Accessible from React formio component
  window.__OBJECTIVE_BUILD_FORMIO_CUSTOM_DATA__ = {
    applicationId,
  };

  // Init formio
  React.useEffect(() => {
    const formioDiv = formioDivRef.current;
    if (!formioDiv) {
      return;
    }
    if (!formSchema) {
      return;
    }

    Formio.createForm(formioDiv, formSchema, options).then((newForm) => {
      // Assign properties to form component
      newForm.component.properties = get(formSchema, "properties") || {};

      // Method to remove single event listener
      //   This allows us to add multiple event listeners without memory leaking or conflicts when removing listeners
      //   PR to formio.js: https://github.com/formio/formio.js/pull/3699
      newForm.off = function (event: string, cb?: Function) {
        if (!this.events) {
          return;
        }

        const type = `${this.options.namespace}.${event}`;

        this.events.listeners(type).forEach((listener: any) => {
          // Ensure the listener is for this element
          if (!listener || listener.id !== this.id) {
            return;
          }

          // If there is a given callback, only deal with the match
          if (cb && cb !== listener) {
            return;
          }

          this.events.off(type, listener);
        });
      };

      // Keep reference
      formRef.current = newForm;
      setFormId(newForm.id);
      if (onSetForm) {
        onSetForm(newForm);
      }
    });
  }, [formSchema, options, onSetForm]);

  return (
    <Box data-testid="FormIOForm" {...otherProps} className={clsx("FormIOForm", otherProps.className)}>
      <div data-testid="FormIODivRef" ref={formioDivRef} />
      {children && formId && formRef.current && children(formRef.current)}
    </Box>
  );
};

export default FormIOForm;
