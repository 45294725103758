import React from "react";
import Guard from "components/Guard/Guard";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { useRemoveAllApplicationDocumentsInContainer } from "components/DocumentContainer/functions/useRemoveAllApplicationDocumentsInContainer";
import { getFileNameComponents } from "utils/getFileNameComponents";
import DocumentIcon from "components/DocumentIcon/DocumentIcon";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuTriggerIconButton from "components/odl-v2/Menu/MenuTriggerIconButton";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import { css } from "@emotion/react";

type Props = {
  applicationId: number;
  documentContainerId: number;
  isCollapsed: boolean;
  isSelected?: boolean;
  isReadonly?: boolean;
  onToggleCollapse: () => void;
};

const MergedDocumentCard: React.FC<Props> = ({
  applicationId,
  documentContainerId,
  isCollapsed,
  isSelected = false,
  isReadonly = false,
  onToggleCollapse,
}) => {
  const { t } = useTranslation();

  const { Menu, openMenu, closeMenu } = useMenu();

  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));
  const {
    handleRemoveAllApplicationDocumentsInContainer,
    removeAllApplicationDocumentsInContainerConfirmationDialog,
  } = useRemoveAllApplicationDocumentsInContainer(applicationId);

  const { fileExt, fileNameWithoutExt } = getFileNameComponents(documentContainer?.mergeFileName || "");

  return (
    <React.Fragment>
      <StyledCard data-testid={`MergedDocumentCard`} $isSelected={isSelected}>
        {/* Icon and filename */}
        <StyledIconContainer>
          <DocumentIcon size={52} />
        </StyledIconContainer>
        <StyledMergedFileName>
          <strong>{fileNameWithoutExt}</strong>
          <Guard condition={!!fileExt}>
            <span>.{fileExt}</span>
          </Guard>
        </StyledMergedFileName>

        {/* Actions button */}
        <Guard condition={!isReadonly}>
          <MenuTriggerIconButton onClick={openMenu} data-testid={"DocumentContainerActionsButton"} />
          <Menu>
            <MenuItem
              icon={"i-format-trash-can"}
              data-testid={"PopupMenuItemDelete"}
              onClick={() => {
                handleRemoveAllApplicationDocumentsInContainer(documentContainerId);
                closeMenu();
              }}
            >
              {t(`Remove`)}
            </MenuItem>
          </Menu>
        </Guard>

        {/* Collapse button */}
        <StyledCollapseButton
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          onClick={onToggleCollapse}
          aria-label={isCollapsed ? t(`Expand documents in this container`) : t(`Collapse documents in this container`)}
        >
          <Guard condition={isCollapsed}>
            <FontIcon name={"i-navigation-chevron-down"} fontSize={18} />
          </Guard>
          <Guard condition={!isCollapsed}>
            <FontIcon name={"i-navigation-chevron-up"} fontSize={18} />
          </Guard>
        </StyledCollapseButton>
      </StyledCard>

      {removeAllApplicationDocumentsInContainerConfirmationDialog}
    </React.Fragment>
  );
};

const StyledCollapseButton = styled(IconButton)(
  ({ theme }) => css`
    font-size: 12px;
    width: 44px;
    height: 44px;
  `
);

const StyledCard = styled("div")<{ $isSelected: boolean }>(
  ({ theme, $isSelected }) => css`
    position: relative;
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("16px")}
    padding: 12px 24px;

    &:hover {
      background: ${theme.palette.objective.blue.light};
    }

    ${$isSelected &&
    css`
      background: ${theme.palette.objective.blue.light};

      &:before {
        content: "";
        position: absolute;
        left: 2px;
        top: 12px;
        bottom: 12px;
        border-left: 4px solid ${theme.palette.primary.main};
        border-radius: 4px;
      }
    `}

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 1px solid ${theme.palette.objective.light.day};
    }
  `
);

const StyledMergedFileName = styled("div")(
  ({ theme }) => css`
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 16px;
    font-size: 14px;
    color: ${theme.palette.objective.dark.neutral};

    & > strong {
      font-weight: 500;
      font-size: 16px;
      color: ${theme.palette.objective.dark.night};
    }
  `
);

const StyledIconContainer = styled("div")(
  ({ theme }) => css`
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
  `
);

export default MergedDocumentCard;
