import React from "react";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = {
  title: React.ReactNode;
  toolbar?: React.ReactNode;
};

const SectionHeader: React.FC<Props> = ({ title, toolbar }) => {
  return (
    <StyledHeaderContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledToolbar>{toolbar}</StyledToolbar>
    </StyledHeaderContainer>
  );
};

const StyledHeaderContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
);

const StyledTitle = styled("h3")(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  `
);

const StyledToolbar = styled("div")(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
  `
);

export default SectionHeader;
