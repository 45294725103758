import React from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { css } from "@emotion/react";

type Props = {
  displayName: string;
  isSelected: boolean;
  isLocked: boolean;
  isClickable: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
};

const ParticipantTypeTag: React.FC<Props> = ({
  displayName,
  isSelected,
  isLocked,
  isClickable,
  isRequired = false,
  disabled = false,
  onClick,
}) => {
  return (
    <StyledChip
      label={displayName}
      clickable={isClickable}
      $isClickable={isClickable}
      $isRequired={isRequired}
      $isSelected={isSelected}
      variant={isSelected ? "filled" : "outlined"}
      onClick={onClick}
      data-testid={"ParticipantTypeTag"}
      aria-pressed={isSelected}
      disabled={disabled}
      {...(isSelected ? { onDelete: onClick, color: "blue" } : {})}
    />
  );
};

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !["$isClickable", "$isRequired", "$isSelected"].includes(prop.toString()),
})<{
  $isClickable: boolean;
  $isRequired: boolean;
  $isSelected: boolean;
}>(
  ({ theme, $isClickable, $isRequired, $isSelected }) => css`
    cursor: ${$isClickable ? "pointer" : "default"};
    color: ${theme.palette.text.secondary};

    &:hover {
      color: ${theme.palette.grey[900]};
      background-color: ${theme.palette.grey[400]} !important;
    }

    ${
      $isSelected &&
      css`
        color: ${theme.palette.text.primary};
      `
    }
    ${
      $isRequired &&
      !$isSelected &&
      css`
        border: 1px solid ${theme.palette.primary.main};
        .MuiChip-icon {
          color: ${theme.palette.primary.main};
        }
      `
    }

    ${theme.mixins.flexGap("8px")}
  `
);

export default ParticipantTypeTag;
