import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";

type Props = {
  addressLine: string;
  consentNumber: string;
  issuedBy: string;
};

const ApplicationReviewParentConsentInfo: React.FC<Props> = ({ addressLine, consentNumber, issuedBy }) => {
  // Common
  const { t } = useTranslation();

  return (
    <StyledBuildingConsentPanelContainer data-testid="ApplicationReviewParentConsentInfo">
      <StyledBuildingContentHeader>{t("The building consent")}</StyledBuildingContentHeader>
      <StyledFieldSection>
        <StyledFieldContainer>
          <StyledFieldLabel>{t(`Address`)}:&nbsp;</StyledFieldLabel>
          <StyledFieldValue>{addressLine}</StyledFieldValue>
        </StyledFieldContainer>
        <StyledFieldContainer>
          <StyledFieldLabel>{t(`Building consent number`)}:&nbsp;</StyledFieldLabel>
          <StyledFieldValue>{consentNumber}</StyledFieldValue>
        </StyledFieldContainer>
        <StyledFieldContainer>
          <StyledFieldLabel>{t(`Issued by`)}:&nbsp;</StyledFieldLabel>
          <StyledFieldValue>{issuedBy}</StyledFieldValue>
        </StyledFieldContainer>
      </StyledFieldSection>
    </StyledBuildingConsentPanelContainer>
  );
};

const StyledBuildingConsentPanelContainer = styled("div")(
  ({ theme }) => css`
    padding: 0 0 24px 0px;
  `
);

const StyledBuildingContentHeader = styled("h3")(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
    padding: 0px 0 2px 0px;
    font-size: 18px;
    font-weight: 700;
  `
);

const StyledFieldSection = styled("div")(({ theme }) => {
  return css`
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0 0px;
    background-color: ${theme.palette.objective.light.white};
    ${theme.mixins.flexGap("8px")};
  `;
});

const StyledFieldContainer = styled("div")(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.neutral};
    margin-bottom: 0px;
  `
);

const StyledFieldLabel = styled("label")(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 300;
  `
);
const StyledFieldValue = styled("span")(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
    font-size: 16px;
    font-weight: 500;
  `
);

export default ApplicationReviewParentConsentInfo;
