import { borders, display, flexbox, palette, positions, shadows, sizing, spacing, typography } from "@mui/system";
import { styled } from "@mui/material/styles";
import { BoxProps } from "@mui/material";
import React from "react";

const Box: React.FC<BoxProps> = React.forwardRef(({ component, ...otherProps }, ref) => {
  return <StyledBox ref={ref} as={component} {...otherProps} />;
});

const StyledBox = styled("div")`
  ${palette as any}
  ${spacing as any}
  ${typography as any}
  ${shadows as any}
  ${borders as any}
  ${sizing as any}
  ${display as any}
  ${flexbox as any}
  ${positions as any}
`;

export default Box;
