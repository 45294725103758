import React from "react";
import {
  InvitationStatus,
  IParticipantEntity,
  IParticipantTypeApplicationEntity,
  ParticipantAccessLevel,
  rankAccessLevel,
} from "models/Participant.model";
import { useTranslation } from "react-i18next";
import Guard from "components/Guard/Guard";
import { useStoreSelector } from "store/hooks";
import {
  selectNonDeletableParticipantTypes,
  selectUnassignedParticipantTypesBeingManagedByCurrentUser,
} from "store/domain-data/participant-type/participantType";
import ParticipantCannotRemoveInformationDialog from "components/ParticipantCannotRemoveInformationDialog/ParticipantCannotRemoveInformationDialog";
import MenuTriggerIconButton from "components/odl-v2/Menu/MenuTriggerIconButton";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import { selectCanUserManageApplicationById } from "store/domain-data/application/application";

type Props = {
  participant: IParticipantEntity;
  onClickEditParticipantInfo?: () => any;
  onClickReinviteParticipant?: () => any;
  onClickDeleteParticipant?: () => any;
  onClickSetToParticipantType?: (participantType: IParticipantTypeApplicationEntity) => any;
};

const ParticipantCardActions: React.FC<Props> = ({
  participant,
  onClickEditParticipantInfo = () => {},
  onClickReinviteParticipant = () => {},
  onClickDeleteParticipant = () => {},
  onClickSetToParticipantType = () => {},
}) => {
  // Common
  const { t } = useTranslation();

  const applicationId = React.useMemo(() => participant.applicationId || 0, [participant.applicationId]);
  const { Menu, openMenu, closeMenu } = useMenu();

  const canResendInvite = React.useMemo(
    () =>
      !!participant.email &&
      (participant.invitation === InvitationStatus.Unregistered ||
        participant.invitation === InvitationStatus.Error ||
        participant.invitation === InvitationStatus.Queued) &&
      rankAccessLevel(participant.accessLevel) > rankAccessLevel(ParticipantAccessLevel.None),
    [participant]
  );

  const [isDisabledDeleteInfoDialogOpen, setIsDisabledDeleteInfoDialogOpen] = React.useState(false);

  const disableDeleteInfoClickHandler = React.useCallback(() => {
    setIsDisabledDeleteInfoDialogOpen(true);
  }, []);

  const onCloseDisableDeleteInfoDialog = React.useCallback(() => {
    setIsDisabledDeleteInfoDialogOpen(false);
  }, []);

  // Derived
  const canUserManageApplication = useStoreSelector((state) =>
    selectCanUserManageApplicationById(state, applicationId)
  );

  const userManagedParticipantTypes = useStoreSelector((state) =>
    selectUnassignedParticipantTypesBeingManagedByCurrentUser(state, {
      applicationId,
      currentParticipantTypes: participant.participantTypes,
    })
  );

  const nonDeletableParticipantTypes = useStoreSelector((state) =>
    selectNonDeletableParticipantTypes(state, { ...participant, libraryContactId: 0 })
  );

  const disableDeleteParticipant = React.useMemo(() => nonDeletableParticipantTypes.length > 0, [
    nonDeletableParticipantTypes,
  ]);

  const nonDeletableParticipantTypesDisplayName = React.useMemo(() => {
    const displayNames = nonDeletableParticipantTypes.map((item) => item.displayName);
    const numOfDisplayNames = displayNames.length;
    if (numOfDisplayNames === 0) {
      return "";
    }

    if (numOfDisplayNames === 1) {
      return displayNames[0];
    }

    const lastDisplayName = displayNames.slice(-1);
    const restDisplayNames = displayNames.slice(0, numOfDisplayNames - 1).join(", ");
    return `${restDisplayNames} and ${lastDisplayName}`;
  }, [nonDeletableParticipantTypes]);

  return (
    <React.Fragment>
      <MenuTriggerIconButton onClick={openMenu} data-testid={`ParticipantActionMenu-${participant.id}`} rectSize={40} />
      <Menu>
        <Guard condition={canUserManageApplication}>
          <MenuItem
            icon={"i-format-edit"}
            data-testid={`EditPersonalInfo-${participant.id}`}
            onClick={() => {
              closeMenu();
              onClickEditParticipantInfo();
            }}
          >
            {t(`Edit participant info`)}
          </MenuItem>
        </Guard>
        <Guard condition={canResendInvite && canUserManageApplication}>
          <MenuItem
            icon={"i-tech-mail-reply"}
            data-testid={`ReinviteParticipant-${participant.id}`}
            onClick={() => {
              closeMenu();
              onClickReinviteParticipant();
            }}
          >
            {t(`Resend Invitation`)}
          </MenuItem>
        </Guard>
        <Guard condition={!disableDeleteParticipant && canUserManageApplication}>
          <MenuItem
            icon={"i-format-trash-can"}
            data-testid={`DeleteParticipant-${participant.id}`}
            onClick={() => {
              closeMenu();
              onClickDeleteParticipant();
            }}
          >
            {t(`Delete`)}
          </MenuItem>
        </Guard>
        <Guard condition={disableDeleteParticipant && canUserManageApplication}>
          <MenuItem
            icon={"i-status-warning-hex"}
            disabled={disableDeleteParticipant}
            data-testid={`DeleteParticipant-${participant.id}`}
            onClick={disableDeleteInfoClickHandler}
          >
            {t(`${nonDeletableParticipantTypesDisplayName} type is required`)}
          </MenuItem>
        </Guard>
        <Guard condition={!canUserManageApplication}>
          {userManagedParticipantTypes.map((type) => (
            <MenuItem
              icon={"i-user-settings"}
              title={t(`Set to ${type.displayName}`)}
              data-testid={"PopupMenuItem"}
              onClick={() => {
                closeMenu();
                onClickSetToParticipantType(type);
              }}
              key={type.displayName}
            >
              {t(`Set to ${type.displayName}`)}
            </MenuItem>
          ))}
        </Guard>
      </Menu>

      <ParticipantCannotRemoveInformationDialog
        participantTypeDisplayNames={nonDeletableParticipantTypesDisplayName}
        isOpen={isDisabledDeleteInfoDialogOpen}
        onClose={onCloseDisableDeleteInfoDialog}
      />
    </React.Fragment>
  );
};

export default ParticipantCardActions;
