import React from "react";
import BreadcrumbStepAccordion from "components/ApplicationFormReviewDialog/BreadcrumbStepAccordion";
import { ApplicationFormNavigationBreadcrumb } from "models/ApplicationFormNavigation.model";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = { applicationId: number; breadcrumb: ApplicationFormNavigationBreadcrumb };

const BreadcrumbSection: React.FC<Props> = ({ applicationId, breadcrumb }) => {
  return (
    <section>
      <StyledBreadcrumbTitle>{breadcrumb.displayName}</StyledBreadcrumbTitle>
      {breadcrumb.steps.map((breadcrumbStep) => (
        <BreadcrumbStepAccordion
          applicationId={applicationId}
          breadcrumbStep={breadcrumbStep}
          key={breadcrumbStep.name}
        />
      ))}
    </section>
  );
};

const StyledBreadcrumbTitle = styled("h2")(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    padding: 12px 0;
  `
);

export default BreadcrumbSection;
