import React from "react";
import MuiDialogActions, { DialogActionsProps } from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = DialogActionsProps;

const DialogActions: React.FC<Props> = React.forwardRef((props, ref) => {
  return <StyledMuiDialogActions ref={ref} {...props} />;
});

const StyledMuiDialogActions = styled(MuiDialogActions)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background: ${theme.palette.background.default};
  `
);

export default DialogActions;
