import React from "react";
import { NotificationLevel } from "models/NotificationSubscriptionLevel.model";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { Theme, useTheme } from "@mui/material";
import { useStoreSelector } from "store/hooks";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { NOTIFICATION_SETTINGS_ENABLED } from "constants/configs";
import { ReactComponent as TimeNormalIcon } from "assets/images/time-normal-icon.svg";
import { ReactComponent as TimeMutedIcon } from "assets/images/time-muted-icon.svg";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = { applicationId: number };

const ApplicationNotificationLevelIcon: React.FC<Props> = ({ applicationId }) => {
  const theme = useTheme();

  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  if (!NOTIFICATION_SETTINGS_ENABLED) {
    return null;
  }

  switch (application?.notificationLevel) {
    case NotificationLevel.Watching:
      return (
        <FontIcon
          name={"i-time-alarm"}
          color={theme.palette.objective.brandColors.teams.primary}
          fontSize={16}
          data-testid={"NotificationIconWatching"}
        />
      );
    case NotificationLevel.Normal:
      return <StyledTimeNormalIcon $colour={"#27AE60"} data-testid={"NotificationIconNormal"}></StyledTimeNormalIcon>;
    case NotificationLevel.Muted:
      return (
        <StyledTimeMutedIcon
          $colour={theme.palette.objective.dark.neutral}
          data-testid={"NotificationIconMuted"}
        ></StyledTimeMutedIcon>
      );
    default:
      return null;
  }
};

const iconStyles = ({ theme, $colour }: { theme: Theme; $colour?: string }) => css`
  width: 20px;
  height: 20px;

  path {
    ${$colour && `fill: ${$colour};`}
  }
`;

const StyledTimeNormalIcon = styled(TimeNormalIcon, { shouldForwardProp: (prop) => prop !== "$colour" })<{
  $colour?: string;
}>(iconStyles);

const StyledTimeMutedIcon = styled(TimeMutedIcon, { shouldForwardProp: (prop) => prop !== "$colour" })<{
  $colour?: string;
}>(iconStyles);

export default ApplicationNotificationLevelIcon;
