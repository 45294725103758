import React from "react";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import {
  fetchNotificationUserProfileForCurrentUser,
  markAllNotificationsAsSeenForCurrentUser,
  selectNotificationUserProfileForCurrentUser,
} from "store/domain-data/notification-user-profile/notificationUserProfile";
import { styled } from "@mui/material/styles";
import { Badge, Divider, IconButton, useTheme, Button } from "@mui/material";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";

import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import BellNotificationCard from "components/AppHeader/BellNotificationCard";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import { fetchLatestNotificationsForCurrentUser } from "store/domain-data/notification/notification";
import { useHistory } from "react-router-dom";
import { INotificationEntity } from "models/Notification.model";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { selectIsLoadingThunks } from "store/app-state/loading/loading";
import isEmpty from "lodash/isEmpty";
import { usePostHog } from "posthog-js/react";
import { css } from "@emotion/react";

type Props = {};

const NotificationNavItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const theme = useTheme();
  const { toastError } = useToast();

  const { Menu, openMenu, closeMenu } = useMenu();
  const history = useHistory();
  const posthog = usePostHog();

  const userInfo = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);
  const notificationUserProfile = useStoreSelector(selectNotificationUserProfileForCurrentUser);
  const isLoading = useStoreSelector((state) =>
    selectIsLoadingThunks(state, [fetchNotificationUserProfileForCurrentUser, fetchLatestNotificationsForCurrentUser])
  );
  const [latestNotifications, setLatestNotifications] = React.useState<INotificationEntity[]>([]);
  const shouldRenderEmptyState = !isLoading && isEmpty(latestNotifications);
  const shouldRenderNotifications = !isLoading && !isEmpty(latestNotifications);

  const handleClickViewAllNotifications = React.useCallback(() => {
    posthog?.capture(`user viewed-all-notifications`);
    history.push("/user-notifications");
    closeMenu();
  }, [posthog, history, closeMenu]);

  React.useEffect(() => {
    if (!userInfo) {
      return;
    }
    dispatch(fetchNotificationUserProfileForCurrentUser());
  }, [dispatch, userInfo]);

  const handleClickNotificationCenter = React.useCallback(async () => {
    try {
      const { notifications } = await dispatch(fetchLatestNotificationsForCurrentUser()).then(unwrapResult);
      setLatestNotifications(notifications);
      if (notificationUserProfile?.hasNewNotifications) {
        await dispatch(markAllNotificationsAsSeenForCurrentUser());
      }
    } catch (e) {
      toastError(e);
    }
  }, [dispatch, notificationUserProfile?.hasNewNotifications, toastError]);

  const hasNewNotifications = React.useMemo(() => {
    return notificationUserProfile?.hasNewNotifications === true;
  }, [notificationUserProfile]);

  return (
    <div data-testid={"NotificationNavItem"}>
      <HoverTooltip title={t(`Notification centre`)}>
        <StyledIconButton
          onClick={(e) => {
            openMenu(e);
            handleClickNotificationCenter();
          }}
          aria-label={t(`Notification centre`)}
        >
          {!hasNewNotifications && (
            <FontIcon
              name={ODL_ICONS.TOGGLE_NOTIFICATION}
              data-testid={"BellIcon"}
              fontSize={16}
              color={theme.palette.objective.dark.night}
            />
          )}
          {hasNewNotifications && (
            <StyledBadge badgeContent={""} data-testid={"BellIconWithBadge"}>
              <FontIcon name={ODL_ICONS.TOGGLE_NOTIFICATION} fontSize={16} color={theme.palette.objective.dark.night} />
            </StyledBadge>
          )}
        </StyledIconButton>
      </HoverTooltip>
      <Menu
        data-testid={"LatestUserNotificationsPopup"}
        transitionDuration={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <StyledMenuContainer>
          <StyledPopupTitle>{t(`Recent notifications`)}</StyledPopupTitle>
          <StyledPopupContent>
            {isLoading && <StyledNotificationStatus>{t(`Loading notifications...`)}</StyledNotificationStatus>}

            {shouldRenderNotifications &&
              latestNotifications.map((notification) => (
                <div role={"menuitem"} tabIndex={0} key={notification.id}>
                  <BellNotificationCard notificationId={notification.id} />
                  <Divider />
                </div>
              ))}

            {shouldRenderEmptyState && (
              <StyledNotificationStatus>{t(`There are no notifications.`)}</StyledNotificationStatus>
            )}
          </StyledPopupContent>
          {shouldRenderNotifications && (
            <StyledPopupFooter>
              <Button variant={"text"} onClick={handleClickViewAllNotifications}>
                {t(`View all notifications`)}
              </Button>
            </StyledPopupFooter>
          )}
        </StyledMenuContainer>
      </Menu>
    </div>
  );
};

const StyledMenuContainer = styled("div")(
  ({ theme }) => css`
    padding: 8px 0;
  `
);

const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    & .MuiBadge-badge {
      background: ${theme.palette.objective.red.main};
      height: 8px;
      min-width: 8px;
      padding: 0;
      right: 1px;
    }
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    height: 44px;
    width: 44px;
  `
);

const StyledPopupTitle = styled("div")(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.objective.dark.night};
    line-height: 150%;
    padding: 12px;
    background: ${theme.palette.objective.light.white};
  `
);
const StyledPopupContent = styled("div")(
  ({ theme }) => css`
    max-height: 400px;
    width: 420px;
    overflow-y: auto;
  `
);

const StyledPopupFooter = styled("div")(
  ({ theme }) =>
    css`
      padding: 12px 12px 0px 12px;
    `
);

const StyledNotificationStatus = styled("p")(
  ({ theme }) =>
    css`
      display: flex;
      flex-grow: 1;
      padding: 24px 12px;
      justify-content: center;
    `
);

export default NotificationNavItem;
