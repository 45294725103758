import React from "react";
import { styled } from "@mui/material/styles";
import { useStoreSelector } from "store/hooks";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import FlexBox from "components/FlexBox/FlexBox";
import { css } from "@emotion/react";

type Props = { documentContainerId: number };

const DocumentContainerHeader: React.FC<Props> = ({ documentContainerId }) => {
  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));

  if (!documentContainer) {
    return null;
  }

  return (
    <FlexBox spacing={2} direction={"column"} component={"header"} useSpacer={false}>
      <StyledDocumentContainerTitle>{documentContainer.displayName}</StyledDocumentContainerTitle>
      <StyledDocumentContainerDescription>{documentContainer.description}</StyledDocumentContainerDescription>
    </FlexBox>
  );
};

const StyledDocumentContainerTitle = styled("h3")(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  `
);

const StyledDocumentContainerDescription = styled("p")(
  ({ theme }) => css`
    line-height: 150%;
  `
);

export default DocumentContainerHeader;
