import { combineReducers } from "@reduxjs/toolkit";
import { jurisdictionReducer } from "store/domain-data/jurisdiction/jurisdiction";
import { jurisdictionFormReducer } from "store/domain-data/jurisdiction-form/jurisdictionForm";
import { breadcrumbReducer } from "store/domain-data/breadcrumb/breadcrumb";
import { breadcrumbStepReducer } from "store/domain-data/breadcrumb-step/breadcrumbStep";
import { formSchemaReducer } from "store/domain-data/form-schema/formSchema";
import { applicationReducer } from "store/domain-data/application/application";
import { contactReducer } from "store/domain-data/contact/contact";
import { propertyReducer } from "store/domain-data/property/property";
import { authorityReducer } from "store/domain-data/authority/authority";
import { userInfoReducer } from "store/domain-data/user-info/userInfo";
import { applicationStepDataReducer } from "store/domain-data/application-step-data/applicationStepData";
import { participantReducer } from "store/domain-data/participant/participant";
import { documentTypeReducer } from "store/domain-data/document-type/documentType";
import { documentReducer } from "store/domain-data/document/document";
import { applicationDocumentRelationReducer } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { jurisdictionFormDocumentTypeRelationReducer } from "store/domain-data/jurisdiction-form-document-type-relation/jurisdictionFormDocumentTypeRelation";
import { jurisdictionDocumentTypeRelationReducer } from "store/domain-data/jurisdiction-document-type-relation/jurisdictionDocumentTypeRelation";
import { documentTagReducer } from "store/domain-data/document-tag/documentTag";
import { documentTagRelationReducer } from "store/domain-data/document-tag-relation/documentTagRelation";
import { documentTypeRelationReducer } from "store/domain-data/document-type-relation/documentTypeRelation";
import { applicationValidationResultReducer } from "store/domain-data/application-validation-result/applicationValidationResult";
import { applicationStepRelationReducer } from "store/domain-data/application-step-relation/applicationStepRelation";
import { participantTypeReducer } from "./participant-type/participantType";
import { organisationReducer } from "store/domain-data/organisation/organisation";
import { organisationMemberReducer } from "store/domain-data/organisation-member/organisationMember";
import { organisationMemberInvitationReducer } from "store/domain-data/organisation-member-invitation/organisationMemberInvitation";
import { countryReducer } from "store/domain-data/country/country";
import { organisationMergeInvitationReducer } from "store/domain-data/organisation-merge-invitation/organisationMergeInvitation";
import { documentContainerReducer } from "store/domain-data/document-containers/documentContainers";
import { applicationDocumentContainerReducer } from "store/domain-data/application-document-containers-relation/applicationDocumentContainersRelation";
import { documentCategoryReducer } from "store/domain-data/document-categories/documentCategories";
import { applicationTemplateReducer } from "store/domain-data/application-template/applicationTemplate";
import { requiredDocumentReducer } from "store/domain-data/required-document/requiredDocument";
import { applicationRequiredDocumentGroupsReducer } from "store/domain-data/required-document/requiredDocumentGroups";
import { rfiPointReducer } from "store/domain-data/rfi-point/rfiPoint";
import { requiredInspectionsReducer } from "store/domain-data/required-inspection/requiredInspections";
import { paymentReducer } from "store/domain-data/payment/payment";
import { paymentOptionReducer } from "store/domain-data/payment-option/paymentOption";
import { notificationReducer } from "store/domain-data/notification/notification";
import { notificationUserProfileReducer } from "store/domain-data/notification-user-profile/notificationUserProfile";
import { qualificationTypeReducer } from "store/domain-data/qualification-type/qualificationType";
import { applicationV3Reducer } from "store/domain-data/application-v3/applicationV3";
import { announcementReducer } from "store/domain-data/announcement/announcement";
import { userAuthorityRelationReducer } from "store/domain-data/user-authority-relation/userAuthorityRelation";
import { registeredApplicantInvitationReducer } from "store/domain-data/registered-applicant-invitation/registeredApplicantInvitationReducer";
import { applicationAccessRequestReducer } from "store/domain-data/application-access-request/applicationAccessRequest";

export const domainDataReducer = combineReducers({
  jurisdiction: jurisdictionReducer,
  jurisdictionForm: jurisdictionFormReducer,
  breadcrumb: breadcrumbReducer,
  breadcrumbStep: breadcrumbStepReducer,
  formSchema: formSchemaReducer,
  applicationStepData: applicationStepDataReducer,
  announcement: announcementReducer,
  application: applicationReducer,
  applicationV3: applicationV3Reducer,
  contact: contactReducer,
  authority: authorityReducer,
  property: propertyReducer,
  document: documentReducer,
  applicationDocumentRelation: applicationDocumentRelationReducer,
  userInfo: userInfoReducer,
  userAuthorityRelation: userAuthorityRelationReducer,
  participant: participantReducer,
  participantType: participantTypeReducer,
  qualificationType: qualificationTypeReducer,
  documentType: documentTypeReducer,
  jurisdictionFormDocumentTypeRelation: jurisdictionFormDocumentTypeRelationReducer,
  jurisdictionDocumentTypeRelation: jurisdictionDocumentTypeRelationReducer,
  documentTag: documentTagReducer,
  documentTagRelation: documentTagRelationReducer,
  documentTypeRelation: documentTypeRelationReducer,
  applicationValidationResult: applicationValidationResultReducer,
  applicationStepRelation: applicationStepRelationReducer,
  organisation: organisationReducer,
  organisationMember: organisationMemberReducer,
  organisationMemberInvitation: organisationMemberInvitationReducer,
  organisationMergeInvitation: organisationMergeInvitationReducer,
  country: countryReducer,
  documentContainers: documentContainerReducer,
  applicationDocumentContainerRelation: applicationDocumentContainerReducer,
  documentCategory: documentCategoryReducer,
  applicationTemplate: applicationTemplateReducer,
  requiredDocument: requiredDocumentReducer,
  requiredDocumentGroups: applicationRequiredDocumentGroupsReducer,
  requiredInspections: requiredInspectionsReducer,
  rfiPoint: rfiPointReducer,
  payment: paymentReducer,
  paymentOption: paymentOptionReducer,
  notifications: notificationReducer,
  notificationUserProfile: notificationUserProfileReducer,
  registeredApplicantInvitations: registeredApplicantInvitationReducer,
  applicationAccessRequests: applicationAccessRequestReducer,
});
