import React from "react";
import { Card, CardContent, CardProps } from "@mui/material";
import FlexBox from "components/FlexBox/FlexBox";
import { useStoreSelector } from "store/hooks";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import ApplicationCardIcon from "components/ApplicationCard/ApplicationCardIcon";
import { selectAuthorityEntityById } from "store/domain-data/authority/authority";
import isEmpty from "lodash/isEmpty";
import {
  selectApplicationEntityById,
  selectIsApplicationRequiresAttention,
} from "store/domain-data/application/application";
import ApplicationWorkflowBadges from "components/ApplicationWorkflow/ApplicationWorkflowBadges";

type Props = CardProps & {
  applicationId: number;
};

const ApplicationCardMinimal: React.FC<Props> = ({ applicationId, ...otherProps }) => {
  // Redux & custom hooks
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const authority = useStoreSelector((state) => selectAuthorityEntityById(state, application?.authorityId || 0));

  const isApplicationRequiresAttention = useStoreSelector((state) =>
    selectIsApplicationRequiresAttention(state, applicationId)
  );

  const addressText = React.useMemo(() => {
    if (!application) {
      return "";
    }

    if (authority) {
      return `${application.address.fullAddress} • ${authority.displayName}`;
    }
    return application.address.fullAddress;
  }, [application, authority]);

  const applicationDisplayId = React.useMemo(() => {
    if (!isEmpty(application?.consentNumber)) {
      return application?.consentNumber;
    }
    return application?.applicationNumber;
  }, [application]);

  // Guard
  if (!application) {
    return null;
  }

  return (
    <Card data-testid={`ApplicationV3CardMinimal`} data-application-status={application.status} {...otherProps}>
      <StyledCardContent>
        <ApplicationCardIcon
          recordType={application.recordType}
          status={application.status}
          showAttentionIcon={isApplicationRequiresAttention}
          activeStageName={application.activeStageName}
        />
        <StyledApplicationPrimaryInformation>
          <StyledApplicationTitle>{application.formDisplayName}</StyledApplicationTitle>

          <FlexBox spacing={1} alignItems={"center"}>
            <StyledApplicationID>{applicationDisplayId}</StyledApplicationID>
          </FlexBox>

          <StyledApplicationAddress>{addressText}</StyledApplicationAddress>
        </StyledApplicationPrimaryInformation>

        <StyledApplicationSecondaryInformation>
          <ApplicationWorkflowBadges applicationId={applicationId} />
        </StyledApplicationSecondaryInformation>
      </StyledCardContent>
    </Card>
  );
};

const StyledCardContent = styled(CardContent)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 12px 24px !important;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }
  `
);

const StyledApplicationTitle = styled("div")(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.objective.dark.neutral};
  `
);

const StyledApplicationID = styled("div")(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledApplicationAddress = styled("div")(
  ({ theme }) => css`
    overflow: hidden;
    line-height: 1.4;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledApplicationPrimaryInformation = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 110px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    ${theme.mixins.flexGap("4px")}
    align-self: flex-start;
  `
);

const StyledApplicationSecondaryInformation = styled("div")(
  ({ theme }) => css`
    min-height: 44px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("sm")} {
      flex-grow: 1;
    }
  `
);

export default ApplicationCardMinimal;
