import React, { FC } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@odl/core/components/Foundation";
import { defaultTheme } from "styles/defaultTheme";
import ScreenWidthMatchProvider from "components/ScreenWidthMatchProvider/ScreenWidthMatchProvider";
import ToastProvider from "components/ToastProvider/ToastProvider";
import ScreenWidthListener from "components/ScreenWidthListener/ScreenWidthListener";
import ApplicationDocumentUploadQueueListener from "components/ApplicationDocumentUploadQueueListener";
import PostHogProvider from "components/Posthog/PostHogProvider";
import { Provider } from "react-redux";
import { store } from "store/store";

export type Children = {
  children?: React.ReactNode;
};
const AppContextProvider: FC<Children> = ({ children }) => {
  return (
    <React.Suspense fallback={<div />}>
      <Provider store={store}>
        <ThemeProvider theme={defaultTheme} componentThemeOverrides={(theme) => theme.components ?? {}}>
          <CssBaseline />
          <PostHogProvider>
            <ToastProvider>
              <ScreenWidthMatchProvider>
                <ScreenWidthListener />
                <ApplicationDocumentUploadQueueListener />
                {children}
              </ScreenWidthMatchProvider>
            </ToastProvider>
          </PostHogProvider>
        </ThemeProvider>
      </Provider>
    </React.Suspense>
  );
};

export default AppContextProvider;
