import React from "react";
import { styled } from "@mui/material/styles";
import { IParticipantGroupData } from "models/Participant.model";
import { Form, Formik, FormikHelpers } from "formik";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  selectOwnerGroupFormFields,
  setOwnerGroupFormFields,
} from "store/app-state/participant-buffer/participantBuffer";
import * as yup from "yup";
import OwnerGroupFormContent from "components/ParticipantEditDialog/owner-group-form/OwnerGroupFormContent";
import { css } from "@emotion/react";
import { Button } from "@mui/material";

type OwnerGroupFormProps = {
  onCloseOwnerGroupForm: () => void;
};

const OwnerGroupForm: React.FC<OwnerGroupFormProps> = ({ onCloseOwnerGroupForm }) => {
  const dispatch = useStoreDispatch();
  const currentOwnerGroups = useStoreSelector((state) => selectOwnerGroupFormFields(state));

  const ownerGroupFormSchema = React.useMemo(() => {
    return yup.object().shape({
      groupName: yup.string(),
      groupList: yup.string(),
    });
  }, []);

  const handleSubmit = React.useCallback(
    async (formValues: IParticipantGroupData, formikHelpers: FormikHelpers<IParticipantGroupData>) => {
      dispatch(setOwnerGroupFormFields(formValues));
    },
    [dispatch]
  );

  return (
    <React.Fragment>
      <Formik<IParticipantGroupData>
        initialValues={currentOwnerGroups}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={ownerGroupFormSchema}
        validateOnChange={true}
      >
        {() => (
          <Form>
            <OwnerGroupFormContent />
          </Form>
        )}
      </Formik>

      <StyledButton onClick={onCloseOwnerGroupForm}>Cancel</StyledButton>
    </React.Fragment>
  );
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    justify-content: start;
    color: ${theme.palette.objective.blue.main};
    width: 200px;
    height: 32px;
    padding: 6px 12px 6px 0;
  `
);

export default OwnerGroupForm;
