import React from "react";
import { styled } from "@mui/material/styles";
import { useStoreSelector } from "store/hooks";
import { selectApplicationEntityById, selectIsApplicationInProgress } from "store/domain-data/application/application";
import Guard from "components/Guard/Guard";
import isEmpty from "lodash/isEmpty";
import ApplicationWorkflowBadge from "components/ApplicationWorkflow/ApplicationWorkflowBadge";
import { css } from "@emotion/react";

type Props = {
  applicationId: number;
};

const ApplicationWorkflowBadges: React.FC<Props> = ({ applicationId }) => {
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const isApplicationInProgress = useStoreSelector((state) => selectIsApplicationInProgress(state, applicationId));

  const workflowStages = application?.workflowStages || [];
  const applicationStatusLabel = application?.applicationStatusLabel;

  return (
    <StyledBadgeContainer data-testid="ApplicationWorkflowBadges">
      <StyledStatusLabel data-testid="StatusLabel">{applicationStatusLabel}</StyledStatusLabel>

      <Guard condition={isApplicationInProgress && !isEmpty(workflowStages)}>
        <StyledDotsContainer>
          {workflowStages.map((workflowStage) => (
            <ApplicationWorkflowBadge key={workflowStage.stageName} workflowStage={workflowStage} />
          ))}
        </StyledDotsContainer>
      </Guard>
    </StyledBadgeContainer>
  );
};

const StyledBadgeContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("8px")}
  `
);

const StyledDotsContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("6px")}
  `
);

const StyledStatusLabel = styled("div")(
  ({ theme }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: ${theme.palette.objective.dark.neutral};
  `
);

export default ApplicationWorkflowBadges;
