import React from "react";
import { ThemeProvider } from "@odl/core/components/Foundation";
import { CssBaseline } from "@mui/material";
import { defaultTheme } from "styles/defaultTheme";
import RouteController from "components/RouteController/RouteController";
import ScreenWidthMatchProvider from "components/ScreenWidthMatchProvider/ScreenWidthMatchProvider";
import { useAuthenticationRoutine } from "hooks/useAuthenticationRoutine";
import ToastProvider from "components/ToastProvider/ToastProvider";
import ScreenWidthListener from "components/ScreenWidthListener/ScreenWidthListener";
import ApplicationDocumentUploadQueueListener from "components/ApplicationDocumentUploadQueueListener";
import { useIsInMaintenance } from "hooks/useIsInMaintenance";
import MaintenancePage from "pages/maintenance-page/MaintenancePage";
import { useAnnouncementsRoutine } from "hooks/useAnnouncementsRoutine";
import PostHogProvider from "components/Posthog/PostHogProvider";

const App: React.FC = () => {
  const { isInMaintenance, isLoadingMaintenanceFlag } = useIsInMaintenance();

  useAuthenticationRoutine();
  useAnnouncementsRoutine();
  if (isLoadingMaintenanceFlag) {
    return null;
  }

  return (
    <ThemeProvider theme={defaultTheme} componentThemeOverrides={(theme) => theme.components ?? {}} brand="planbuild">
      {/*<StyledThemeProvider theme={defaultTheme}>*/}
      <CssBaseline />
      <PostHogProvider>
        <ToastProvider>
          <ScreenWidthMatchProvider>
            <ScreenWidthListener />
            <ApplicationDocumentUploadQueueListener />
            {isInMaintenance && <MaintenancePage />}
            {!isInMaintenance && <RouteController />}
          </ScreenWidthMatchProvider>
        </ToastProvider>
      </PostHogProvider>
      {/*</StyledThemeProvider>*/}
    </ThemeProvider>
  );
};

export default App;
