import React from "react";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import * as yup from "yup";
import { Button, Typography } from "@mui/material";
import FlexBox from "components/FlexBox/FlexBox";
import FormikSelect from "components/FormikSelect/FormikSelect";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import Spacer from "components/Spacer/Spacer";
import { ReactComponent as SystemGroupAccessIcon } from "assets/images/system-group-access.svg";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { first, toInteger } from "lodash";
import { useTranslation } from "react-i18next";
import { serviceContainer } from "services/serviceContainer";
import { selectCurrentJurisdictionId } from "store/app-state/application-navigation/applicationNavigation";
import {
  selectIndependentAuthoritiesForAuthenticatedUser,
  selectPublicAuthoritiesByJurisdiction,
} from "store/domain-data/authority/authority";
import { requestApplicationAccessRequest } from "store/domain-data/application-access-request/applicationAccessRequest";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { formValidationUtils } from "utils/formValidationUtils";
import Guard from "components/Guard/Guard";
import { unwrapResult } from "@reduxjs/toolkit";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import { useNavigate } from "components/RouteController/functions/useNavigate";
import { urlHelper } from "components/RouteController/functions/urlHelper";
import { fetchApplicationV3ByApplicationNumberAndAuthority } from "store/domain-data/application-v3/applicationV3";
import { IApplicationAccessRequestApplicantFormData } from "models/ApplicationAccessRequest.model";
import { useToast } from "hooks/useToast";
import { usePostHog } from "posthog-js/react";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import { IAuthorityEntity } from "models/Authority.model";

type Props = {
  onClose: () => void;
};

const validationSchema = yup.object({
  applicationNumber: yup.string().trim().required(formValidationUtils.getErrorMessageForRequiredField()),
  authorityId: yup.number().required(formValidationUtils.getErrorMessageForRequiredField()),
});

const ApplicationAccessRequestApplicantDialog: React.FC<Props> = ({ onClose }) => {
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();
  const { t } = useTranslation();
  const { navigateTo } = useNavigate();
  const { toastSuccess, toastError } = useToast();

  const jurisdictionId = useStoreSelector(selectCurrentJurisdictionId);
  const allPublicAuthoritiesInJurisdiction = useStoreSelector((state) =>
    selectPublicAuthoritiesByJurisdiction(state, jurisdictionId)
  );
  const independentAuthorities = useStoreSelector(selectIndependentAuthoritiesForAuthenticatedUser);

  // Merge public and independent authorities
  const userAuthorities = React.useMemo(() => {
    const sortFn = (a: IAuthorityEntity, b: IAuthorityEntity) => a.displayName.localeCompare(b.displayName);

    if (allPublicAuthoritiesInJurisdiction.length > 0 && independentAuthorities.length > 0) {
      const authorities = [...allPublicAuthoritiesInJurisdiction, ...independentAuthorities].sort(sortFn);
      return authorities;
    }

    return allPublicAuthoritiesInJurisdiction.sort(sortFn);
  }, [allPublicAuthoritiesInJurisdiction, independentAuthorities]);

  const authoritySelectItems = userAuthorities.map((authority) => ({
    value: authority.id,
    displayName: authority.displayName,
  }));

  const formRef = React.useRef<FormikProps<IApplicationAccessRequestApplicantFormData>>(null);
  const [error, setError] = React.useState<ServiceError | null>(null);
  const [formValues, setFormValues] = React.useState<IApplicationAccessRequestApplicantFormData>({
    applicationNumber: "",
    authorityId: first(authoritySelectItems)?.value!,
  });

  const handleSubmit = React.useCallback(
    async (
      values: IApplicationAccessRequestApplicantFormData,
      helpers: FormikHelpers<IApplicationAccessRequestApplicantFormData>
    ) => {
      const logger = serviceContainer.cradle.logger;
      if (!values) {
        return;
      }

      helpers.setSubmitting(true);
      const payload: IApplicationAccessRequestApplicantFormData = {
        applicationNumber: values.applicationNumber,
        authorityId: toInteger(values.authorityId),
      };

      try {
        await dispatch(requestApplicationAccessRequest(payload)).then(unwrapResult);
        toastSuccess(t(`Access requested`));
        posthog?.capture(`user access-requested`, { success: true });
        onClose();
      } catch (e) {
        const error = e as ServiceError;

        logger.error("Participant access request submit failed", error);
        setError(error);

        if (
          [
            ServiceErrorCode.ResourceNotFound,
            ServiceErrorCode.AccessRequestAlreadyGranted,
            ServiceErrorCode.AccessRequestExist,
            ServiceErrorCode.AccessRequestApplicationNotEditable,
          ].includes(error.code)
        ) {
          posthog?.capture(`user access-requested`, {
            success: false,
            errorCode: error.code,
            errorMessage: error.message,
          });
        }
      } finally {
        setFormValues(payload);
        helpers.setSubmitting(false);
      }
    },
    [t, dispatch, onClose, toastSuccess, posthog]
  );

  const handleOpenProject = React.useCallback(async () => {
    try {
      const application = await dispatch(fetchApplicationV3ByApplicationNumberAndAuthority(formValues)).then(
        unwrapResult
      );
      if (!application) {
        throw new Error("Application not found");
      }

      // Construct application url
      let urlFn = urlHelper.getUrlForApplicationDetailsPage;
      if (application?.status === ApplicationStatus.Draft) {
        urlFn = urlHelper.getUrlForApplicationFormPage;
      }

      navigateTo(urlFn({ applicationId: application.id }));
    } catch (e) {
      toastError(t("Failed to open project"));
    }
  }, [t, toastError, dispatch, navigateTo, formValues]);

  const severityColour = React.useMemo(() => {
    if (
      error?.code === ServiceErrorCode.AccessRequestAlreadyGranted ||
      error?.code === ServiceErrorCode.AccessRequestExist
    ) {
      return "warning";
    }

    return "error";
  }, [error]);

  const DialogTitle = (
    <FlexBox alignItems={"center"}>
      <SystemGroupAccessIcon width={20} height={20} />
      <Spacer x={4} />
      {t(`Request access to a project`)}
    </FlexBox>
  );

  return (
    <Dialog
      open={true}
      data-testid={"ApplicationAccessRequestApplicantDialog"}
      title={DialogTitle}
      onClose={onClose}
      scroll="body"
    >
      <Formik<IApplicationAccessRequestApplicantFormData>
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        innerRef={formRef}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <StyledDialogContent>
              <StyledTypography variant="body1">
                To request access to a project, enter the project's building consent number or application (BLD number)
                and select the building consent authority.
              </StyledTypography>
              <StyledTypography variant="body1">
                If the number matches a project, the system notifies the managers of the project and they may accept or
                decline your request. Your profile information is included in the notification to managers.
              </StyledTypography>
              <StyledTypography variant="body1">
                If a manager does not respond, your request expires automatically in seven (7) calendar days.
              </StyledTypography>

              <FormikTextInput
                label={t("Building consent number or application number")}
                required={true}
                fieldName={"applicationNumber"}
              />
              <Spacer y={6} />
              <FormikSelect
                label={t("Authority")}
                required={true}
                fieldName={"authorityId"}
                items={authoritySelectItems}
              />

              <Spacer y={6} />
              <Guard condition={error?.code === ServiceErrorCode.ResourceNotFound}>
                {/* Application number not found */}
                <StyledAlert severity={"warning"}>
                  {t(`No match exists for this combination of number and authority`)}
                </StyledAlert>
              </Guard>
              <Guard condition={error?.code === ServiceErrorCode.AccessRequestAlreadyGranted}>
                {/* User already has access */}
                <StyledAlert
                  severity={"success"}
                  action={
                    <Button variant="outlined" data-testid="OpenProjectButton" onClick={handleOpenProject}>
                      Open Project
                    </Button>
                  }
                >
                  {error?.message}
                </StyledAlert>
              </Guard>
              <Guard
                condition={
                  error &&
                  error.code !== ServiceErrorCode.ResourceNotFound &&
                  error.code !== ServiceErrorCode.AccessRequestAlreadyGranted
                }
              >
                {/* Any other errors */}
                <StyledAlert severity={severityColour}>{error?.message}</StyledAlert>
              </Guard>
            </StyledDialogContent>

            <DialogActions>
              <DialogActionCancel data-testid="CancelButton" onClick={onClose}>
                {t(`Cancel`)}
              </DialogActionCancel>
              <DialogActionGroup>
                <DialogActionPrimary data-testid="SubmitButton" disabled={isSubmitting || !isValid} type="submit">
                  {t(`Submit`)}
                </DialogActionPrimary>
              </DialogActionGroup>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const StyledAlert = styled(OdlAlert)(
  ({ theme }) => css`
    margin-bottom: 16px;
  `
);

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
  `
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    max-width: 500px;
  `
);

export default ApplicationAccessRequestApplicantDialog;
