import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import Box from "components/Box/Box";
import Spacer from "components/Spacer/Spacer";
import FormikEmailInput from "components/FormikEmailInput/FormikEmailInput";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { serviceContainer } from "services/serviceContainer";
import { ForgotPasswordFormValues } from "pages/authentication/AuthenticationPage.types";
import Guard from "components/Guard/Guard";
import { ServiceError } from "services/ServiceError";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import MessageSection from "pages/authentication/MessageSection";
import LinkAction from "pages/authentication/LinkAction";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import FormikSubmitButton from "components/FormikSubmitButton/FormikSubmitButton";

type Props = {};

const validationSchema = yup.object({
  email: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForEmail(), formValidationUtils.getErrorMessageForEmail()),
});

const initialValues = {
  email: "",
};

const ForgotPasswordSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [networkError, setNetworkError] = React.useState<ServiceError | null>(null);

  const handleSubmit = React.useCallback(
    async (formValues: ForgotPasswordFormValues) => {
      try {
        await serviceContainer.cradle.cognitoService.forgotPassword(formValues.email);
        history.push("/reset-password", {
          email: formValues.email,
        });
      } catch (e) {
        setNetworkError(e);
      }
    },
    [history]
  );

  return (
    <Box data-testid="ForgotPasswordSection" paddingTop={4}>
      <MessageSection title={t(`Forgot your password?`)}>
        {t(`If an account exists with this email we will send you instructions on how to reset your password`)}
      </MessageSection>
      <Spacer y={6} />
      <Formik<ForgotPasswordFormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <FlexBox spacing={6} direction={"column"}>
              <FormikEmailInput label={"Email"} fieldName="email" />
              <Guard condition={networkError}>
                <OdlAlert severity={"error"}>{networkError?.message}</OdlAlert>
              </Guard>
            </FlexBox>
            <Spacer y={4} />
            <FlexBox justifyContent={"flex-end"}>
              <FormikSubmitButton />
            </FlexBox>
            <Spacer y={6} />

            <LinkAction action={t(`Sign In here`)} hint={t(`Remember your password?`)} to="/" />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgotPasswordSection;
