import React from "react";
import { styled } from "@mui/material/styles";
import { Link, LinkProps } from "react-router-dom";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { NavigationItemsContext } from "components/odl-v2/Navigation/NavigationItemsContext";
import { css } from "@emotion/react";

type NavigationItemProps = Omit<LinkProps, "to" | "onClick"> & {
  to: string;
  icon: typeof ODL_ICONS[keyof typeof ODL_ICONS] | JSX.Element;
  isActive?: boolean;
  isDisabled?: boolean;
  isCollapsed?: boolean;
  "data-testid"?: string;
};

const excludedForwardProps = ["$isActive", "$isDisabled", "$isCollapsed"];

const NavigationItem: React.FC<NavigationItemProps> = React.forwardRef(
  (
    {
      icon,
      title,
      to,
      isActive = false,
      replace = false,
      isDisabled = false,
      isCollapsed: isCollapsedFromProps,
      "data-testid": dataTestId = "NavigationItem",
      ...otherProps
    },
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const { isCollapsed: isCollapsedFromContext } = React.useContext(NavigationItemsContext);

    const isCollapsed = React.useMemo(() => {
      // If specify `isCollapsed` from props, use that
      if (typeof isCollapsedFromProps !== "undefined") {
        return isCollapsedFromProps;
      }

      // Otherwise use value from context
      return isCollapsedFromContext;
    }, [isCollapsedFromProps, isCollapsedFromContext]);

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLAnchorElement>) => {
        // Should not perform click event when active or disabled, so no redirection could be triggered
        if (isActive || isDisabled) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }
      },
      [isActive, isDisabled]
    );

    return (
      <StyledNavigationItem
        to={to}
        replace={Boolean(replace)}
        $isActive={isActive}
        $isDisabled={isDisabled}
        $isCollapsed={isCollapsed}
        ref={ref}
        data-testid={dataTestId}
        data-is-active={isActive}
        data-is-collapsed={isCollapsed}
        aria-label={title}
        onClick={handleClick}
        {...otherProps}
      >
        <StyledIconContainer $isActive={isActive}>
          {typeof icon === "string" && (
            <StyledFontIcon
              name={icon}
              $isActive={isActive}
              $isDisabled={isDisabled}
              data-testid={"NavigationItemIcon"}
              data-title={title}
            />
          )}
          {typeof icon === "object" && icon}
        </StyledIconContainer>
        <StyledNavigationItemTitle $isCollapsed={isCollapsed} $isActive={isActive} $isDisabled={isDisabled}>
          {title}
        </StyledNavigationItemTitle>
      </StyledNavigationItem>
    );
  }
);

const StyledNavigationItem = styled(Link, {
  shouldForwardProp: (prop) => !excludedForwardProps.includes(prop.toString()),
})<{
  $isActive: boolean;
  $isDisabled: boolean;
  $isCollapsed: boolean;
}>(
  ({ theme, $isActive, $isDisabled, $isCollapsed }) => css`
    // Default
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    color: ${theme.palette.objective.dark.night};
    ${theme.mixins.flexGap("6px")}
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: ${theme.palette.objective.light.day};
    }
    
    // Collapsed
    ${
      $isCollapsed &&
      `
        display: inline-flex;
      `
    }

    // Active
    ${
      $isActive &&
      css`
        background-color: ${theme.palette.objective.blue.light};
        color: ${theme.palette.objective.light.white};
        &:hover {
          background-color: ${theme.palette.objective.blue.light};
        }

        &:before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          border-left: 4px solid ${theme.palette.primary.main};
        }
      `
    }

    // Disabled
    ${
      $isDisabled &&
      `
        cursor: not-allowed;
        pointer-events: none;
      `
    }
  `
);

const StyledIconContainer = styled("div", {
  shouldForwardProp: (prop) => !excludedForwardProps.includes(prop.toString()),
})<{
  $isActive: boolean;
}>(
  ({ theme, $isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    ${$isActive &&
    `
      position: relative;
      svg {
        fill: ${theme.palette.objective.blue.main};
      }
    `}
  `
);

const StyledFontIcon = styled(FontIcon, {
  shouldForwardProp: (prop) => !excludedForwardProps.includes(prop.toString()),
})<{ $isActive: boolean; $isDisabled: boolean }>(
  ({ theme, $isActive, $isDisabled }) => css`
    font-size: 20px;
    color: ${theme.palette.objective.dark.night};

    ${$isActive &&
    `
      color: ${theme.palette.objective.blue.main};
    `}

    ${$isDisabled &&
    `
      color: ${theme.palette.objective.dark.neutral};
    `}
  `
);

const StyledNavigationItemTitle = styled("span", {
  shouldForwardProp: (prop) => !excludedForwardProps.includes(prop.toString()),
})<{ $isCollapsed: boolean; $isActive: boolean; $isDisabled: boolean }>(
  ({ theme, $isCollapsed, $isActive, $isDisabled }) => css`
    text-wrap: none;
    white-space: nowrap;
    font-size: 16px;
    color: ${theme.palette.objective.dark.night};
    padding: 0 10px;
    transition: ${theme.mixins.layoutTransition("left")};

    ${$isCollapsed &&
    `
      display: none;
    `};

    ${$isActive &&
    `
      color: ${theme.palette.objective.blue.main};
    `}

    ${$isDisabled &&
    `
      color: ${theme.palette.objective.dark.neutral};
    `}
  `
);

export default NavigationItem;
