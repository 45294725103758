import React from "react";
import { PersonalInfoFormViewMode } from "./PersonalInfoForm";
import { useTranslation } from "react-i18next";
import Guard from "../Guard/Guard";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type SetViewButtonProps = {
  currentViewMode: PersonalInfoFormViewMode;
  onChangeViewMode: (view: PersonalInfoFormViewMode) => void;
};

const PersonalInfoViewModeButton: React.FC<SetViewButtonProps> = ({ onChangeViewMode, currentViewMode }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Guard condition={currentViewMode === PersonalInfoFormViewMode.PersonalInfo}>
        <StyledButton
          data-testid={"EnterManualAddressButton"}
          onClick={() => {
            onChangeViewMode(PersonalInfoFormViewMode.ManualAddress);
          }}
        >
          {t("Enter address manually")}
        </StyledButton>
      </Guard>
      <Guard condition={currentViewMode === PersonalInfoFormViewMode.ManualAddress}>
        <StyledButton
          data-testid={"ManualAddressGoBackButton"}
          onClick={() => {
            onChangeViewMode(PersonalInfoFormViewMode.PersonalInfo);
          }}
          startIcon={<FontIcon name={ODL_ICONS.NAVIGATION_CHEVRON_LEFT} />}
        >
          {t(`Back to contact`)}
        </StyledButton>
      </Guard>
    </React.Fragment>
  );
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    width: fit-content;
  `
);

export default PersonalInfoViewModeButton;
