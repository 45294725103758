import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel, Paper, TextField, Autocomplete } from "@mui/material";
import { serviceContainer } from "services/serviceContainer";
import trim from "lodash/trim";
import debounce from "lodash/debounce";
import { useFormikContext } from "formik";
import { IAddressEntity, IAddressSuggestionEntity } from "models/Address.model";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import get from "lodash/get";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { css } from "@emotion/react";

type FormikAddressSearchProps = {
  label: string;
  required: boolean;
};

const addressFields = ["address1", "address2", "city", "state", "country", "zipCode", "fullAddress"];

const FormikAddressSearch: React.FC<FormikAddressSearchProps> = ({ required, label }) => {
  const { t } = useTranslation();
  const { setFieldValue, errors, values } = useFormikContext<IAddressEntity>();

  const [addressSearchInputValue, setAddressSearchInputValue] = React.useState(values.fullAddress);
  const [isOptionListOpen, setIsOptionListOpen] = React.useState(false);
  const [addressOptions, setAddressOptions] = React.useState<IAddressSuggestionEntity[]>([]);

  const inputId = React.useMemo(() => `address-${nanoid(5)}`, []);
  const isAutocompleteInputFocusedRef = React.useRef(false);

  const debouncedLookupAddress = React.useMemo(() => {
    const lookupAddress = async (keywords: string) => {
      try {
        const options = await serviceContainer.cradle.addressCheckerService.search(keywords);

        // If autocomplete input is not focused anymore, we drop the result
        if (!isAutocompleteInputFocusedRef.current) {
          return;
        }

        setAddressOptions(options);
        setIsOptionListOpen(true);
      } catch (e) {
        serviceContainer.cradle.logger.error(e);
      }
    };

    return debounce(lookupAddress, 1000);
  }, []);

  const handleAddressSearchInputChange = React.useCallback(
    async (event: any, value: string, reason: string) => {
      if (!value && reason === "reset") {
        return;
      }

      setAddressSearchInputValue(value);

      if (!value || value.length < 2) {
        return;
      }

      await debouncedLookupAddress(value);
    },
    [debouncedLookupAddress]
  );

  const clearFormValues = React.useCallback(() => {
    for (const field of addressFields) {
      setFieldValue(field, "");
    }
  }, [setFieldValue]);

  const handleAddressSearchValueChange = React.useCallback(
    async (event: any, value: any, reason: string) => {
      setIsOptionListOpen(false);
      clearFormValues();

      if (!value) {
        return;
      }

      // Look up address details when selecting one option from autocomplete input
      const address = await serviceContainer.cradle.addressCheckerService.lookup(value.identifier);
      for (const [key, value] of Object.entries(address)) {
        if (!addressFields.includes(key)) {
          continue;
        }
        setFieldValue(key, value);
      }
    },
    [clearFormValues, setFieldValue]
  );

  const handleAddressSearchInputBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setIsOptionListOpen(false);

      // If input value is empty, clear the address details
      if (!trim(evt.target.value)) {
        setAddressSearchInputValue("");
        clearFormValues();
        return;
      }

      // If input value is not empty, reset address details
      setAddressSearchInputValue(values.fullAddress);
    },
    [clearFormValues, values.fullAddress]
  );

  const hasAddressError = React.useMemo(() => {
    return addressFields.some((field) => Boolean(get(errors, field)));
  }, [errors]);

  const addressErrorMessage = React.useMemo(() => {
    if (!hasAddressError) {
      return "";
    }

    if (errors["fullAddress"]) {
      return t("Required");
    }

    return t("Complete address required for participant's role");
  }, [errors, hasAddressError, t]);

  const renderInput = React.useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <div ref={params.InputProps.ref}>
          <TextField
            inputProps={params.inputProps}
            fullWidth={true}
            type="search"
            autoComplete="off"
            onBlur={(evt) => {
              isAutocompleteInputFocusedRef.current = false;

              const inputProps: any = params.inputProps;
              if (inputProps.onBlur) {
                inputProps.onBlur(evt);
              }

              handleAddressSearchInputBlur(evt);
            }}
            onFocus={(evt) => {
              isAutocompleteInputFocusedRef.current = true;

              const inputProps: any = params.inputProps;
              if (inputProps.onFocus) {
                inputProps.onFocus(evt);
              }
            }}
            error={hasAddressError}
          />
        </div>
      );
    },
    [handleAddressSearchInputBlur, hasAddressError]
  );

  React.useEffect(() => {
    setAddressSearchInputValue(values.fullAddress);
  }, [values.fullAddress]);

  return (
    <FormControl fullWidth={true} hiddenLabel={true} variant={"outlined"} required={required} error={hasAddressError}>
      <StyledInputLabel htmlFor={inputId} shrink={false}>
        {t(label)}
      </StyledInputLabel>
      <Autocomplete
        data-testid="FormikAddressInputAutocomplete"
        id={inputId}
        freeSolo={false}
        openOnFocus={false}
        selectOnFocus={false}
        blurOnSelect={true}
        onChange={handleAddressSearchValueChange}
        onInputChange={handleAddressSearchInputChange}
        inputValue={addressSearchInputValue}
        open={isOptionListOpen}
        PaperComponent={StyledAutocompletePopupPaper}
        ListboxComponent={StyledAutocompleteListbox}
        renderInput={renderInput}
        options={addressOptions}
        getOptionLabel={getOptionLabel}
      />

      {hasAddressError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize("fullAddress")}`}>
          {addressErrorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const getOptionLabel = (option: IAddressSuggestionEntity) => option.fullAddress;

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.text.primary};
  `
);

const StyledAutocompletePopupPaper = styled(Paper)(
  ({ theme }) => css`
    box-shadow: ${theme.shadows[6]};

    .MuiAutocomplete-noOptions {
      font-size: 14px;
    }
  `
);

const StyledAutocompleteListbox = styled("ul")(
  ({ theme }) => css`
    & > li {
      border-left: 4px solid transparent;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
    }

    & > li:hover {
      border-left: 4px solid ${theme.palette.primary.main};
      background: ${theme.palette.objective.blue.light};
    }
  `
);

export default FormikAddressSearch;
