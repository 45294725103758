// ============================================================
// IMPORTANT: Please put sensitive test configs in configDev.ts
// ============================================================

// Proxy access to process.env to provide meaningful defaults

// Backend
export const BUILD_API_ENDPOINT: string = process.env.REACT_APP_BUILD_API_ENDPOINT || "";
export const USE_MOCK_BACKEND = process.env.REACT_APP_USE_MOCK_BACKEND === "true";

// Env
export const NODE_ENV: string = process.env.NODE_ENV || "development";
export const IS_DEV_ENV: boolean = NODE_ENV === "development";
export const IS_TEST_ENV: boolean = NODE_ENV === "test";
export const IS_PRODUCTION_ENV: boolean = NODE_ENV === "production";

// AWS
export const AWS_COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION || "";
export const AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID || "";
export const AWS_USER_POOLS_WEB_CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || "";
export const AWS_COGNITO_USE_MOCK = process.env.REACT_APP_AWS_COGNITO_USE_MOCK === "true";

// Google Map
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
export const GOOGLE_MAP_ZOOM_DEFAULT = 18;

// Feature flags
export const USER_PROFILE_IS_UPDATE_NOTIFICATION_METHOD_ENABLED: boolean = false;
export const BNS_FETCH_PROFILE_ENABLED = process.env.REACT_APP_BNS_FETCH_PROFILE_ENABLED === "true";
export const BNS_MARK_SEEN_ENABLED = process.env.REACT_APP_BNS_MARK_SEEN_ENABLED === "true";
export const BNS_FETCH_USER_NOTIFICATIONS_ENABLED =
  process.env.REACT_APP_BNS_FETCH_USER_NOTIFICATIONS_ENABLED === "true";
export const BNS_FETCH_APPLICATION_NOTIFICATIONS_ENABLED =
  process.env.REACT_APP_BNS_FETCH_APPLICATION_NOTIFICATIONS_ENABLED === "true";
export const BNS_FETCH_NOTIFICATION_DETAILS_ENABLED =
  process.env.REACT_APP_BNS_FETCH_NOTIFICATION_DETAILS_ENABLED === "true";
export const NOTIFICATION_SETTINGS_ENABLED = process.env.REACT_APP_NOTIFICATION_SETTINGS_ENABLED === "true";
export const NOTIFICATION_CENTRE_ENABLED = process.env.REACT_APP_NOTIFICATION_CENTRE_ENABLED === "true";
export const DIRECT_TRANSFER_PROOF_OF_PAYMENT_ENABLED =
  process.env.REACT_APP_DIRECT_TRANSFER_PROOF_OF_PAYMENT_ENABLED === "true";
export const FULL_CONTACT_PHONE_ENABLED = process.env.REACT_APP_FULL_CONTACT_PHONE_ENABLED === "true";
export const PARTICIPANT_EDITABLE_AFTER_SUBMIT_ENABLED =
  process.env.REACT_APP_PARTICIPANT_EDITABLE_AFTER_SUBMIT_ENABLED === "true";
export const DOCUMENT_MERGING_ENABLED = process.env.REACT_APP_DOCUMENT_MERGING_ENABLED === "true";
export const RESEND_VERIFICATION_EMAIL_ENABLED = process.env.REACT_APP_RESEND_VERIFICATION_EMAIL_ENABLED === "true";
export const REACT_APP_OUTSTANDING_APPS_BADGES_ENABLED =
  process.env.REACT_APP_OUTSTANDING_APPS_BADGES_ENABLED === "true";

export const FOCUS_DASHBOARD_ENABLED = process.env.REACT_APP_FOCUS_DASHBOARD_ENABLED === "true";
export const CONVERT_IMAGE_TO_PDF_ENABLED = process.env.REACT_APP_CONVERT_IMAGE_TO_PDF_ENABLED === "true";

// Notifications
export const NOTIFICATIONS_POPUP_PAGE_SIZE = 7;
export const NOTIFICATIONS_LIST_PAGE_SIZE = 20;

// Legal Links
export const TERMS_OF_SERVICE_LINK = process.env.REACT_APP_TERMS_OF_SERVICE_LINK || "";
export const PRIVACY_POLICY_LINK = process.env.REACT_APP_PRIVACY_POLICY_LINK || "";

// Application Links
export const BUILD_IOS_APP_DOWNLOAD_LINK = process.env.REACT_APP_BUILD_IOS_APP_DOWNLOAD_LINK || "";
export const HOW_TO_SUPPORT_YOUR_APPLICATION_LINK = process.env.REACT_APP_HOW_TO_SUPPORT_YOUR_APPLICATION_LINK || "";

// Support
export const WEB_HELP_LINK = process.env.REACT_APP_WEB_HELP_LINK || "";
export const WEB_SUPPORT_LINK = process.env.REACT_APP_WEB_SUPPORT_LINK || "";
export const WEB_SUPPORT_EMAIL = process.env.REACT_APP_WEB_SUPPORT_EMAIL || "";
export const WEB_SUPPORT_PHONE = process.env.REACT_APP_WEB_SUPPORT_PHONE || "";

// Date Formats, TODO: BLD-2703 const should be better named
export const DATE_FORMATS = {
  MODIFIED_DATE: "d LLL yyyy - p",
  FULL_DATE: "dd MMM yyyy",
  FULL_DATE_WITH_TIME: "dd MMM yyyy h:mm a",
};

export const MIME_TYPES = {
  APPLICATION_PDF: "application/pdf",
  IMAGE_JPEG: "image/jpeg",
  IMAGE_PNG: "image/png",
};

export const FILE_TYPES = {
  PDF: "pdf",
};

// Document Container Upload Configs
export const DOCUMENT_CONTAINER_UPLOAD_CONFIG = {
  ACCEPT_MAX_FILE_SIZE: 250 * 1024 * 1024, // 250 MB
  ACCEPT_MAX_FILE_SIZE_DESCRIPTION: "250MB",
  ACCEPT_MIN_FILE_SIZE: 6000, // about 6 KB
  ACCEPT_MAX_FILE_NUMBERS: 100, // BLD-7145: Changed to max 100 files per container
  ACCEPT_MIME_TYPES: CONVERT_IMAGE_TO_PDF_ENABLED
    ? [MIME_TYPES.APPLICATION_PDF, MIME_TYPES.IMAGE_JPEG, MIME_TYPES.IMAGE_PNG]
    : [MIME_TYPES.APPLICATION_PDF],
  ACCEPT_MIME_TYPE_DESCRIPTION: "PDF",
};

// PDFTron Key
export const PDFTRON_LICENSE_KEY = process.env.REACT_APP_PDFTRON_LICENSE_KEY || "";

// Spreedly Library
export const SPREEDLY_LIBRARY = "https://core.spreedly.com/iframe/express-3.min.js";

// Pagination
export const DEFAULT_PAGE_SIZE = 10;

// Phone
export const DEFAULT_PHONE_COUNTRY_CODE = "+64";

// Search
export const MIN_CHARACTERS_TO_ACTIVATE_SEARCH = 3;
export const DEBOUNCE_SEARCH_IN_MS = 400;

// Captcha
export const H_CAPTCHA_SITE_KEY = process.env.REACT_APP_H_CAPTCHA_SITE_KEY || "";

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "";
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE || "";

// Posthog
export const PUBLIC_POSTHOG_KEY = process.env.REACT_APP_PUBLIC_POSTHOG_KEY || "";
export const PUBLIC_POSTHOG_HOST = process.env.REACT_APP_PUBLIC_POSTHOG_HOST || "";

// Invalid coordinates value "420" used in "Demo City Council" scenario (BLD-5256)
export const DEMO_CITY_COUNCIL_COORD = {
  LATITUDE: 420,
  LONGITUDE: 420,
};
export const DEMO_CITY_COUNCIL_COORD_FOR_MAP = {
  LATITUDE: -40.349915,
  LONGITUDE: 175.618281,
};
