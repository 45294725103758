import React from "react";
import { useFormikContext } from "formik";
import isEqual from "lodash/isEqual";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";

type Props = {
  title?: React.ReactNode;
  disabled?: boolean;
};

const FormikSubmitButton: React.FC<Props> = ({ title, disabled }) => {
  const { t } = useTranslation();
  const { initialValues, values, isValid } = useFormikContext<any>();

  const isSubmitDisabled = React.useMemo(() => {
    const hasNoChanges = isEqual(initialValues, values);
    return disabled || hasNoChanges || !isValid;
  }, [disabled, initialValues, values, isValid]);

  return (
    <Button
      variant={"contained"}
      type="submit"
      size={"large"}
      disabled={isSubmitDisabled}
      endIcon={<FontIcon name={"i-navigation-arrow-right"} />}
    >
      {title || t(`Next`)}
    </Button>
  );
};

export default FormikSubmitButton;
