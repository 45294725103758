import React from "react";
import { styled } from "@mui/material/styles";
import { formatNotificationDateStr } from "utils/formatNotificationDateStr";
import { DATE_FORMATS } from "constants/configs";
import { css } from "@emotion/react";
import { Card, CardActionArea, CardHeader } from "@mui/material";

type Prop = {
  icon: React.ReactNode;
  subject: string;
  datetime: string;
  accessory: React.ReactNode;
  onCardClick?: () => void;
};

const NotificationCard: React.FC<Prop> = ({ icon, subject, datetime, accessory, onCardClick, ...otherProps }) => {
  const notificationCardIsClickable = React.useMemo(() => {
    if (!onCardClick) {
      return false;
    }
    return true;
  }, [onCardClick]);

  return (
    <StyledCard
      $isClickable={notificationCardIsClickable}
      onClick={onCardClick}
      data-testid={"NotificationCard"}
      {...otherProps}
    >
      <CardActionArea>
        <CardHeader
          title={subject}
          subheader={formatNotificationDateStr(datetime, DATE_FORMATS.FULL_DATE)}
          avatar={icon}
          action={<StyledAccessory>{accessory}</StyledAccessory>}
        />
      </CardActionArea>
    </StyledCard>
  );
};

const StyledCard = styled(Card, { shouldForwardProp: (prop) => prop !== "$isClickable" })<{ $isClickable?: boolean }>(
  ({ theme, $isClickable }) => css`
    // display: flex;

    &:hover {
      background: ${theme.palette.objective.light.day};
    }

    ${$isClickable &&
    `
      cursor: pointer;
    `}

    ${theme.mixins.flexGap("12px")}
  `
);

const StyledAccessory = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-shrink: 0;
    width: 32px;
    height: 32px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `
);

export default NotificationCard;
