import { css } from "@emotion/react";
import { styled } from "@mui/material/styles";
import newAmendmentIcon from "assets/images/amendment-icon.svg";
import newSupportingApplicationIcon from "assets/images/certificate-icon.svg";
import newApplicationIcon from "assets/images/new-application-icon.svg";
import Section from "components/Layout/Section";
import SectionHeader from "components/Layout/SectionHeader";
import Panel from "components/odl-v2/Layout/Panel";
import StartApplicationIntentCard from "pages/dashboard/StartApplicationIntentCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type Props = {};

const StartApplicationSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickNewApplication = React.useCallback(() => {
    history.push("/new-application/address");
  }, [history]);

  const handleClickAmendExisting = React.useCallback(() => {
    history.push("/new-application/child-application?type=amendment");
  }, [history]);

  const handleClickSupportApplication = React.useCallback(() => {
    history.push("/new-application/child-application?type=supporting");
  }, [history]);

  return (
    <Section>
      <SectionHeader title={t(`Start an application`)} />
      <IntentContainer data-testid={"IntentSection"} hasInnerContainer={false}>
        <StartApplicationIntentCard
          icon={<StyledApplicationIntentIcon src={newApplicationIcon} alt={"New application icon"} />}
          title={t(`New application`)}
          subTitle={t(
            `New Building Consent, PIM, Solid Fuel Burner, Exemption or Certificate of Acceptance applications`
          )}
          onClick={handleClickNewApplication}
        />
        <StartApplicationIntentCard
          icon={<StyledApplicationIntentIcon src={newAmendmentIcon} alt={"New amendment icon"} />}
          title={t(`Amendment application`)}
          subTitle={t(`Modification to an existing building consent or solid fuel burner consent`)}
          onClick={handleClickAmendExisting}
        />
        <StartApplicationIntentCard
          icon={
            <StyledApplicationIntentIcon src={newSupportingApplicationIcon} alt={"New supporting application icon"} />
          }
          title={t(`Supporting application`)}
          subTitle={t(
            `Certificate for Public Use, Code Compliance Certificate or Extension of Time related to an existing consent`
          )}
          onClick={handleClickSupportApplication}
        />
      </IntentContainer>
    </Section>
  );
};

const IntentContainer = styled(Panel)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("lg")} {
      flex-direction: column;
      ${theme.mixins.flexGap("8px")}
    }
  `
);

const StyledApplicationIntentIcon = styled("img")(
  ({ theme }) => css`
    width: 80px;
  `
);

export default StartApplicationSection;
