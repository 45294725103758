import React from "react";
import { IApplicationAccessRequestEntity } from "models/ApplicationAccessRequest.model";
import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";

type RequestAccessPersonDetailsProps = { accessRequest: IApplicationAccessRequestEntity };

const RequestAccessPersonDetails: React.FC<RequestAccessPersonDetailsProps> = ({ accessRequest }) => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledPersonName>
        {accessRequest.requestUser.firstName} {accessRequest.requestUser.lastName}
        {accessRequest.requestUser.organisation && <span>, {accessRequest.requestUser.organisation}</span>}
      </StyledPersonName>
      <StyledDetailsContainer>
        <StyledDetailsContainerLeft>
          <StyledDataHeader>{t(`Address`)}</StyledDataHeader>
          <StyledData>{accessRequest.requestUser.address.fullAddress}</StyledData>
        </StyledDetailsContainerLeft>
        <StyledDetailsContainerRight>
          <Box>
            <StyledDataHeader>{t(`Email`)}</StyledDataHeader>
            <Link variant="body1" href={`mailto:${accessRequest.requestUser.email}`}>
              <StyledData>{accessRequest.requestUser.email}</StyledData>
            </Link>
          </Box>
          <Box>
            <StyledDataHeader>{t(`Phone`)}</StyledDataHeader>
            <StyledData>{accessRequest.requestUser.phoneNumber}</StyledData>
          </Box>
        </StyledDetailsContainerRight>
      </StyledDetailsContainer>
    </StyledContainer>
  );
};

const StyledDataHeader = styled("div")(
  ({ theme }) => css`
    margin-bottom: 4px;
    color: ${theme.palette.text.secondary};
  `
);

const StyledData = styled("div")(
  ({ theme }) =>
    css`
      font-size: 16px;
      text-wrap: wrap;
      word-wrap: break-word;
    `
);

const StyledPersonName = styled("div")(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${theme.palette.text.primary};
  `
);

const StyledContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    ${theme.mixins.flexGap(10)}
  `
);

const StyledDetailsContainerRight = styled("div")(
  ({ theme }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 50%;
    ${theme.mixins.flexGap(12)}
  `
);

const StyledDetailsContainerLeft = styled("div")(
  ({ theme }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 50%;
  `
);

const StyledDetailsContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap(24)}
  `
);

export default RequestAccessPersonDetails;
