import React from "react";
import AlertAction from "components/odl-v2/Alert/AlertAction";
import Spacer from "components/Spacer/Spacer";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import { useTranslation } from "react-i18next";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionSecondary from "components/odl-v2/Dialog/DialogActionSecondary";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  completeApplicationAccessRequest,
  rejectApplicationAccessRequest,
  selectApplicationAccessRequestEntityById,
} from "store/domain-data/application-access-request/applicationAccessRequest";
import { useToast } from "hooks/useToast";
import { usePostHog } from "posthog-js/react";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchApplicationById, selectApplicationEntityById } from "store/domain-data/application/application";
import ApplicationCardMinimal from "components/ApplicationCard/ApplicationCardMinimal";
import RequestAccessPersonDetails from "pages/dashboard/RequestAccessPersonDetails";
import ParticipantEditDialog from "components/ParticipantEditDialog/ParticipantEditDialog";
import { IParticipantPersonalInfoFormData } from "models/Participant.model";
import { fetchParticipantTypes } from "store/domain-data/participant-type/participantType";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import { fetchParticipants } from "store/domain-data/participant/participant";
import { Divider } from "@mui/material";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import { formatDateStr } from "utils/formatDateStr";
import { DATE_FORMATS } from "constants/configs";

type ApplicationAccessRequestAlertItemProps = {
  accessRequestGuid: string;
};

const ApplicationAccessRequestAlertItem: React.FC<ApplicationAccessRequestAlertItemProps> = ({ accessRequestGuid }) => {
  const { t } = useTranslation();
  const { toastSuccess, toastError } = useToast();
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();

  const [showParticipantDialog, setShowParticipantDialog] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const accessRequest = useStoreSelector((state) => selectApplicationAccessRequestEntityById(state, accessRequestGuid));
  const application = useStoreSelector((state) =>
    selectApplicationEntityById(state, accessRequest?.applicationId || 0)
  );

  React.useEffect(() => {
    dispatch(fetchApplicationById(accessRequest?.applicationId || 0));
  }, [accessRequest, dispatch]);

  const handleClickOrganisationMergeInvitation = React.useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleClickDeclineInvitation = React.useCallback(async () => {
    try {
      await dispatch(rejectApplicationAccessRequest(accessRequest?.requestGuid || "")).then(unwrapResult);
      toastSuccess(t(`Access request declined`));
      posthog?.capture("user access-declined", { uuid: accessRequest?.requestGuid || "" });
      handleCloseDialog();
    } catch (e) {
      toastError(e);
    }
  }, [accessRequest, dispatch, handleCloseDialog, posthog, t, toastError, toastSuccess]);

  const defaultParticipantDetails: IParticipantPersonalInfoFormData = React.useMemo(() => {
    return {
      email: accessRequest?.requestUser.email || "",
      firstName: accessRequest?.requestUser.firstName || "",
      lastName: accessRequest?.requestUser.lastName || "",
      phoneCountryCode: accessRequest?.requestUser.countryCode || "",
      phone: accessRequest?.requestUser.phoneNumber || "",
      organisation: accessRequest?.requestUser.organisation || "",
      groupList: "",
      groupName: "",
      fullAddress: accessRequest?.requestUser.address.fullAddress || "",
      address1: accessRequest?.requestUser.address.address1 || "",
      address2: accessRequest?.requestUser.address.address2 || "",
      city: accessRequest?.requestUser.address.city || "",
      state: accessRequest?.requestUser.address.state || "",
      country: accessRequest?.requestUser.address.country || "",
      zipCode: accessRequest?.requestUser.address.zipCode || "",
      isManualAddress: accessRequest?.requestUser.address.isManualAddress || false,
    };
  }, [accessRequest]);

  const handleClickAcceptInvitation = React.useCallback(async () => {
    // Preload participant types for the application
    await dispatch(fetchParticipantTypes(accessRequest?.applicationId || 0));
    await dispatch(fetchParticipants({ applicationId: accessRequest?.applicationId || 0 }));
    posthog?.capture("user access-approved", { uuid: accessRequest?.requestGuid || "" });
    setShowParticipantDialog(true);
  }, [accessRequest, dispatch, posthog]);

  const requestUserFullName = React.useMemo(() => {
    if (!accessRequest) {
      return "";
    }

    if (accessRequest.requestUser.lastName) {
      return `${accessRequest.requestUser.firstName} ${accessRequest.requestUser.lastName}`;
    }
    return accessRequest.requestUser.firstName;
  }, [accessRequest]);

  const canRespond = React.useMemo(() => {
    if (!application) {
      return false;
    }

    return [
      ApplicationStatus.Draft,
      ApplicationStatus.VRFISuspended,
      ApplicationStatus.InProgress,
      ApplicationStatus.Refused,
    ].includes(application.status);
  }, [application]);

  const handleOnCloseParticipantEdit = React.useCallback(
    (created?: boolean) => {
      if (created) {
        dispatch(completeApplicationAccessRequest(accessRequest?.requestGuid || ""));
      }
      setShowParticipantDialog(false);
      setIsDialogOpen(false);
    },
    [accessRequest, dispatch]
  );

  if (!accessRequest || !application) {
    return null;
  }

  return (
    <React.Fragment>
      <OdlAlert
        severity={"info"}
        data-testid={"RespondToApplicationAccessRequestAlert"}
        header={t(
          `${requestUserFullName} has requested access to ${application.consentNumber || application.applicationNumber}`
        )}
        action={
          <AlertAction
            severity={"info"}
            data-testid={"RespondToApplicationAccessRequest"}
            onClick={handleClickOrganisationMergeInvitation}
          >
            {t(`Respond`)}
          </AlertAction>
        }
      >
        {t(`You have manage level access to this project and may grant or decline this request`)}
      </OdlAlert>
      <Dialog
        title={t(`Respond to request for access`)}
        data-testid={"RespondToApplicationAccessRequestAlertDialog"}
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth={false}
        scroll={"body"}
      >
        <StyledDialogContent>
          <StyledContent>
            <StyledParagraph>{t(`${requestUserFullName} has requested access to this project:`)}</StyledParagraph>
            <ApplicationCardMinimal applicationId={accessRequest.applicationId} />
            <StyledParagraph>
              {t(`If you accept the request, you can then add ${requestUserFullName} as a participant on the project.`)}
            </StyledParagraph>
            <StyledParagraph>
              {t(`If you decline, ${requestUserFullName} is notified and you do not need to take further action.`)}
            </StyledParagraph>
            <StyledParagraph>
              {t(`Request expires automatically on {{expireTime}}`, {
                expireTime: formatDateStr(accessRequest.expireTime, DATE_FORMATS.FULL_DATE),
              })}
            </StyledParagraph>
            <Divider />
            <RequestAccessPersonDetails accessRequest={accessRequest} />
            <Spacer y={2} />
          </StyledContent>
        </StyledDialogContent>
        <DialogActions>
          <DialogActionCancel data-testid="CancelButton" onClick={handleCloseDialog}>
            {t(`Cancel`)}
          </DialogActionCancel>
          <DialogActionGroup>
            <HoverTooltip
              title={
                canRespond
                  ? ""
                  : t("Project cannot be edited when status is: {{status}}. Please try again when status changes.", {
                      status: application.status,
                    })
              }
            >
              <DialogActionPrimary
                disabled={!canRespond}
                data-testid={"AcceptButton"}
                onClick={handleClickAcceptInvitation}
              >
                {t(`Accept`)}
              </DialogActionPrimary>
            </HoverTooltip>
            <DialogActionSecondary data-testid={"DeclineButton"} onClick={handleClickDeclineInvitation}>
              {t(`Decline`)}
            </DialogActionSecondary>
          </DialogActionGroup>
        </DialogActions>
      </Dialog>
      {showParticipantDialog && (
        <ParticipantEditDialog
          applicationId={accessRequest.applicationId}
          participantId={0}
          onClose={handleOnCloseParticipantEdit}
          allowSearch={false}
          initialPersonalValues={defaultParticipantDetails}
        />
      )}
    </React.Fragment>
  );
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    min-width: 800px;
    padding-bottom: 0;
  `
);

const StyledContent = styled("div")(
  ({ theme }) => css`
    max-width: 800px;
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("16px")}

    .MuiDivider-root {
      margin-top: 16px;
    }
  `
);

const StyledParagraph = styled("p")(
  ({ theme }) => css`
    display: flex;
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.text.primary};
  `
);

export default ApplicationAccessRequestAlertItem;
