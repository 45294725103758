import React from "react";
import { MenuProps, Menu as MuiMenu } from "@mui/material";
import { PopoverOrigin } from "@mui/material/Popover/Popover";

const defaultAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const Menu: React.FC<MenuProps> = React.forwardRef(
  ({ anchorOrigin = defaultAnchorOrigin, anchorEl = null, ...props }, ref) => {
    return <MuiMenu ref={ref} anchorOrigin={anchorOrigin} anchorEl={anchorEl} {...props} />;
  }
);

export default Menu;
