import React from "react";
import MuiDialog, { DialogProps } from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogTitle from "components/odl-v2/Dialog/DialogTitle";
import { IconButton } from "@mui/material";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { css } from "@emotion/react";

type Props = Omit<DialogProps, "onClose" | "title"> & {
  title: React.ReactNode;
  onClose?: (event: React.SyntheticEvent) => void;
  disableCloseButton?: boolean;
};

const Dialog: React.FC<Props> = React.forwardRef(
  ({ title, children, onClose, disableCloseButton = false, ...props }, ref) => {
    const { t } = useTranslation();

    const handleClose = React.useCallback(
      (event: React.SyntheticEvent, reason?: string) => {
        if (!onClose || reason === "escapeKeyDown" || reason === "backdropClick") {
          return;
        }

        onClose(event);
      },
      [onClose]
    );

    const titleHtmlId = React.useMemo(() => `dialog-title-${nanoid(5)}`, []);

    return (
      <StyledDialog
        ref={ref}
        maxWidth={false}
        scroll={"body"}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        aria-labelledby={titleHtmlId}
        {...props}
      >
        <DialogTitle
          id={titleHtmlId}
          closeButton={
            <StyledIconButton disabled={disableCloseButton} onClick={handleClose} aria-label={t(`Close`)}>
              <StyledCloseIcon $disabled={disableCloseButton} name={"i-navigation-close"} />
            </StyledIconButton>
          }
        >
          {title}
        </DialogTitle>
        {children}
      </StyledDialog>
    );
  }
);

const StyledDialog = styled(MuiDialog)(
  ({ theme }) => css`
    .MuiDialog-paper {
      border: 1px solid ${theme.palette.primary.main};
    }
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 50px;
    height: 50px;
  `
);

const StyledCloseIcon = styled(FontIcon, { shouldForwardProp: (prop) => prop !== "$isDisabled" })<{
  $disabled: boolean;
}>(
  ({ theme, $disabled }) => css`
    color: ${theme.palette.objective.dark.night};
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${$disabled &&
    css`
      color: ${theme.palette.objective.light.allspice};
    `}
  `
);

export default Dialog;
