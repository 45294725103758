import React from "react";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import FlexBox from "components/FlexBox/FlexBox";
import { Button } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { DocumentUtil, IDocumentEntity } from "models/Document.model";
import { DocumentStatus } from "models/DocumentStatus.model";
import { useTranslation } from "react-i18next";
import { serviceContainer } from "services/serviceContainer";
import { selectApplicationDocumentUploadQueuedItems } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { removeApplicationDocumentByApplicationIdAndDocumentName } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { isEmpty } from "lodash";

export const useRemoveFailedApplicationDocuments = (applicationId: number, documents: IDocumentEntity[]) => {
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();
  const { t } = useTranslation();

  const queuedItems = useStoreSelector(selectApplicationDocumentUploadQueuedItems);
  const failedDocuments = documents.filter(
    (document) =>
      document.uploadStatus === DocumentStatus.Error || DocumentUtil.isPendingNotInQueue(document, queuedItems)
  );

  const handleRemoveFailed = React.useCallback(async () => {
    const logger = serviceContainer.cradle.logger;

    try {
      await Promise.all(
        failedDocuments.map((document) =>
          dispatch(
            removeApplicationDocumentByApplicationIdAndDocumentName({ applicationId, documentName: document.name })
          ).then(unwrapResult)
        )
      );
    } catch (e) {
      logger.error("Remove failed documents failed", e);
      toastError(e);
    }
  }, [dispatch, toastError, applicationId, failedDocuments]);

  const removeFailedButton = React.useMemo(() => {
    if (isEmpty(failedDocuments)) {
      return null;
    }

    return (
      <FlexBox marginTop={4}>
        <Button onClick={handleRemoveFailed}>
          <FontIcon name={"i-format-trash-can"} marginRight={1} />
          {t("Remove failed")}
        </Button>
      </FlexBox>
    );
  }, [t, handleRemoveFailed, failedDocuments]);

  return removeFailedButton;
};
