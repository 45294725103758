import React from "react";
import { styled } from "@mui/material/styles";
import { Theme } from "@mui/material";
import { css } from "@emotion/react";

type Props = {
  label: string;
  adornment?: number;
};

const TabLabel: React.FC<Props> = ({ label, adornment }) => {
  if (!adornment) {
    return <span>{label}</span>;
  }
  return (
    <StyledContainer>
      {label}
      <StyledAdornment $adornment={adornment} data-testid={`Adornment-${adornment}`} />
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("8px")}
    align-items: center;
  `
);

const StyledAdornment = styled("span")(
  ({ theme, $adornment }: { theme: Theme; $adornment: number }) => css`
    display: inline-block;
    line-height: 32px;  
    &::after {
      display: flex;
      justify-content: center;
      content: "${$adornment}";
      width: ${$adornment < 10 ? "17px" : "22px"};
      height: 21px;
      border-radius: 4px;
      padding: 0 4px;
      background-color: ${theme.palette.objective.red.main};
      color: ${theme.palette.objective.light.white};
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }
`
);

export default TabLabel;
