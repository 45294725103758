import React from "react";
import { useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import {
  selectApplicationEntityById,
  selectCanDeleteApplication,
  selectCanDuplicateApplication,
  selectCanEditApplicationFormForApplication,
  selectIsApplicationMigrated,
} from "store/domain-data/application/application";
import { selectCanManageParticipantNotificationPreferenceForApplicationId } from "store/domain-data/participant/participant";
import { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuTriggerIconButton from "components/odl-v2/Menu/MenuTriggerIconButton";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import MenuItemDivider from "components/odl-v2/Menu/MenuItemDivider";
import Guard from "components/Guard/Guard";
import { selectJurisdictionFormEntityById } from "store/domain-data/jurisdiction-form/jurisdictionForm";
import { JurisdictionFormCategory } from "models/JurisdictionForm.model";
import { NOTIFICATION_SETTINGS_ENABLED } from "constants/configs";
import unarchiveIconSvg from "assets/images/unarchive-icon.svg";
import archiveIconSvg from "assets/images/archive-icon.svg";

type Props = {
  applicationId: number;
  onClickDeleteApplication: () => any;
  onClickDownloadApplicationFormPdf: () => any;
  onClickPreviewApplicationFormPdf: () => any;
  onClickUpdateNotificationLevel: () => any;
  onClickSaveAsTemplate: () => any;
  onClickDuplicateApplication: () => any;
  onClickArchiveApplication: () => any;
  onClickViewSubmittedForm: () => any;
  onClickEditApplicationForm: () => any;
};

const ApplicationActionsMenu: React.FC<Props> = ({
  applicationId,
  onClickDeleteApplication,
  onClickDownloadApplicationFormPdf,
  onClickPreviewApplicationFormPdf,
  onClickUpdateNotificationLevel,
  onClickDuplicateApplication,
  onClickSaveAsTemplate,
  onClickArchiveApplication,
  onClickViewSubmittedForm,
  onClickEditApplicationForm,
}) => {
  const { t } = useTranslation();
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const jurisdictionForm = useStoreSelector((state) =>
    selectJurisdictionFormEntityById(state, application?.formId || 0)
  );
  const canUpdateNotificationLevel = useStoreSelector((state) =>
    selectCanManageParticipantNotificationPreferenceForApplicationId(state, applicationId)
  );
  const canEditApplicationForm = useStoreSelector((state) =>
    selectCanEditApplicationFormForApplication(state, applicationId)
  );
  const isApplicationMigrated = useStoreSelector((state) => selectIsApplicationMigrated(state, applicationId));
  const canDeleteApplication = useStoreSelector((state) => selectCanDeleteApplication(state, applicationId));
  const canDuplicateApplication = useStoreSelector((state) => selectCanDuplicateApplication(state, applicationId));

  const { Menu, openMenu, closeMenu } = useMenu();

  const { archiveIcon, archiveTitle } = React.useMemo(() => {
    if (!application) {
      return { archiveTitle: "" };
    }
    if (application.isHidden) {
      return { archiveIcon: <img src={unarchiveIconSvg} alt={"Unarchive Icon"} />, archiveTitle: t(`Unarchive`) };
    }
    return { archiveIcon: <img src={archiveIconSvg} alt={"Archive Icon"} />, archiveTitle: t(`Archive`) };
  }, [application, t]);

  const isStandaloneApplication = jurisdictionForm?.category === JurisdictionFormCategory.Standalone;

  if (!application) {
    return null;
  }

  return (
    <div data-testid={`application-action-menu-${applicationId}`}>
      <MenuTriggerIconButton
        onClick={(event) => {
          openMenu(event);
        }}
        data-testid={"ActionsButton"}
      />
      <Menu>
        <Guard condition={!canEditApplicationForm && !isApplicationMigrated}>
          <MenuItem
            icon={"i-file-document"}
            onClick={() => {
              onClickViewSubmittedForm();
              closeMenu();
            }}
            data-testid={"action-view-form"}
          >
            {t(`View submitted form`)}
          </MenuItem>
        </Guard>
        <Guard condition={canEditApplicationForm}>
          <MenuItem
            icon={"i-format-edit"}
            onClick={() => {
              onClickEditApplicationForm();
              closeMenu();
            }}
            data-testid={"view-edit-form"}
          >
            {t(`View and Edit form`)}
          </MenuItem>
        </Guard>
        <Guard condition={!canEditApplicationForm}>
          <MenuItem
            icon={"i-file-document-view"}
            onClick={() => {
              onClickPreviewApplicationFormPdf();
              closeMenu();
            }}
            data-testid={"PopupMenuItemPreviewSubmittedFormPdf"}
            disabled={!application.applicationFormPdf}
          >
            {t(`Preview summary PDF`)}
          </MenuItem>
          <MenuItem
            icon={"i-file-download"}
            data-testid={"PopupMenuItemDownloadSubmittedFormPdf"}
            disabled={!application.applicationFormPdf}
            onClick={() => {
              onClickDownloadApplicationFormPdf();
              closeMenu();
            }}
          >
            {t(`Download summary PDF`)}
          </MenuItem>
          <MenuItemDivider />
        </Guard>

        <Guard condition={canDeleteApplication}>
          <MenuItem
            icon={"i-format-trash-can"}
            onClick={() => {
              onClickDeleteApplication();
              closeMenu();
            }}
            data-testid={"action-delete"}
          >
            {t(`Delete`)}
          </MenuItem>
        </Guard>

        <Guard condition={NOTIFICATION_SETTINGS_ENABLED}>
          <MenuItem
            icon={"i-toggle-notification"}
            disabled={!canUpdateNotificationLevel}
            data-testid={"PopupMenuItemUpdateNotificationSubscriptionLevel"}
            onClick={() => {
              onClickUpdateNotificationLevel();
              closeMenu();
            }}
          >
            {t(`Notification: {{notification_level}}`, {
              notification_level: application.notificationLevel,
            })}
          </MenuItem>
        </Guard>

        <Guard condition={isStandaloneApplication && !isApplicationMigrated && canDuplicateApplication}>
          <MenuItem
            icon={"i-file-document-multiple-02"}
            onClick={() => {
              onClickDuplicateApplication();
              closeMenu();
            }}
            data-testid={"PopupMenuItemDuplicateApplication"}
          >
            {t(`Duplicate`)}
          </MenuItem>
        </Guard>
        <Guard condition={isStandaloneApplication && !isApplicationMigrated && canDuplicateApplication}>
          <MenuItem
            icon={ODL_ICONS.FORMAT_SAVE}
            onClick={() => {
              onClickSaveAsTemplate();
              closeMenu();
            }}
            data-testid={"PopupMenuItemSaveAsTemplate"}
          >
            {t(`Save as Template`)}
          </MenuItem>
        </Guard>

        <MenuItemDivider />
        <MenuItem
          icon={archiveIcon}
          onClick={() => {
            onClickArchiveApplication();
            closeMenu();
          }}
          data-testid={"PopupMenuItemArchive"}
        >
          {archiveTitle}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ApplicationActionsMenu;
