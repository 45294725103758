import React from "react";
import { useFormikContext } from "formik";
import {
  capitalize,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import Guard from "components/Guard/Guard";
import { fetchCountryInfo, selectAllCountryEntities } from "store/domain-data/country/country";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { ICountryEntity } from "models/Country.model";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { styled } from "@mui/material/styles";
import { useToast } from "hooks/useToast";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import FlexBox from "components/FlexBox/FlexBox";
import { css } from "@emotion/react";

const nanoid = require("nanoid");

type Props = {
  label: string;
  contactNumberFieldName: string;
  countryCodeFieldName: string;
  disabled?: boolean;
  required?: boolean;
};

const FormikContactNumberInput: React.FC<Props> = ({
  label,
  contactNumberFieldName,
  countryCodeFieldName,
  disabled = false,
  required = false,
}) => {
  const dispatch = useStoreDispatch();

  const { touched, errors, handleChange, handleBlur, values, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const { toastError } = useToast();

  const { Menu, openMenu, closeMenu } = useMenu();

  const hasError = touched[contactNumberFieldName] && Boolean(errors[contactNumberFieldName]);
  const countries = useStoreSelector((state) => selectAllCountryEntities(state));

  const handleClickCountryListItem = React.useCallback(
    (country: ICountryEntity) => {
      setFieldValue(countryCodeFieldName, country.phoneCountryCode);
      setTimeout(() => {
        setFieldTouched(contactNumberFieldName);
      }, 0);
    },
    [countryCodeFieldName, setFieldValue, setFieldTouched, contactNumberFieldName]
  );

  const inputId = React.useMemo(() => {
    return contactNumberFieldName + "-" + nanoid(5);
  }, [contactNumberFieldName]);

  React.useEffect(() => {
    // Already got country list, no need to re-fetch
    if (countries.length > 0) {
      return;
    }

    dispatch(fetchCountryInfo()).catch(toastError);
  }, [dispatch, countries, toastError]);

  if (!countries) {
    return null;
  }
  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <InputLabel htmlFor={inputId} shrink={false}>
        {label}
      </InputLabel>

      <Guard
        condition={!disabled}
        fallback={
          <TextField
            id={inputId}
            name={contactNumberFieldName}
            value={values[contactNumberFieldName]}
            disabled={disabled}
            data-testid={"FormikContactNumberInput-" + contactNumberFieldName}
          />
        }
      >
        <TextField
          id={inputId}
          data-testid={"FormikContactNumberInput-" + contactNumberFieldName}
          name={contactNumberFieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError}
          value={values[contactNumberFieldName]}
          InputProps={{
            startAdornment: (
              <StyledInputAdornment position="start">
                <StyledPopupTrigger>
                  <StyledButton
                    onClick={openMenu}
                    data-testid={"UpdateContactCountryCodeButton"}
                    disabled={countries.length === 0}
                  >
                    <span>{values[countryCodeFieldName]}</span>
                    <FontIcon name={"i-navigation-caret-down"} />
                  </StyledButton>
                </StyledPopupTrigger>

                <Menu data-testid={"UpdateContactCountryCodePopup"}>
                  {countries.map((country) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          handleClickCountryListItem(country);
                          closeMenu();
                        }}
                        key={country.id}
                        data-testid={`ContactCountryCodeItem-${country.phoneCountryCode.replace("+", "")}`}
                        selected={values[countryCodeFieldName] === country.phoneCountryCode}
                      >
                        <FlexBox spacing={2}>
                          <span>{country.phoneCountryCode}</span>
                          <span>{country.name}</span>
                        </FlexBox>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </StyledInputAdornment>
            ),
          }}
        />
      </Guard>
      <Guard condition={hasError}>
        <FormHelperText error={true} data-testid={`InputError${capitalize(contactNumberFieldName)}`}>
          {errors[contactNumberFieldName]}
        </FormHelperText>
      </Guard>
    </FormControl>
  );
};

const StyledPopupTrigger = styled("div")(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("4px")}
  `
);

const StyledInputAdornment = styled(InputAdornment)(
  ({ theme }) => css`
    margin-left: 12px !important;
    margin-right: 12px !important;
  `
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    text-transform: none;
    min-width: 0;
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.objective.blue.main};
    padding: 0;
    margin: 0;
    gap: 0 !important;

    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("8px")}
  `
);

export default FormikContactNumberInput;
