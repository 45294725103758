import React from "react";
import ButtonLoadingIcon from "components/ButtonLoadingIcon/ButtonLoadingIcon";
import Button, { ButtonProps } from "@mui/material/Button";
import { useTranslation } from "react-i18next";

type Props = {
  isLoading: boolean;
  onClick: ButtonProps["onClick"];
};

const LoadMoreButton: React.FC<Props> = React.forwardRef(({ isLoading, onClick }, ref: React.Ref<any>) => {
  const { t } = useTranslation();

  return (
    <Button
      ref={ref}
      variant={"text"}
      type={"button"}
      disabled={isLoading}
      onClick={onClick}
      endIcon={isLoading ? <ButtonLoadingIcon /> : null}
    >
      {t(`Load More`)}
    </Button>
  );
});

export default LoadMoreButton;
