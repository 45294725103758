import React from "react";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  capitalize,
  TextField,
  IconButton,
} from "@mui/material";
import { useFormikContext } from "formik";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import Guard from "components/Guard/Guard";

const nanoid = require("nanoid");

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};

const FormikPasswordInput: React.FC<Props> = ({ label, fieldName, disabled = false, required = false }) => {
  const { touched, errors, handleChange, handleBlur, values } = useFormikContext<any>();

  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const inputId = fieldName + "-" + nanoid(5);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);

  const handleTogglePasswordVisibilityClick = React.useCallback(() => {
    setIsPasswordVisible((value) => !value);
  }, []);

  const handleMouseDownPassword = React.useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <InputLabel htmlFor={inputId} shrink={false}>
        {label}
      </InputLabel>

      <Guard
        condition={!disabled}
        fallback={
          <TextField id={inputId} name={fieldName} type={"password"} value={values[fieldName]} disabled={disabled} />
        }
      >
        <TextField
          id={inputId}
          name={fieldName}
          type={isPasswordVisible ? "text" : "password"}
          onChange={handleChange}
          onBlur={handleBlur}
          error={hasError}
          value={values[fieldName]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibilityClick}
                  onMouseDown={handleMouseDownPassword}
                >
                  {/* For some reason the font icons are different sizes */}
                  {isPasswordVisible ? (
                    <FontIcon name="i-toggle-view" fontSize={20} />
                  ) : (
                    <FontIcon name="i-toggle-view-off" fontSize={16} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Guard>

      {hasError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikPasswordInput;
