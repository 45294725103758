import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthenticationType } from "pages/authentication/AuthenticationPage.types";
import { Tab, Tabs } from "@mui/material";

type Props = {
  activeAuthenticationType: AuthenticationType;
};

const AuthenticationNavigationTabs: React.FC<Props> = ({ activeAuthenticationType }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleTabChange = React.useCallback(
    (evt, value: string) => {
      switch (value) {
        case AuthenticationType.Registration:
          history.replace("/registration");
          break;
        case AuthenticationType.ForgotPassword:
          history.replace("/forgot-password");
          break;
        case AuthenticationType.ResetPassword:
          history.replace("/reset-password");
          break;
        case AuthenticationType.Login:
          history.replace("/");
          break;
        default:
          history.replace("/");
          break;
      }
    },
    [history]
  );

  const tabItems = React.useMemo(() => {
    return [
      {
        disabled: false,
        label: t(`Create Account`),
        value: AuthenticationType.Registration,
      },
      {
        disabled: false,
        label: t(`Sign In`),
        value: AuthenticationType.Login,
      },
    ];
  }, [t]);

  return (
    <Tabs
      centered
      value={activeAuthenticationType}
      aria-label="Authentication Navigation Tabs"
      onChange={handleTabChange}
    >
      {tabItems.map((tabItem) => {
        return <Tab label={tabItem.label} value={tabItem.value} key={tabItem.value} />;
      })}
    </Tabs>
  );
};

export default AuthenticationNavigationTabs;
