import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { useTranslation } from "react-i18next";
import Box from "components/Box/Box";
import { styled } from "@mui/material/styles";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import ApplicationCardIcon from "components/ApplicationCard/ApplicationCardIcon";
import { useTheme } from "@mui/material";
import { ApplicationRecordType } from "models/Application.model";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import { css } from "@emotion/react";

type Props = {
  message?: string;
};

const NoApplicationCard: React.FC<Props> = ({ message }) => {
  // Common
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box position="relative">
      <StyledCardContainer data-testid="NoApplicationCard">
        <ApplicationCardIcon status={ApplicationStatus.Draft} recordType={ApplicationRecordType.Draft} />

        <FlexBox direction={"column"} flexGrow={1}>
          <FlexBox direction={"column"}>
            <Box fontSize={10} color={theme.palette.objective.dark.neutral} paddingY={1} alignSelf={"flex-start"}>
              {"MAJOR ALTERATIONS OR ADDITIONS"}
            </Box>
            <Box fontSize={16} color={theme.palette.objective.blue.main} fontWeight={600} paddingBottom={2}>
              {"ID: 4802"}
            </Box>
          </FlexBox>
          <FlexBox direction={"row"} alignItems={"flex-end"} flexGrow={1}>
            <Box fontSize={16} color={theme.palette.objective.dark.neutral} fontWeight={600}>
              {"Lot 9 - DP 151203"}
            </Box>
          </FlexBox>
        </FlexBox>

        {/* Top right part */}

        <FlexBox direction="column">
          <FlexBox
            justifyContent="flex-start"
            fontSize={12}
            lineHeight={1.8}
            fontWeight={500}
            color={theme.palette.objective.dark.neutral}
          >
            {"DRAFT"}
          </FlexBox>
          <FlexBox justifyContent="flex-end" spacing={2}>
            <FlexBox width={14} height={14} borderRadius={14} bgcolor={theme.palette.objective.light.allspice} />
            <FlexBox width={14} height={14} borderRadius={14} bgcolor={theme.palette.objective.light.allspice} />
            <FlexBox width={14} height={14} borderRadius={14} bgcolor={theme.palette.objective.light.allspice} />
            <FlexBox width={14} height={14} borderRadius={14} bgcolor={theme.palette.objective.light.allspice} />
            <FlexBox width={14} height={14} borderRadius={14} bgcolor={theme.palette.objective.light.allspice} />
          </FlexBox>
        </FlexBox>
        <FontIcon name="i-navigation-overflow-menu-horizontal" fontSize={18} padding={2} alignSelf={"center"} />
      </StyledCardContainer>

      <StyledTextOverlay>
        {/* Show the overlay message */}
        <StyledText>{message || t(`You currently have no applications`)}</StyledText>
      </StyledTextOverlay>
    </Box>
  );
};

const StyledCardContainer = styled("div")(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    filter: blur(6px);
    background: ${theme.palette.background.paper};

    &:hover {
      background: ${theme.palette.objective.blue.light};
    }

    &:hover:before {
      content: "";
      position: absolute;
      left: 6px;
      top: 12px;
      bottom: 12px;
      border-left: 4px solid ${theme.palette.primary.main};
      border-radius: 4px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 28px;
      right: 32px;
      bottom: 0;
      border-bottom: 1px solid ${theme.palette.divider};
    }

    ${theme.mixins.flexGap("16px")}
  `
);

const StyledTextOverlay = styled("div")(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    ${theme.mixins.flexGap("8px")}
  `
);

const StyledText = styled("div")(
  ({ theme }) => css`
    text-shadow: 0 0 5px rgba(0, 0, 0, 0);
    color: ${theme.palette.primary.main};
    font-size: 18px;
    font-weight: 600;
  `
);

export default NoApplicationCard;
