import React from "react";
import { BoxProps } from "@mui/material";
import Box from "components/Box/Box";
import { objectivePalette } from "styles/objectivePalette";
import { UserAvatarType } from "components/UserAvatar/UserAvatar.types";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = BoxProps & {
  initials: string;
  rectSize: number;
  fontSize: number;
  charLimit?: number;
  type?: UserAvatarType;
};

const UserAvatar: React.FC<Props> = ({
  initials,
  rectSize,
  fontSize,
  charLimit = 2,
  type = UserAvatarType.Solid,
  ...boxProps
}) => {
  const avatar = React.useMemo(() => {
    const avatarTypes = new Map<string, { border: string; bgcolor: string; color: string }>([
      [
        UserAvatarType.Solid,
        { border: "none", bgcolor: objectivePalette.blue.main, color: `${objectivePalette.light.white}` },
      ],
      [
        UserAvatarType.Outlined,
        {
          border: `2px solid ${objectivePalette.blue.main}`,
          bgcolor: "transparent",
          color: `${objectivePalette.blue.main}`,
        },
      ],
      [
        UserAvatarType.Dimmed,
        { border: "none", bgcolor: `${objectivePalette.light.allspice}`, color: `${objectivePalette.dark.night}` },
      ],
    ]);
    return avatarTypes.get(type) as { border: string; bgcolor: string; color: string };
  }, [type]);

  // TODO: Refactor - get rid of Box for detailed styling, use styled-components instead
  return (
    <StyledBox
      width={rectSize}
      height={rectSize}
      borderRadius={rectSize}
      border={avatar.border}
      bgcolor={avatar.bgcolor}
      color={avatar.color}
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontWeight={600}
      fontSize={fontSize}
      {...boxProps}
    >
      {initials?.slice(0, charLimit) || "?"}
    </StyledBox>
  );
};

export default UserAvatar;

const StyledBox = styled(Box)(
  ({ theme }) => css`
    font-weight: 600;
  `
);
