import React from "react";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { UserInfoUtil } from "models/UserInfo.model";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { logout } from "store/app-state/authentication/authentication";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Guard from "components/Guard/Guard";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import { usePostHog } from "posthog-js/react";
import { css } from "@emotion/react";

type Props = {
  compact?: boolean;
};

const UserNavItem: React.FC<Props> = ({ compact = false }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const postHog = usePostHog();

  const { Menu, openMenu, closeMenu } = useMenu();

  const userInfo = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);
  const displayName = UserInfoUtil.getDisplayName(userInfo);
  const initials = UserInfoUtil.getInitials(userInfo);

  const handleClickProfile = React.useCallback(() => {
    history.push("/user-profile");
  }, [history]);

  const handleClickLogout = React.useCallback(async () => {
    await dispatch(logout());
    postHog?.capture("user signed-out");
    postHog?.reset();
  }, [dispatch, postHog]);

  const handleKeyPress = React.useCallback<React.KeyboardEventHandler<HTMLElement>>((event) => {
    event.stopPropagation();

    if (event.key === "Enter" || event.key === " ") {
      const el = event.currentTarget;
      if (el && typeof el.click === "function") {
        el.click();
      }
      return;
    }
  }, []);

  return (
    <StyledContainer data-testid="UserNavItem">
      <StyledTriggerContainer aria-haspopup="true" onClick={openMenu} onKeyPress={handleKeyPress} tabIndex={0}>
        <UserAvatar initials={initials} rectSize={32} fontSize={12} charLimit={2} />
        <Guard condition={!compact}>
          <StyledTitle>
            {displayName}
            <StyledFontIcon name="i-navigation-chevron-down" />
          </StyledTitle>
        </Guard>
      </StyledTriggerContainer>

      <Menu data-testid="UserNavItemPopup">
        <MenuItem
          icon={"i-user-settings"}
          onClick={() => {
            closeMenu();
            handleClickProfile();
          }}
        >
          {t(`Profile`)}
        </MenuItem>
        <MenuItem
          icon={"i-operations-logout"}
          onClick={() => {
            closeMenu();
            handleClickLogout();
          }}
        >
          {t(`Logout`)}
        </MenuItem>
      </Menu>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(({ theme }) => ``);

const StyledTriggerContainer = styled("button")(
  ({ theme }) => css`
    padding: 6px 8px;
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("8px")}
    cursor: pointer;
    border: none;
    background: none;
  `
);

const StyledTitle = styled("div")(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.objective.dark.night};
    font-size: 14px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    margin-left: 4px;
  `
);

export default UserNavItem;
