import React from "react";
import SectionHeader from "components/Layout/SectionHeader";
import Guard from "components/Guard/Guard";
import NoApplicationCard from "components/ApplicationCard/NoApplicationCard";
import ApplicationCard from "components/ApplicationCard/ApplicationCard";
import Section from "components/Layout/Section";
import { useRecentApplicationsForCurrentUser } from "pages/dashboard/functions/useRecentApplicationsForCurrentUser";
import { useTranslation } from "react-i18next";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectIsLoadingThunk } from "store/app-state/loading/loading";
import { fetchPaginatedApplicationsForCurrentUser } from "store/domain-data/application/application";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import Panel from "components/odl-v2/Layout/Panel";
import { useRecentConsentsForCurrentUser } from "pages/dashboard/functions/useRecentConsentsForCurrentUser";
import isEmpty from "lodash/isEmpty";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import {
  fetchRecentApplicationsBadgeCount,
  fetchRecentConsentsBadgeCount,
  selectRecentApplicationsBadgeCount,
  selectRecentConsentsBadgeCount,
} from "store/app-state/application-badge-count/applicationBadgeCount";
import { REACT_APP_OUTSTANDING_APPS_BADGES_ENABLED } from "constants/configs";
import { css } from "@emotion/react";
import { Button, Tab, Tabs, Stack } from "@mui/material";
import TabLabel from "components/TabLabel/TabLabel";

type Props = {};

enum DashboardTabs {
  Applications = "Applications",
  Consents = "Consents",
}

const ApplicationsSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useStoreDispatch();

  const [selectedTab, setSelectedTab] = React.useState(DashboardTabs.Applications);

  const recentApplications = useRecentApplicationsForCurrentUser();
  const recentConsents = useRecentConsentsForCurrentUser();

  const applicationAlertsCount = useStoreSelector(selectRecentApplicationsBadgeCount);
  const consentAlertsCount = useStoreSelector(selectRecentConsentsBadgeCount);

  const hasApplications = !isEmpty(recentApplications);
  const hasConsents = !isEmpty(recentConsents);

  React.useEffect(() => {
    if (REACT_APP_OUTSTANDING_APPS_BADGES_ENABLED) {
      dispatch(fetchRecentApplicationsBadgeCount());
      dispatch(fetchRecentConsentsBadgeCount());
    }
  }, [dispatch]);

  const handleViewAllApplicationsClick = React.useCallback(() => {
    history.push("/applications");
  }, [history]);

  const handleViewAllConsentsClick = React.useCallback(() => {
    history.push("/consents");
  }, [history]);

  const isLoadingApplications = useStoreSelector((state) =>
    selectIsLoadingThunk(state, fetchPaginatedApplicationsForCurrentUser)
  );

  return (
    <Section data-testid={"ApplicationsSection"} header={<SectionHeader title={t(`My recent applications`)} />}>
      <Guard condition={applicationAlertsCount || consentAlertsCount}>
        <OdlAlert header={t(`Some applications are suspended`)} severity={"info"}>
          {t(
            `One or more applications or consents are suspended because of outstanding RFIs, or outstanding payments.`
          )}
        </OdlAlert>
      </Guard>

      <Tabs
        value={selectedTab}
        onChange={(event, value) => {
          setSelectedTab(value);
        }}
      >
        <Tab
          label={<TabLabel label={t(`Applications`)} adornment={applicationAlertsCount} />}
          value={DashboardTabs.Applications}
        />
        <Tab label={<TabLabel label={t(`Consents`)} adornment={consentAlertsCount} />} value={DashboardTabs.Consents} />
      </Tabs>

      <Guard condition={selectedTab === DashboardTabs.Applications}>
        <Panel thickness={"thin"} data-testid={"RecentApplicationsPanel"}>
          <Guard condition={!hasApplications && isLoadingApplications}>
            <NoApplicationCard message={t(`Loading applications...`)} />
          </Guard>

          <Guard condition={!hasApplications && !isLoadingApplications}>
            <NoApplicationCard />
          </Guard>

          <Guard condition={hasApplications}>
            <Stack>
              {recentApplications.map((application) => (
                <ApplicationCard applicationId={application.id} key={application.id} isCardClickable={false} />
              ))}
            </Stack>
          </Guard>
        </Panel>

        <StyledFooterContainer>
          <Button onClick={handleViewAllApplicationsClick}>{t(`View all`)}</Button>
        </StyledFooterContainer>
      </Guard>

      <Guard condition={selectedTab === DashboardTabs.Consents}>
        <Panel thickness={"thin"} data-testid={"RecentConsentsPanel"}>
          <Guard condition={!hasConsents && isLoadingApplications}>
            <NoApplicationCard message={t(`Loading consents...`)} />
          </Guard>

          <Guard condition={!hasConsents && !isLoadingApplications}>
            <NoApplicationCard />
          </Guard>

          <Guard condition={hasConsents}>
            <Stack>
              {recentConsents.map((consent) => (
                <ApplicationCard applicationId={consent.id} key={consent.id} isCardClickable={false} />
              ))}
            </Stack>
          </Guard>
        </Panel>

        <StyledFooterContainer>
          <Button onClick={handleViewAllConsentsClick}>{t(`View all`)}</Button>
        </StyledFooterContainer>
      </Guard>
    </Section>
  );
};

const StyledFooterContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  `
);

export default ApplicationsSection;
