import React from "react";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import Dialog from "components/odl-v2/Dialog/Dialog";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { fetchNotificationById, selectNotificationEntityById } from "store/domain-data/notification/notification";
import { useTranslation } from "react-i18next";
import { formatDateStr } from "utils/formatDateStr";
import { BNS_FETCH_NOTIFICATION_DETAILS_ENABLED, DATE_FORMATS } from "constants/configs";
import FlexBox from "components/FlexBox/FlexBox";
import NotificationInfoField from "components/NotificationInfoField/NotificationInfoField";

import { useToast } from "hooks/useToast";

import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  notificationId: string;
};

const NotificationDetailsDialog: React.FC<Props> = ({ isOpen, onClose, notificationId }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  React.useEffect(() => {
    if (!BNS_FETCH_NOTIFICATION_DETAILS_ENABLED || !isOpen) {
      return;
    }

    dispatch(fetchNotificationById(notificationId)).catch(toastError);
  }, [dispatch, notificationId, toastError, isOpen]);

  const notification = useStoreSelector((state) => selectNotificationEntityById(state, notificationId));
  const notificationRecipients = React.useMemo(() => {
    if (!notification) {
      return "";
    }

    const recipients = notification.recipients.users.map((user) => user.fullName).join(", ");
    return recipients;
  }, [notification]);

  if (!notification) {
    return null;
  }

  return (
    <Dialog open={isOpen} title={t(`Notification details`)} onClose={onClose}>
      <StyledDialogContent>
        <FlexBox flexDirection={"column"} spacing={3}>
          <NotificationInfoField label={t(`Subject`)} value={<strong>{notification.message.shortText}</strong>} />
          <NotificationInfoField
            label={t(`Sent at`)}
            value={<strong>{formatDateStr(notification.createdDate, DATE_FORMATS.FULL_DATE_WITH_TIME)}</strong>}
          />
          <NotificationInfoField label={t(`Recipients`)} value={<strong>{notificationRecipients}</strong>} />
          {notification.message.htmlText && (
            <StyledIframe sandbox="allow-top-navigation" srcDoc={notification.message.htmlText} />
          )}
          {!notification.message.htmlText && (
            <NotificationInfoField label={t(`Content`)} value={<strong>{notification.message.fullText}</strong>} />
          )}
        </FlexBox>
      </StyledDialogContent>
      <DialogActions>
        <DialogActionCancel onClick={onClose}>{t(`Cancel`)}</DialogActionCancel>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    min-width: 800px;
  `
);

const StyledIframe = styled("iframe")(
  ({ theme }) => css`
    height: 50vh;
    border: none;

    body {
      margin: 0;
      padding: 0;
    }
  `
);

export default NotificationDetailsDialog;
