import React from "react";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectBreadcrumbStepEntityById } from "store/domain-data/breadcrumb-step/breadcrumbStep";
import { useFormSchemaByName } from "components/FormIOForm/FormIOForm.functions";
import { fetchApplicationStepData } from "store/domain-data/application-step-data/applicationStepData";
import FormIOReviewStylesHandler from "pages/application-form/FormIOReviewStylesHandler";
import FormIOReviewDataHandler from "pages/application-form/FormIOReviewDataHandler";
import { styled } from "@mui/material/styles";
import FormIOForm from "components/FormIOForm/FormIOForm";
import { css } from "@emotion/react";

type Props = {
  applicationId: number;
  breadcrumbStepId: number;
};

const FormIOStepContent: React.FC<Props> = ({ applicationId, breadcrumbStepId }) => {
  const dispatch = useStoreDispatch();

  const breadcrumbStep = useStoreSelector((state) => selectBreadcrumbStepEntityById(state, breadcrumbStepId));
  const formioName = breadcrumbStep?.formioName || "";
  const formSchema = useFormSchemaByName(formioName);

  React.useEffect(() => {
    dispatch(fetchApplicationStepData({ applicationId, breadcrumbStepId }));
  }, [dispatch, applicationId, breadcrumbStepId]);

  if (!breadcrumbStep) {
    return null;
  }

  return (
    <StyledFormIOForm formSchema={formSchema} options={options} applicationId={applicationId}>
      {(form) => (
        <React.Fragment>
          <FormIOReviewStylesHandler form={form} />
          <FormIOReviewDataHandler form={form} applicationId={applicationId} breadcrumbStepId={breadcrumbStepId} />
        </React.Fragment>
      )}
    </StyledFormIOForm>
  );
};

const options = {
  readOnly: true,
  noAlerts: true,
};

const StyledFormIOForm = styled(FormIOForm)(
  ({ theme }) => css`
    display: block;

    & > * + * {
      margin-bottom: 8px;
    }

    // Optimise for printing
    label,
    span,
    td,
    div.component {
      break-inside: avoid;
    }
  `
);

export default FormIOStepContent;
