import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import { selectApplicationEntityById } from "store/domain-data/application/application";
import { selectJurisdictionFormEntityById } from "store/domain-data/jurisdiction-form/jurisdictionForm";
import { ApplicationRecordType, ApplicationUtil } from "models/Application.model";
import ApplicationInfoField from "components/ApplicationCard/ApplicationInfoField";
import { formatDateStr } from "utils/formatDateStr";
import { DATE_FORMATS } from "constants/configs";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = {
  applicationId: number;
};

const ApplicationInfoBasic: React.FC<Props> = ({ applicationId }) => {
  // Common
  const { t } = useTranslation();

  // From Redux
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));
  const jurisdictionForm = useStoreSelector((state) =>
    selectJurisdictionFormEntityById(state, application?.formId || 0)
  );

  // Derived
  const applicationDisplayId = ApplicationUtil.getDisplayId(application);

  const applicationDisplayIdLabel = React.useMemo(() => {
    if (application?.recordType === ApplicationRecordType.Consent) {
      return t(`Consent number`);
    }
    return t(`Application number`);
  }, [application?.recordType, t]);

  // Guard
  if (!application) {
    return null;
  }

  return (
    <FlexBox direction="column" spacing={3} paddingTop={1}>
      <StyledFormDisplayName>{jurisdictionForm?.displayName}</StyledFormDisplayName>
      <ApplicationInfoField label={applicationDisplayIdLabel} value={<strong>{applicationDisplayId}</strong>} />
      <ApplicationInfoField label={t(`Address`)} value={application.address.fullAddress} />

      <ApplicationInfoField
        label={t(`Last modified`)}
        value={formatDateStr(application.modifiedDate, DATE_FORMATS.MODIFIED_DATE)}
      />
    </FlexBox>
  );
};

const StyledFormDisplayName = styled("div")(
  ({ theme }) => css`
    font-size: 14px;
    color: ${theme.palette.text.secondary};
    text-transform: uppercase;
  `
);

export default ApplicationInfoBasic;
