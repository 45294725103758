import React from "react";
import FlexBox from "../FlexBox/FlexBox";
import { useStoreSelector } from "store/hooks";
import {
  selectCurrentAccessLevel,
  selectCurrentParticipant,
  selectCurrentParticipantTypes,
} from "store/app-state/participant-buffer/participantBuffer";
import { Chip, useTheme } from "@mui/material";
import { accessLevelMetaData } from "models/Participant.model";
import FontIcon from "../FontIcon/FontIcon";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type DetailsCardProps = {};

const DetailsCard: React.FC<DetailsCardProps> = () => {
  const theme = useTheme();
  const participantDetails = useStoreSelector(selectCurrentParticipant);
  const participantTypes = useStoreSelector(selectCurrentParticipantTypes);
  const currentAccessLevel = useStoreSelector(selectCurrentAccessLevel);

  const accessOption = React.useMemo(() => {
    return Object.values(accessLevelMetaData).find((accessLevel) => accessLevel.value === currentAccessLevel);
  }, [currentAccessLevel]);

  return (
    <FlexBox flexGrow={1} spacing={25}>
      <StyledLeftContainer>
        <StyledParticipantName>
          {participantDetails.firstName} {participantDetails.lastName}
        </StyledParticipantName>
        <span>{participantDetails.email}</span>
        <span>{participantDetails.phone}</span>
      </StyledLeftContainer>
      <StyledRightContainer>
        <FlexBox direction="row" spacing={2} flexWrap={"wrap"}>
          {participantTypes.map((type) => {
            return <StyledChip key={type.displayName} label={type.displayName} color={"brown"} />;
          })}
        </FlexBox>

        <FlexBox spacing={2} alignItems={"center"}>
          <FontIcon name={accessOption?.icon || ""} color={theme.palette.objective.blue.main} />
          {accessOption?.description}
        </FlexBox>
      </StyledRightContainer>
    </FlexBox>
  );
};

const StyledParticipantName = styled("div")(
  ({ theme }) =>
    css`
      font-weight: 600;
    `
);

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    padding: 12px 8px;
    font-weight: 500;
    border: 0;
  `
);

const StyledLeftContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("8px")};
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledRightContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("12px")};
  `
);

export default DetailsCard;
