import React from "react";
import ConfirmationDialog from "components/ConfirmationDialog/ConfirmationDialog";
import { Trans, useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import { styled } from "@mui/material/styles";
import { selectDocumentEntityByName } from "store/domain-data/document/document";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { css } from "@emotion/react";

type Props = {
  documentName: string;
  documentContainerId: number;
  onClose: (confirmed: boolean) => void;
};

const RemoveApplicationDocumentConfirmationDialog: React.FC<Props> = ({
  documentName,
  documentContainerId,
  onClose,
}) => {
  const { t } = useTranslation();

  const document = useStoreSelector((state) => selectDocumentEntityByName(state, documentName));

  // TODO: document container may not be found / loaded when it is not for UPLOAD_STEP
  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));

  if (!document) {
    return null;
  }

  return (
    <ConfirmationDialog
      data-testid={"ConfirmRemoveApplicationDocumentDialog"}
      title={t(`Remove document from application`)}
      isOpen={true}
      onClose={onClose}
      confirmTitle={t(`Remove`)}
      cancelTitle={t(`Cancel`)}
      content={
        <StyledDialogContent>
          <Trans
            defaults={`Remove document <strong>{{fileName}}</strong> from <strong>{{containerName}}</strong>?`}
            values={{
              containerName: documentContainer?.displayName || t(`Document Container`),
              fileName: document.fileName,
            }}
            components={{
              strong: <StyledStrong />,
            }}
            parent={StyledTransParent}
          />
          <div>{t(`This operation can't be undone.`)}</div>
        </StyledDialogContent>
      }
    />
  );
};

const StyledTransParent = styled("div")(
  ({ theme }) => css`
    display: inline-block;
  `
);

const StyledStrong = styled("span")(
  ({ theme }) => css`
    font-weight: 600;
  `
);

const StyledDialogContent = styled("div")(
  ({ theme }) => css`
    line-height: 1.5em;
    padding: 4px;
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("16px")}
  `
);

export default RemoveApplicationDocumentConfirmationDialog;
