import React from "react";
import { styled } from "@mui/material/styles";
import InfoField from "components/InfoField/InfoField";
import { useTranslation } from "react-i18next";
import { generateGoogleMapUrl } from "utils/generateGoogleMapUrl";
import { Grid } from "@mui/material";
import { useStoreSelector } from "store/hooks";
import { selectAuthorityEntityById } from "store/domain-data/authority/authority";
import { css } from "@emotion/react";

type Props = {
  authorityId: number;
};

const AuthorityInfoCard: React.FC<Props> = ({ authorityId }) => {
  const { t } = useTranslation();

  const authority = useStoreSelector((state) => selectAuthorityEntityById(state, authorityId));

  if (!authority) {
    return null;
  }

  const googleMapAddressUrl = generateGoogleMapUrl(authority.address);

  return (
    <StyledContainer data-testid={"AuthorityInfoCard"}>
      <StyledTitle>{authority.displayName}</StyledTitle>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <InfoField label={t(`Address`)} value={authority.address} href={googleMapAddressUrl} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoField label={t(`Email`)} value={authority.email} href={`mailto:${authority.email}`} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoField label={t(`Phone`)} value={authority.phone} href={`tel:${authority.phone}`} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InfoField label={t(`Website`)} value={authority.website} href={authority.website} />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    max-width: 800px;
    ${theme.mixins.flexGap("16px")}
  `
);

const StyledTitle = styled("h3")(
  ({ theme }) => css`
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.objective.dark.night};
  `
);

export default AuthorityInfoCard;
