import React from "react";
import { ReactComponent as SystemGroupAccessIcon } from "assets/images/system-group-access.svg";
import { ActionItemVariant, IActionItem } from "components/ActionSection/ActionItem";
import { Typography, Button } from "@mui/material";
import FlexBox from "components/FlexBox/FlexBox";
import ApplicationAccessRequestApplicantDialog from "components/ApplicationAccessRequestApplicantDialog/ApplicationAccessRequestApplicantDialog";
import Spacer from "components/Spacer/Spacer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled, { css } from "styled-components/macro";
import { useStoreSelector } from "store/hooks";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { UserInfoUtil } from "models/UserInfo.model";

export const useApplicationIntentActionItems = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  // Check if user profile is completed
  const userInfo = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);
  const isAccessRequestEnabled = React.useMemo(() => {
    if (!userInfo) {
      return false;
    }

    return UserInfoUtil.isProfileComplete(userInfo);
  }, [userInfo]);

  const applicationAccessRequestApplicantDialog = React.useMemo(() => {
    if (!isDialogOpen) {
      return null;
    }

    return <ApplicationAccessRequestApplicantDialog onClose={() => setIsDialogOpen(false)} />;
  }, [isDialogOpen, setIsDialogOpen]);

  const openAccessRequestApplicantDialog = React.useCallback(() => {
    setIsDialogOpen(true);
  }, [setIsDialogOpen]);

  // Incomplete user profile tooltip message
  const RequestAccessTooltip = React.useMemo(
    () => (
      <FlexBox width={256} display={"flex"} alignItems={"flex-end"} flexDirection={"column"}>
        <StyledDescription variant="body1">To request access, you must complete your user profile</StyledDescription>
        <Spacer y={6} />
        <Button
          variant="contained"
          onClick={() => {
            history.push("/user-profile");
          }}
        >
          Go to Profile
        </Button>
      </FlexBox>
    ),
    [history]
  );

  const actionItems: IActionItem[] = React.useMemo(() => {
    return [
      {
        icon: "i-navigation-add",
        title: t(`Start a new application`),
        variant: ActionItemVariant.Button,
        onClick: () => {
          history.push("/new-application");
        },
      },
      {
        icon: <SystemGroupAccessIcon />,
        title: t(`Request access to a project`),
        variant: ActionItemVariant.Button,
        ...(isAccessRequestEnabled ? {} : { disabled: true, tooltip: RequestAccessTooltip }),
        onClick: () => {
          openAccessRequestApplicantDialog();
        },
      },
    ];
  }, [t, openAccessRequestApplicantDialog, history, isAccessRequestEnabled, RequestAccessTooltip]);

  return { applicationAccessRequestApplicantDialog, openAccessRequestApplicantDialog, actionItems };
};

const StyledDescription = styled(Typography)(
  ({ theme }) => css`
    text-align: left;
    line-height: 1.5;
  `
);
