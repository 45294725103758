import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { css } from "@emotion/react";

type Props = {
  onClick: () => any;
};

const RemoveIconButton = ({ onClick }: Props) => {
  return (
    <StyledDeleteButton data-testid="RemoveIconButton" onClick={onClick}>
      <StyledRemoveIcon name={ODL_ICONS.NAVIGATION_CLOSE} />
    </StyledDeleteButton>
  );
};

const StyledDeleteButton = styled(IconButton)(
  ({ theme }) => css`
    width: 44px;
    height: 44px;
  `
);

const StyledRemoveIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 20px;
    color: ${theme.palette.objective.dark.night};
  `
);

export default RemoveIconButton;
