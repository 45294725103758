import React from "react";
import ImagePreviewCard from "components/Dropzone/ImagePreviewCard";
import FileUploadDropzone from "components/Dropzone/FileUploadDropzone";
import { scaleImageToFit } from "components/Dropzone/functions/scaleImageToFit";
import { FileRejection } from "react-dropzone";
import { styled } from "@mui/material/styles";
import { useHandleFileRejections } from "components/DocumentContainer/functions/useHandleFileRejections";
import { IEncodedImageFile } from "models/EncodedImageFile.model";
import FlexBox from "components/FlexBox/FlexBox";
import { useTranslation } from "react-i18next";
import { prettyBytes } from "utils/prettyBytes";
import Guard from "components/Guard/Guard";
import { css } from "@emotion/react";

type Props = {
  maxSize: number; // number in bytes
  onImageChange: (image: IEncodedImageFile | null) => void;
  initialImage?: IEncodedImageFile | null;
  scaleImageMaxWidth?: number; // number in px
  scaleImageMaxHeight?: number; // number in px
  hideDefaultHelpInfo?: boolean;
  disabled?: boolean;
};

const ImageUploadDropzone: React.FC<Props> = ({
  initialImage,
  onImageChange,
  maxSize,
  scaleImageMaxWidth = 250,
  scaleImageMaxHeight = 150,
  hideDefaultHelpInfo = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const [image, setImage] = React.useState(initialImage);

  // Reject on drop files
  const handleFileRejections = useHandleFileRejections({
    acceptFileTypeDescription: "image",
    maxSizeDescription: prettyBytes(maxSize),
  });

  const handleDropFiles = React.useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      handleFileRejections(fileRejections);

      // No accepted file, no op
      if (acceptedFiles.length === 0) {
        return;
      }

      // Read the content of the first file into memory
      const file = acceptedFiles[0];

      // Scale image

      const image = await scaleImageToFit(file, { maxWidth: scaleImageMaxWidth, maxHeight: scaleImageMaxHeight });
      setImage(image);
      onImageChange(image);
    },
    [handleFileRejections, onImageChange, scaleImageMaxWidth, scaleImageMaxHeight]
  );

  const handleRemoveImage = React.useCallback(() => {
    setImage(null);
    onImageChange(null);
  }, [onImageChange]);

  // Update image when initialImage changed
  React.useEffect(() => {
    setImage(initialImage);
  }, [initialImage]);

  if (image) {
    return (
      <StyledImagePreviewContainer data-testid={"ImageUploadDropzone"}>
        <ImagePreviewCard onClickRemove={handleRemoveImage} image={image} readonly={disabled} />
      </StyledImagePreviewContainer>
    );
  }

  return (
    <FlexBox direction={"column"} spacing={2} data-testid={"ImageUploadDropzone"}>
      <FileUploadDropzone
        accept={"image/*"}
        maxSize={maxSize}
        multiple={false}
        onDrop={handleDropFiles}
        disabled={disabled}
      />

      {/* Render default help info */}
      <Guard condition={!hideDefaultHelpInfo}>
        <StyledHelpText>
          {t(`Only JPG, JPEG, PNG or GIF files are supported.`)}{" "}
          {t(`Maximum file size is {{maxSize}}.`, { maxSize: prettyBytes(maxSize) })}
        </StyledHelpText>
        <StyledHelpText>
          {t(`Your image will be resized to fit into a {{width}} x {{height}} px container.`, {
            width: scaleImageMaxWidth,
            height: scaleImageMaxHeight,
          })}
        </StyledHelpText>
      </Guard>
    </FlexBox>
  );
};

const StyledImagePreviewContainer = styled("div")(
  () => `
    width: 250px;
  `
);

const StyledHelpText = styled("div")(
  ({ theme }) => css`
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

export default ImageUploadDropzone;
