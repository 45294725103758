import React from "react";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import { useStoreSelector } from "store/hooks";
import { selectAnnouncementEntityById } from "store/domain-data/announcement/announcement";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import dayjs from "dayjs";
import { css } from "@emotion/react";

type Props = {
  id: string;
};

const STORAGE_KEY_PREFIX_ALERT_MUTED = "build.announcement.alert";
const STORAGE_KEY_SUFFIX_ALERT_MUTED = "isMuted";

const generateStorageKey = (id: string) => {
  return [STORAGE_KEY_PREFIX_ALERT_MUTED, id, STORAGE_KEY_SUFFIX_ALERT_MUTED].join(".");
};

const AnnouncementAlert: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();
  const announcementAlert = useStoreSelector((state) => selectAnnouncementEntityById(state, id));
  const [isAlertMuted, setIsAlertMuted] = React.useState(false);

  const handleClickMute = React.useCallback(() => {
    if (!announcementAlert) {
      return;
    }
    const storageKey = generateStorageKey(announcementAlert.id);
    localStorage.setItem(storageKey, "true");
    setIsAlertMuted(true);
  }, [announcementAlert]);

  const isAnnouncementAlertMuted = React.useMemo(() => {
    if (!announcementAlert) {
      return false;
    }
    const storageKey = generateStorageKey(announcementAlert.id);
    if (localStorage.getItem(storageKey) === "true") {
      setIsAlertMuted(true);
      return true;
    } else {
      return false;
    }
  }, [announcementAlert]);

  const isAnnouncementAlertVisible = React.useMemo(() => {
    if (!announcementAlert) {
      return false;
    }

    const startDateTime = dayjs(announcementAlert.startTime);
    const endDateTime = dayjs(announcementAlert.endTime);

    if (dayjs().isAfter(startDateTime) && dayjs().isBefore(endDateTime) && !isAlertMuted) {
      return true;
    }
    return false;
  }, [announcementAlert, isAlertMuted]);

  if (!announcementAlert || isAnnouncementAlertMuted || !isAnnouncementAlertVisible) {
    return null;
  }

  return (
    <OdlAlert
      header={announcementAlert.header}
      severity={announcementAlert.severity}
      action={
        !announcementAlert.persistent && (
          <StyledIconButton onClick={handleClickMute} aria-label={t(`Close`)}>
            <StyledCloseIcon name={"i-navigation-close"} />
          </StyledIconButton>
        )
      }
    >
      {announcementAlert.subTitle && <StyledSubtitle>{announcementAlert.subTitle}</StyledSubtitle>}
      {announcementAlert.description}
    </OdlAlert>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
    cursor: pointer;
  `
);

const StyledCloseIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledSubtitle = styled("div")(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: bold;
    color: ${theme.palette.objective.dark.night};
    margin-bottom: 4px;
  `
);

export default AnnouncementAlert;
