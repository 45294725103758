import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "react-i18next";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import startCase from "lodash/startCase";
import { css } from "@emotion/react";
import { Link } from "@mui/material";

type Props = {
  onClickCancel: () => void;
  onClickKeep: () => void;
  expiredFileNames: string[];
  fileExpiryInMonths: number;
  documentContainerName: string;
};

const ApplicationDocumentExpiredDialog: React.FC<Props> = ({
  onClickCancel,
  onClickKeep,
  expiredFileNames,
  documentContainerName,
  fileExpiryInMonths,
}) => {
  const { t } = useTranslation();
  const fileCount = expiredFileNames.length;
  const fileLabel = t(`{{count}} files`, { count: fileCount });
  const thisTheseLabel = t(`{{count}} these`, { count: fileCount });
  const isAreLabel = t(`{{count}} are`, { count: fileCount });
  const itThemLabel = t(`{{count}} them`, { count: fileCount });
  const newerFileLabel = t(`{{count}} newer files`, { count: fileCount });

  const title = t(`{{fileLabel}} more than {{fileExpiryInMonths}} months old`, {
    fileLabel: startCase(fileLabel),
    fileExpiryInMonths,
  });

  const subTitle = t(
    `{{documentContainerName}} files <strong>must</strong> be less than {{fileExpiryInMonths}} months old or your BCA will issue an RFI for newer files. RFIs slow down the acceptance of your application.`,
    {
      documentContainerName,
      fileExpiryInMonths,
    }
  );

  const desc1 = t("You can request a new copy of your record of title from ");
  const desc2 = t("Toitū Te Whenua Land Information NZ.");

  const fileTitle = t(
    `{{thisTheseLabel}} {{fileLabel}} {{isAreLabel}} too old. Please remove {{itThemLabel}} and replace with {{newerFileLabel}}:`,
    {
      thisTheseLabel: startCase(thisTheseLabel),
      fileLabel,
      isAreLabel,
      fileExpiryInMonths,
      itThemLabel,
      newerFileLabel,
    }
  );

  const question = t(`Do you want to keep {{thisTheseLabel}} {{fileLabel}}? `, {
    fileLabel,
    thisTheseLabel,
  });

  return (
    <Dialog
      open={true}
      data-testid={"ApplicationDocumentExpiredDialog"}
      title={title}
      onClose={onClickCancel}
      maxWidth={false}
      scroll="body"
    >
      <DialogContent>
        <StyledDialogContent>
          <FlexBox direction={"column"}>
            <StyledParagraph>
              <Trans
                i18nKey={subTitle}
                defaults={subTitle}
                components={{
                  strong: <strong />,
                }}
                tOptions={{
                  documentContainerName,
                  fileExpiryInMonths,
                }}
              />
            </StyledParagraph>
            <br />
            <StyledParagraph>{fileTitle}</StyledParagraph>
            <br />
            {expiredFileNames.map((fileName) => (
              <div key={fileName}>
                <strong>{fileName}</strong>
              </div>
            ))}
            <br />
            <StyledParagraph>
              {desc1}
              <Link target="_blank" href={"https://www.linz.govt.nz/products-services/land-records/land-record-search"}>
                {desc2}
              </Link>
            </StyledParagraph>
            <br />
            <StyledParagraph>{question}</StyledParagraph>
          </FlexBox>
        </StyledDialogContent>
      </DialogContent>

      <DialogActions>
        <DialogActionCancel data-testid="KeepButton" onClick={onClickKeep}>
          {t(`Keep`)}
        </DialogActionCancel>
        <DialogActionPrimary data-testid="CancelButton" onClick={onClickCancel}>
          {t(`Remove`)}
        </DialogActionPrimary>
      </DialogActions>
    </Dialog>
  );
};

const StyledParagraph = styled("div")(
  ({ theme }) => css`
    line-height: 1.5;
  `
);

const StyledDialogContent = styled("div")(
  ({ theme }) => css`
    line-height: 1.2em;
    padding: 4px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    ${theme.mixins.flexGap("8px")}
  `
);

export default ApplicationDocumentExpiredDialog;
