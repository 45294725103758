import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@mui/material";
import { useFormikContext } from "formik";
import trim from "lodash/trim";

const nanoid = require("nanoid");

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  items: { value: string | number; displayName: string }[];
};

const FormikSelect: React.FC<Props> = ({ label, fieldName, disabled = false, required = false, onBlur, items }) => {
  const { touched, errors, handleChange, handleBlur: formikHandleBlur, setFieldValue, values } = useFormikContext<
    any
  >();

  const inputId = React.useMemo(() => fieldName + "-" + nanoid(5), [fieldName]);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);
  const handleBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement>) => {
      // Trim text input
      setFieldValue(fieldName, trim(evt.target.value));

      // Custom blur handler
      if (onBlur) {
        onBlur(evt);
      }

      // General blur handler
      formikHandleBlur(evt);
    },
    [formikHandleBlur, onBlur, setFieldValue, fieldName]
  );

  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <InputLabel htmlFor={inputId} shrink={false}>
        {label}
      </InputLabel>

      <Select
        id={inputId}
        name={fieldName}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[fieldName]}
        error={hasError}
        disabled={disabled}
      >
        {items.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>

      {hasError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelect;
