import React from "react";
import { useParticipants } from "hooks/useParticipants";
import { fetchParticipantTypes } from "store/domain-data/participant-type/participantType";
import { useStoreDispatch } from "store/hooks";
import ParticipantCard from "components/ParticipantCard/ParticipantCard";
import { Stack } from "@mui/material";

type Props = {
  applicationId: number;
};

const ParticipantStepContent: React.FC<Props> = ({ applicationId }) => {
  const dispatch = useStoreDispatch();
  const { participants } = useParticipants(applicationId);
  const [isParticipantTypesReady, setParticipantTypesReady] = React.useState<boolean>(false);

  // Lifecycle
  React.useEffect(() => {
    const initData = async () => {
      await dispatch(fetchParticipantTypes(applicationId));
      setParticipantTypesReady(true);
    };
    initData();
  }, [applicationId, dispatch, isParticipantTypesReady]);

  if (!isParticipantTypesReady) {
    return null;
  }

  return (
    <Stack>
      {participants.map((participant) => (
        <ParticipantCard key={participant.id} participantId={participant.id} readOnly={true} />
      ))}
    </Stack>
  );
};

export default ParticipantStepContent;
