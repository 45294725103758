import React from "react";
import { IQualificationEntity, QualificationType } from "models/Qualification.model";
import FlexBox from "../FlexBox/FlexBox";
import { IconButton, useTheme } from "@mui/material";
import Box from "../Box/Box";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { QualificationViewContext } from "components/ParticipantEditDialog/contexts/QualificationViewContext";
import { styled } from "@mui/material/styles";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import { useTranslation } from "react-i18next";
import Guard from "components/Guard/Guard";
import { css } from "@emotion/react";

type QualificationCardProps = {
  qualification: IQualificationEntity;
  index: number;
};

const QualificationCard: React.FC<QualificationCardProps> = ({ qualification, index }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { removeQualification } = React.useContext(QualificationViewContext);

  const handleDeleteClick = React.useCallback(() => {
    removeQualification(index);
  }, [removeQualification, index]);

  const mappedQualificationName = React.useMemo(() => {
    if (qualification.otherQualificationType) {
      return qualification.otherQualificationType;
    }
    switch (qualification.qualificationName) {
      case "LBP":
        return QualificationType.LicensedBuildingPractitioner;
      default:
        return qualification.qualificationName;
    }
  }, [qualification.otherQualificationType, qualification.qualificationName]);

  return (
    <StyledContainer data-testid="QualificationCard">
      <StyledFlexBox>
        <Box fontWeight={600}>{mappedQualificationName}</Box>
        <Box>ID: {qualification.qualificationNumber}</Box>
        <Guard condition={qualification.licensingClasses.length > 0}>
          <FlexBox>Classes: {qualification.licensingClasses.join(", ")}</FlexBox>
          {qualification.warnings.map((warning) => (
            <OdlAlert severity={"info"} key={warning.label} data-testid="InactiveLicenseWarning">
              {t(warning.warning)}
            </OdlAlert>
          ))}
        </Guard>
      </StyledFlexBox>
      <StyledIconButton onClick={handleDeleteClick} data-testid={"DeleteQualificationButton"}>
        <FontIcon name={"i-navigation-close"} color={theme.palette.error.main} />
      </StyledIconButton>
    </StyledContainer>
  );
};

const StyledContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
  `
);

const StyledFlexBox = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    flex-grow: 1;
    flex-wrap: wrap;
    background: ${theme.palette.grey[300]};
    border-bottom: 1px solid ${theme.palette.grey[600]};
    color: ${theme.palette.text.primary};
    ${theme.mixins.flexGap("8px")}
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: 0;
    height: auto;
    width: 68px;
    &:hover {
      background-color: ${theme.palette.action.hover};
    }
  `
);

export default QualificationCard;
