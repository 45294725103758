import React from "react";
import { getFileNameComponents } from "utils/getFileNameComponents";
import { styled, Theme, useTheme } from "@mui/material/styles";
import prettyBytes from "pretty-bytes";
import { useTranslation } from "react-i18next";
import { DocumentStatus } from "models/DocumentStatus.model";
import { DocumentErrorCodeUtil } from "models/DocumentErrorCode.model";
import Guard from "components/Guard/Guard";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { formatDateStr } from "utils/formatDateStr";
import DocumentIcon from "components/DocumentIcon/DocumentIcon";
import { DocumentErrorCode } from "models/DocumentErrorCode.model";
import FlexBox from "components/FlexBox/FlexBox";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import { FILE_TYPES } from "constants/configs";
import { css } from "@emotion/react";
import { useStoreSelector } from "store/hooks";
import { selectApplicationDocumentUploadQueuedItems } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { DocumentUtil } from "models/Document.model";
import { OdlThemeType } from "@odl/core";
import { Card, CardHeader, Typography } from "@mui/material";
import { omit } from "lodash";

const SUPPORTED_FILE_EXTS = [FILE_TYPES.PDF];

type Props = {
  name: string;
  fileName: string;
  fileType?: string;
  fileSize: number;
  fileModifiedDate?: string;
  uploadStatus: DocumentStatus;
  errorCode?: DocumentErrorCode | null;
  isDraggable?: boolean;
  onClickCard?: () => void;
  isSelected?: boolean;
  linkMessage?: string;
  actionButton?: React.ReactNode;
  raised?: boolean;
};

const DocumentCard: React.FC<Props> = ({
  name,
  fileName,
  fileSize,
  fileModifiedDate = "",
  isDraggable = false,
  isSelected = false,
  onClickCard,
  errorCode,
  linkMessage = "",
  actionButton,
  raised,
  uploadStatus: docUploadStatus,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let { fileNameWithoutExt, fileExt } = React.useMemo(() => getFileNameComponents(fileName), [fileName]);
  const queuedItems = useStoreSelector(selectApplicationDocumentUploadQueuedItems);

  if (!SUPPORTED_FILE_EXTS.includes(fileExt.toLowerCase())) {
    fileNameWithoutExt = fileNameWithoutExt.concat(fileExt);
    fileExt = "";
  }

  const uploadStatus = React.useMemo(() => {
    if (DocumentUtil.isPendingNotInQueue({ name, uploadStatus: docUploadStatus }, queuedItems)) {
      return DocumentStatus.Error;
    }

    return docUploadStatus;
  }, [queuedItems, docUploadStatus, name]);

  const errorMessage = React.useMemo(() => {
    return t(DocumentErrorCodeUtil.getErrorMessage(errorCode));
  }, [t, errorCode]);

  const DocumentCardTitle = (
    <FlexBox flexDirection="row" spacing={3} alignItems="center">
      <Typography variant="h4">{fileNameWithoutExt}</Typography>
      <Guard condition={!!fileExt}>
        <Typography data-testid={`DocumentCardFileExt-${fileName}`} variant="body2" color={theme.palette.text.tertiary}>
          {fileExt}
        </Typography>
      </Guard>
      <Guard condition={fileSize > 0}>
        <Typography variant="body2" data-testid={`DocumentCardFileSize-${fileName}`}>
          {prettyBytes(fileSize || 0)}
        </Typography>
      </Guard>
    </FlexBox>
  );

  const DocumentCardSubheader = (
    <StyledUploadInfo $isError={uploadStatus === DocumentStatus.Error}>
      <Guard condition={uploadStatus === DocumentStatus.Error}>{errorMessage}</Guard>
      <Guard condition={uploadStatus === DocumentStatus.Pending}>{t(`Queued`)}</Guard>
      <Guard condition={uploadStatus === DocumentStatus.Uploading}>{t(`Uploading...`)}</Guard>
      <Guard condition={uploadStatus === DocumentStatus.Uploaded}>{t(`Scanning for viruses...`)}</Guard>
      <Guard condition={uploadStatus === DocumentStatus.Completed && fileModifiedDate}>
        <Guard condition={linkMessage}>
          <HoverTooltip title={linkMessage}>
            <FontIcon name={"i-format-link"} />
          </HoverTooltip>
        </Guard>
        {formatDateStr(fileModifiedDate, "dd MMM yyyy")}
      </Guard>
    </StyledUploadInfo>
  );

  return (
    <StyledCard
      data-testid={`DocumentCard-${fileName}`}
      data-uploadstatus={uploadStatus}
      onClick={onClickCard}
      selected={isSelected}
      $isDraggable={isDraggable}
      $raised={raised}
      {...omit(otherProps, ["fileType"])}
    >
      <CardHeader
        title={DocumentCardTitle}
        subheader={DocumentCardSubheader}
        avatar={
          <FlexBox alignItems="center" spacing={1}>
            <Guard condition={isDraggable}>
              <StyledDragHandleIcon name="i-navigation-draggable" />
            </Guard>
            <DocumentIcon size={44} status={uploadStatus} />
          </FlexBox>
        }
        action={actionButton}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => !["$isDraggable", "selected", "raised"].includes(prop.toString()),
})<{
  $isDraggable?: boolean;
  $raised?: boolean;
  selected: boolean;
}>(
  ({
    theme,
    selected,
    $isDraggable,
    $raised,
  }: {
    theme: Theme & OdlThemeType;
    $isDraggable?: boolean;
    $raised?: boolean;
    selected: boolean;
  }) => css`
    position: relative;

    // Different background color for document card in dropzone
    ${($isDraggable || $raised) &&
    css`
      background: ${theme.palette.background.papershade};
      box-shadow: none;
    `}

    margin-bottom: 2px;
    border-radius: 0;

    &:hover {
      background: ${theme.palette.background.papershade};
    }

    &:active {
      background: ${theme.palette.background.info};

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        border-left: 4px solid ${theme.palette.primary.main};
      }
    }

    ${selected &&
    css`
      background: ${theme.palette.background.info};

      &:hover {
        background: ${theme.palette.secondary.light};
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        border-left: 4px solid ${theme.palette.primary.main};
      }
    `}
  `
);

const StyledDragHandleIcon = styled(FontIcon)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin: 6px;
    color: ${theme.palette.text.primary};
  `
);

const StyledUploadInfo = styled("div")<{ $isError: boolean }>(
  ({ theme, $isError }) => css`
    display: flex;
    align-items: center;
    color: ${$isError ? theme.palette.error.main : theme.palette.text.tertiary};
    ${theme.mixins.flexGap("4px")}
  `
);

export default DocumentCard;
