import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

const DialogActionGroup = styled("div")(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.mixins.flexGap("16px")}
  `
);

export default DialogActionGroup;
