import { createAction, createAsyncThunk, createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { IRFIPointEntity, RFIDerivedStatus, RFIPointType, RFIPointUtil } from "models/RFIPoint.model";
import { serviceContainer } from "services/serviceContainer";
import { RootState } from "store/types";
import { batch } from "react-redux";
import { upsertDocuments } from "store/domain-data/document/document";
import { createDeepEqualSelector } from "store/utils";
import uniq from "lodash/uniq";
import { IRFIPointService } from "services/rfi-point/RFIPointService.types";
import groupBy from "lodash/groupBy";
import { fetchApplicationById } from "store/domain-data/application/application";
import { isValid } from "date-fns";
import { orderBy, uniqBy } from "lodash";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

// Entity Adapter

const rfiPointAdapter = createEntityAdapter<IRFIPointEntity>({
  selectId: (entity) => entity.id,
  sortComparer: (a, b) => {
    if (a.applicationId !== b.applicationId) {
      return a.applicationId - b.applicationId;
    }

    if (a.rfiPointType !== b.rfiPointType) {
      return a.rfiPointType.localeCompare(b.rfiPointType);
    }

    if (a.rfiLocation !== b.rfiLocation) {
      return a.rfiLocation.localeCompare(b.rfiLocation);
    }

    if (a.rfiRound !== b.rfiRound) {
      return a.rfiRound - b.rfiRound;
    }

    return a.displayOrderInRound - b.displayOrderInRound;
  },
});

// Actions

export const loadRFIPoint = createAction<IRFIPointEntity>("domainData/rfiPoint/loadRFIPoint");
export const loadRFIPoints = createAction<IRFIPointEntity[]>("domainData/rfiPoint/loadRFIPoints");

// Thunks

export const fetchRFIPointsByApplicationId = createAsyncThunk(
  "domainData/rfiPoint/fetchRFIPointsByApplicationId",
  async (applicationId: number, thunkAPI) => {
    const rfiPoints = await serviceContainer.cradle.rfiPointService.fetchRFIPointsByApplicationId(applicationId);
    thunkAPI.dispatch(loadRFIPoints(rfiPoints));
    return rfiPoints;
  }
);

export const fetchRFIPoint = createAsyncThunk(
  "domainData/rfiPoint/fetchRFIPoint",
  async (args: { applicationId: number; rfiPointId: number }, thunkAPI) => {
    const {
      rfiPoint,
      documents,
      applicationDocumentRelations,
    } = await serviceContainer.cradle.rfiPointService.fetchRFIPoint(args);

    batch(() => {
      thunkAPI.dispatch(loadRFIPoint(rfiPoint));

      if (documents && applicationDocumentRelations) {
        thunkAPI.dispatch(upsertDocuments(documents));
      }
    });
  }
);

export const saveRFIPointResponse = createAsyncThunk(
  "domainData/rfiPoint/saveRFIPointResponse",
  async (
    args: Parameters<IRFIPointService["saveRFIPointResponse"]>[0] & { ignoreUpsertDocuments?: boolean },
    thunkAPI
  ) => {
    const {
      rfiPoint,
      documents,
      applicationDocumentRelations,
    } = await serviceContainer.cradle.rfiPointService.saveRFIPointResponse(args);

    batch(() => {
      thunkAPI.dispatch(loadRFIPoint(rfiPoint));

      // Prevent concurrent upsert documents when polling at the same time
      if (documents && applicationDocumentRelations && !args.ignoreUpsertDocuments) {
        thunkAPI.dispatch(upsertDocuments(documents));
      }
    });
  }
);

export const submitRFIRound = createAsyncThunk(
  "domainData/rfiPoint/submitRFIRound",
  async (
    args: { applicationId: number; rfiLocation: string; rfiRound: string; rfiPointType: RFIPointType },
    thunkAPI
  ) => {
    // Submit rfi round

    await serviceContainer.cradle.rfiPointService.submitRFIRound({
      applicationId: args.applicationId,
      rfiLocation: args.rfiLocation,
      rfiRound: args.rfiRound,
      rfiPointType: args.rfiPointType,
    });

    // Re-fetch RFI points as they will be updated by submitting the round
    await thunkAPI.dispatch(fetchRFIPointsByApplicationId(args.applicationId));

    // Re-fetch application as it will be updated by submitting the round
    await thunkAPI.dispatch(fetchApplicationById(args.applicationId));
  }
);

export const submitRFIRoundV3 = createAsyncThunk(
  "domainData/rfiPoint/submitRFIRoundV3",
  async (args: { applicationId: number; rfiTeam: string; rfiRound: string; rfiPointType: RFIPointType }, thunkAPI) => {
    // Submit rfi round

    await serviceContainer.cradle.rfiPointService.submitRFIRoundV3({
      applicationId: args.applicationId,
      rfiTeam: args.rfiTeam,
      rfiRound: args.rfiRound,
      rfiPointType: args.rfiPointType,
    });

    // Re-fetch RFI points as they will be updated by submitting the round
    await thunkAPI.dispatch(fetchRFIPointsByApplicationId(args.applicationId));

    // Re-fetch application as it will be updated by submitting the round
    await thunkAPI.dispatch(fetchApplicationById(args.applicationId));
  }
);
// Reducer

export const defaultRFIState = rfiPointAdapter.getInitialState();

export const rfiPointReducer = createReducer<EntityState<IRFIPointEntity>>(defaultRFIState, (builder) =>
  builder
    // @prettier-ignore
    .addCase(loadRFIPoints, rfiPointAdapter.upsertMany)
    .addCase(loadRFIPoint, rfiPointAdapter.upsertOne)
);

// Selectors

export const {
  selectById: selectRFIPointEntityById,
  selectIds: selectRFIPointsEntityIds,
  selectEntities: selectRFIPointEntities,
  selectAll: selectAllRFIPointEntities,
} = rfiPointAdapter.getSelectors((state: RootState) => state.domainData.rfiPoint);

export const selectRFIPointsByApplicationId = createDeepEqualSelector(
  [selectAllRFIPointEntities, (state: RootState, applicationId: number) => applicationId],
  (entities, applicationId) => {
    return entities.filter((entity) => entity.applicationId === applicationId);
  }
);
export const selectRFILocationsForApplicationByType = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
  ],
  (entities, rfiPointType) => {
    const locations = entities
      .filter((entity) => entity.rfiPointType === rfiPointType)
      .map((entity) => entity.rfiLocation);
    return uniq(locations);
  }
);

export const selectRFITeamsForApplicationByType = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
  ],
  (entities, rfiPointType) => {
    const teams = entities.filter((entity) => entity.rfiPointType === rfiPointType).map((entity) => entity.rfiTeam);
    return uniq(teams);
  }
);

export const selectRFITypeHasLegacyRFIPoint = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
  ],
  (entities, rfiPointType) => {
    const selectedRFITypeHasLegacyRFIPoint = entities.some(
      (rfiPoint) => rfiPoint.rfiPointType === rfiPointType && isEmpty(rfiPoint.rfiTeam)
    );
    return selectedRFITypeHasLegacyRFIPoint;
  }
);

export const selectInitialRFIPointType = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { selectedRFIPointType: RFIPointType | null }) => args.selectedRFIPointType,
  ],
  (entities, rfiPointType) => {
    if (rfiPointType) {
      return rfiPointType;
    }

    const filteredRFIsWithValidDates = entities.filter(
      (rfi) => rfi.councilCreatedDate && isValid(new Date(rfi.councilCreatedDate))
    );

    if (isEmpty(filteredRFIsWithValidDates)) {
      return RFIPointType.Vetting;
    }

    const sortedRFIPoints = orderBy(
      filteredRFIsWithValidDates,
      (rfi) => new Date(rfi.councilCreatedDate).getTime(),
      "desc"
    );
    const firstRFIPoint = sortedRFIPoints[0];
    return firstRFIPoint.rfiPointType;
  }
);

// TODO: Need to check how to handle the case when rfiRound is 0 (caused by raw value without number in it)
export const selectRFIRoundsForApplicationByTypeAndLocation = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiLocation: string }) => args.rfiLocation,
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
  ],
  (entities, rfiLocation, rfiPointType) => {
    const rounds = entities
      .filter((entity) => entity.rfiLocation === rfiLocation && entity.rfiPointType === rfiPointType)
      .map((entity) => entity.rfiRound);

    return uniq(rounds);
  }
);

export const selectRFIRoundsForApplicationByTypeAndTeam = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiTeam: string }) => args.rfiTeam,
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
  ],
  (entities, rfiTeam, rfiPointType) => {
    const rounds = entities
      .filter((entity) => entity.rfiTeam === rfiTeam && entity.rfiPointType === rfiPointType)
      .map((entity) => entity.rfiRound);

    return sortBy(uniq(rounds));
  }
);

export const selectRFITeamsForApplication = createDeepEqualSelector(
  [(state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId)],
  (entities) => {
    const rfiPointsWithTeam = entities.filter((item) => !isEmpty(item.rfiTeam));
    const rfiTeams = uniqBy(rfiPointsWithTeam, (item) => item.rfiTeam).map((item) => item.rfiTeam);
    return rfiTeams;
  }
);

export const selectRFIPointsForApplicationByCriteria = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiRound: number }) => args.rfiRound,
    (state: RootState, args: { rfiLocation: string }) => args.rfiLocation,
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
  ],
  (entities, rfiRound, rfiLocation, rfiPointType) => {
    return entities.filter(
      (entity) =>
        entity.rfiLocation === rfiLocation && entity.rfiPointType === rfiPointType && entity.rfiRound === rfiRound
    );
  }
);
export const selectRFIPointsForApplicationByTeamAndRound = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
    (state: RootState, args: { rfiTeam: string }) => args.rfiTeam,
    (state: RootState, args: { rfiRound: number }) => args.rfiRound,
  ],
  (entities, rfiPointType, rfiTeam, rfiRound) => {
    return entities.filter(
      (entity) => entity.rfiTeam === rfiTeam && entity.rfiPointType === rfiPointType && entity.rfiRound === rfiRound
    );
  }
);
export const selectRFIBuildingsForApplicationTypeByTeamAndRound = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
    (state: RootState, args: { rfiTeam: string }) => args.rfiTeam,
    (state: RootState, args: { rfiRound: number }) => args.rfiRound,
  ],
  (entities, rfiPointType, rfiTeam, rfiRound) => {
    const sortedEntities = [...entities].sort((a, b) => a.id - b.id);
    const rfiRoundPoints = sortedEntities.filter(
      (entity) => entity.rfiPointType === rfiPointType && entity.rfiTeam === rfiTeam && entity.rfiRound === rfiRound
    );
    const buildings = rfiRoundPoints.map((rfiPoint) => rfiPoint.rfiBuilding);
    return uniq(buildings);
  }
);

export const selectRFIPointsForApplicationTypeByTeamByRoundAndBuilding = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointType: RFIPointType }) => args.rfiPointType,
    (state: RootState, args: { rfiTeam: string }) => args.rfiTeam,
    (state: RootState, args: { rfiRound: number }) => args.rfiRound,
    (state: RootState, args: { rfiBuilding: string }) => args.rfiBuilding,
  ],
  (entities, rfiPointType, rfiTeam, rfiRound, rfiBuilding) => {
    const rfiBuildingPoints = entities.filter(
      (entity) =>
        entity.rfiPointType === rfiPointType &&
        entity.rfiTeam === rfiTeam &&
        entity.rfiRound === rfiRound &&
        entity.rfiBuilding === rfiBuilding
    );
    return rfiBuildingPoints;
  }
);

export const selectOutstandingRFIRoundsStatsByTypeForApplication = createDeepEqualSelector(
  [(state: RootState, applicationId: number) => selectRFIPointsByApplicationId(state, applicationId)],
  (entities) => {
    let indexedRFITypes: Record<RFIPointType, number> = { Vetting: 0, Consent: 0, CCC: 0 };
    for (const rfiPointType of Object.values(RFIPointType)) {
      // All rfiPoints of current rfiPointTypes
      let num: number = 0;
      const filteredRFIPointTypes = entities.filter((entity) => entity.rfiPointType === rfiPointType);
      const isAllRFIPointsV3 = filteredRFIPointTypes.every((entity) => entity.rfiPointStatus !== null);

      // For legacy group by location otherwise by team
      const teamLocationGroups = isAllRFIPointsV3
        ? groupBy(filteredRFIPointTypes, (entity) => entity.rfiTeam)
        : groupBy(filteredRFIPointTypes, (entity) => entity.rfiLocation);

      for (const group of Object.values(teamLocationGroups)) {
        // Grouping locations individual entities based on rfiRound
        const roundRFIPoints = groupBy(group, (rfiPoint) => rfiPoint.rfiRound);
        for (const rfiPoints of Object.values(roundRFIPoints)) {
          const outstandingPoints = rfiPoints.filter((rfiPoint) =>
            [RFIDerivedStatus.NotResponded, RFIDerivedStatus.Responded].includes(
              RFIPointUtil.getRFIDerivedStatus(rfiPoint)
            )
          );
          if (outstandingPoints.length > 0) {
            num += 1;
          }
        }
      }

      indexedRFITypes[rfiPointType] = num;
    }
    return indexedRFITypes;
  }
);

export const selectOutstandingRFIRoundsCountForApplication = createDeepEqualSelector(
  [selectOutstandingRFIRoundsStatsByTypeForApplication],
  (stats) => {
    let count = 0;
    for (const rfiPointType of Object.values(RFIPointType)) {
      count += stats[rfiPointType];
    }

    return count;
  }
);

export const selectRFIDocumentUsages = createDeepEqualSelector([selectAllRFIPointEntities], (entities) => {
  const mapping: Record<string, number> = {};
  const documentNames = entities.flatMap((entity) => entity.documentNames);
  const uniqDocumentsNames = uniq(documentNames);
  for (const documentName of uniqDocumentsNames) {
    const documentUsageCount = entities.filter((rfiEntity) => rfiEntity.documentNames.includes(documentName)).length;
    mapping[documentName] = documentUsageCount;
  }
  return mapping;
});

export const selectIsRFIPointEditable = createDeepEqualSelector([selectRFIPointEntityById], (rfiPoint) => {
  if (!rfiPoint) {
    return false;
  }

  const rfiStatus = RFIPointUtil.getRFIDerivedStatus(rfiPoint);
  const isEditable = [RFIDerivedStatus.NotResponded, RFIDerivedStatus.Responded].includes(rfiStatus);
  return isEditable;
});

export const selectRFIPointChainIds = createDeepEqualSelector(
  [
    (state: RootState, args: { applicationId: number }) => selectRFIPointsByApplicationId(state, args.applicationId),
    (state: RootState, args: { rfiPointId: number }) => args.rfiPointId,
  ],
  (entities, rfiPointId) => {
    // Find target RFI Point
    const rfiPoint = entities.find((rfiPoint) => rfiPoint.id === rfiPointId);
    const rfiPointRoundChainIds: number[] = [];
    if (!rfiPoint) {
      return rfiPointRoundChainIds;
    }

    rfiPointRoundChainIds.push(rfiPointId);

    const findPreviousRfiPoints = (guid: string | undefined, chain: number[]): number[] => {
      // stop endless loop if guid and prev guid the same. Should not happen
      if (rfiPoint.rfiPointGuid === guid) {
        return chain;
      }
      const previousRfiPoint = entities.find((rfiPoint) => rfiPoint.rfiPointGuid === guid);
      if (previousRfiPoint) {
        chain.unshift(previousRfiPoint.id);
        return findPreviousRfiPoints(previousRfiPoint.previousRfiPointGuid, chain);
      }
      return chain;
    };

    const findNextRfiPoints = (guid: string | undefined, chain: number[]): number[] => {
      // stop endless loop if guid and next guid the same. Should not happen
      if (rfiPoint.rfiPointGuid === guid) {
        return chain;
      }
      const nextRfiPoint = entities.find((rfiPoint) => rfiPoint.rfiPointGuid === guid);
      if (nextRfiPoint) {
        chain.push(nextRfiPoint.id);
        return findNextRfiPoints(nextRfiPoint.nextRfiPointGuid, chain);
      }
      return chain;
    };

    findPreviousRfiPoints(rfiPoint.previousRfiPointGuid, rfiPointRoundChainIds);
    findNextRfiPoints(rfiPoint.nextRfiPointGuid, rfiPointRoundChainIds);

    return rfiPointRoundChainIds;
  }
);
