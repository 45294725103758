import React from "react";
import { useStoreSelector } from "store/hooks";
import { selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments } from "store/domain-data/document/document";
import { styled } from "@mui/material/styles";
import { Collapse, Stack } from "@mui/material";
import { selectDocumentContainersEntityById } from "store/domain-data/document-containers/documentContainers";
import { useCollapse } from "hooks/useCollapse";
import MergedDocumentCard from "components/DocumentContainer/MergedDocumentCard";
import { useDownloadApplicationDocument } from "hooks/useDownloadApplicationDocument";
import { usePreviewApplicationDocument } from "components/DocumentPreviewDialog/usePreviewApplicationDocument";
import DocumentContainerHeader from "components/DocumentContainer/DocumentContainerHeader";
import isEmpty from "lodash/isEmpty";
import DocumentContainerDocumentCard from "components/DocumentContainer/DocumentContainerDocumentCard";
import Guard from "components/Guard/Guard";
import { DocumentContainerUtil } from "models/DocumentContainer.model";
import { css } from "@emotion/react";

type Props = {
  documentContainerId: number;
  applicationId: number;
  wrapperComponent?: React.ElementType;
};

const DocumentContainerReadonlyMode: React.FC<Props> = ({
  documentContainerId,
  applicationId,
  wrapperComponent: WrapperComponent = React.Fragment,
}) => {
  const documentContainer = useStoreSelector((state) => selectDocumentContainersEntityById(state, documentContainerId));
  const documents = useStoreSelector((state) =>
    selectDocumentEntitiesByApplicationIdForContainerIncludingRequiredDocuments(state, {
      applicationId,
      documentContainerId,
    })
  );

  const { isCollapsed, toggleCollapse } = useCollapse(false);

  const { downloadApplicationDocument } = useDownloadApplicationDocument(applicationId);
  const { previewApplicationDocument, applicationDocumentPreviewDialog } = usePreviewApplicationDocument(applicationId);

  if (!documentContainer || isEmpty(documents)) {
    return null;
  }

  return (
    <WrapperComponent>
      <StyledSection data-testid={`DocumentContainer-${documentContainer.id}`}>
        <DocumentContainerHeader documentContainerId={documentContainerId} />

        <StyledDocumentContainerContent>
          <Guard condition={DocumentContainerUtil.isMergeDocumentsSupported(documentContainer)}>
            <MergedDocumentCard
              applicationId={applicationId}
              documentContainerId={documentContainerId}
              isCollapsed={isCollapsed}
              isReadonly={true}
              onToggleCollapse={toggleCollapse}
            />
          </Guard>

          <Collapse in={!isCollapsed}>
            <StyledDocumentCardList>
              <Stack>
                {/* uploaded Files */}
                {documents.map((document) => (
                  <DocumentContainerDocumentCard
                    key={document.name}
                    document={document}
                    onPreviewApplicationDocument={previewApplicationDocument}
                    onDownloadApplicationDocument={downloadApplicationDocument}
                  />
                ))}
              </Stack>
            </StyledDocumentCardList>
          </Collapse>
        </StyledDocumentContainerContent>
      </StyledSection>

      {applicationDocumentPreviewDialog}
    </WrapperComponent>
  );
};

const StyledSection = styled("section")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("20px")}
  `
);

const StyledDocumentContainerContent = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
  `
);

const StyledDocumentCardList = styled(Stack)(
  ({ theme }) => css`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  `
);

export default DocumentContainerReadonlyMode;
