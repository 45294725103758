import { createAction, createAsyncThunk, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { RootState } from "store/types";
import { IApplicationAccessRequestEntity } from "models/ApplicationAccessRequest.model";
import { serviceContainer } from "services/serviceContainer";
import { createDeepEqualSelector } from "store/utils";

const applicationAccessRequestEntityAdapter = createEntityAdapter<IApplicationAccessRequestEntity>({
  selectId: (entity) => entity.requestGuid,
  sortComparer: (a, b) => {
    return b.requestGuid.localeCompare(a.requestGuid);
  },
});

export const loadApplicationAccessRequest = createAction<IApplicationAccessRequestEntity[]>(
  "domainData/application/loadAccessRequests"
);

export const fetchApplicationAccessRequest = createAsyncThunk(
  "domainData/application/fetchAccessRequests",
  async (_, thunkAPI) => {
    const requests = await serviceContainer.cradle.applicationAccessRequestService.fetchApplicationAccessRequests();
    thunkAPI.dispatch(loadApplicationAccessRequest(requests));
    return requests;
  }
);

export const rejectApplicationAccessRequest = createAsyncThunk(
  "domainData/application/rejectAccessRequest",
  async (requestGuid: string) => {
    await serviceContainer.cradle.applicationAccessRequestService.respondToApplicationAccessRequest({
      requestGuid,
      response: "DENIED",
    });
    return requestGuid;
  }
);

export const completeApplicationAccessRequest = createAsyncThunk(
  "domainData/application/completeAccessRequest",
  async (requestGuid: string) => {
    return requestGuid;
  }
);

export const requestApplicationAccessRequest = createAsyncThunk(
  "domainData/application/requestApplicationAccessRequest",
  async ({ applicationNumber, authorityId }: { applicationNumber: string; authorityId: number }) => {
    await serviceContainer.cradle.applicationAccessRequestService.requestApplicationAccessRequest({
      applicationNumber,
      authorityId,
    });
  }
);

export const initialState = applicationAccessRequestEntityAdapter.getInitialState();

export const applicationAccessRequestReducer = createReducer<typeof initialState>(initialState, (builder) =>
  builder
    .addCase(loadApplicationAccessRequest, applicationAccessRequestEntityAdapter.setAll)
    .addCase(rejectApplicationAccessRequest.fulfilled, applicationAccessRequestEntityAdapter.removeOne)
    .addCase(completeApplicationAccessRequest.fulfilled, applicationAccessRequestEntityAdapter.removeOne)
);

export const {
  selectById: selectApplicationAccessRequestEntityById,
  selectAll: selectAllApplicationAccessRequestEntities,
} = applicationAccessRequestEntityAdapter.getSelectors(
  (state: RootState) => state.domainData.applicationAccessRequests
);

export const selectApplicationAccessRequestsForCurrentUser = createDeepEqualSelector(
  [selectAllApplicationAccessRequestEntities],
  (requestEntities) => {
    return requestEntities;
  }
);
