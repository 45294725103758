import React from "react";
import { styled } from "@mui/material/styles";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import Guard from "components/Guard/Guard";
import { useMainContentScroll } from "components/odl-v2/PageLayout/functions/useMainContentScroll";
import ScrollPositionListener from "components/odl-v2/PageLayout/ScrollPositionListener";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { useMediaQuery } from "@mui/material";
import { hideInPrintMixin } from "styles/mixins/hideInPrintMixin";
import { css } from "@emotion/react";

type ChildrenFunc = (args: { mainScrollElement: HTMLElement }) => React.ReactNode;

type Props = {
  pageHeader: React.ReactNode;
  leftColumn?: React.ReactNode;
  isLeftColumnCollapsed: boolean;
  rightColumn?: React.ReactNode;
  rightColumnAlt?: React.ReactNode;
  scrollElementRef?: React.MutableRefObject<HTMLElement | undefined>;
  "data-testid"?: string;
  children?: React.ReactNode | ChildrenFunc;
};

const ThreeColumnsLayout: React.FC<Props> = React.forwardRef(
  (
    {
      pageHeader,
      leftColumn,
      isLeftColumnCollapsed,
      rightColumn,
      rightColumnAlt,
      children,
      scrollElementRef,
      "data-testid": dataTestId,
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const screenWidthMatch = useScreenWidthMatch();
    const isRightColumnVisible = screenWidthMatch.xxl;
    const { scrollElement, handleScrollElementRef, childrenNode } = useMainContentScroll(children, scrollElementRef);
    const isPrinting = useMediaQuery("print");

    return (
      <StyledPageContainer ref={ref} data-testid={dataTestId || "ThreeColumnsPage"}>
        <StyledMainContentContainer ref={handleScrollElementRef} $isLeftColumnCollapsed={isLeftColumnCollapsed}>
          <StyledChildrenContainer>{childrenNode}</StyledChildrenContainer>
        </StyledMainContentContainer>

        <StyledLeftColumnContainer $isLeftColumnCollapsed={isLeftColumnCollapsed}>
          {leftColumn}
        </StyledLeftColumnContainer>

        <Guard condition={isRightColumnVisible}>
          <StyledRightColumnContainer $isLeftColumnCollapsed={isLeftColumnCollapsed}>
            {rightColumn}
          </StyledRightColumnContainer>
        </Guard>

        <ScrollPositionListener scrollElement={scrollElement}>
          {({ scrollPosition }) => (
            <React.Fragment>
              <StyledTopBarContainer
                $isLeftColumnCollapsed={isLeftColumnCollapsed}
                $hasShadow={!isPrinting && scrollPosition > 0}
              >
                <StyledHeaderContainer>{pageHeader}</StyledHeaderContainer>
                <Guard condition={!isRightColumnVisible}>
                  <StyledRightColumnAltContainer>{rightColumnAlt}</StyledRightColumnAltContainer>
                </Guard>
              </StyledTopBarContainer>

              <ScrollToTop scrollElement={scrollElement} scrollPosition={scrollPosition} />
            </React.Fragment>
          )}
        </ScrollPositionListener>
      </StyledPageContainer>
    );
  }
);

const StyledPageContainer = styled("div")(
  ({ theme }) => css`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  `
);

const StyledMainContentContainer = styled("div")<{ $isLeftColumnCollapsed: boolean }>(
  ({ theme, $isLeftColumnCollapsed }) => css`
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    padding-left: ${$isLeftColumnCollapsed ? 56 : 240}px;
    transition: ${theme.mixins.layoutTransition(["padding-left", "padding-right"])};

    ${theme.breakpoints.up("xxl")} {
      padding-right: ${$isLeftColumnCollapsed ? 585 : 385}px;
    }

    @media print {
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
    }
  `
);

const StyledChildrenContainer = styled("div")(
  ({ theme }) => css`
    min-width: calc(100vw - 56px);
    padding: 0 8px 8px 8px;

    ${theme.breakpoints.up("sm")} {
      min-width: 620px;
      padding: 0 24px 24px 24px;
    }
  `
);

const StyledHeaderContainer = styled("div")(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    z-index: ${theme.zIndex.appBar - 1};
  `
);

const StyledLeftColumnContainer = styled("div")<{ $isLeftColumnCollapsed: boolean }>(
  ({ theme, $isLeftColumnCollapsed }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    bottom: 0;
    padding-top: 12px;
    border-right: 2px solid ${theme.palette.objective.light.mid};

    ${$isLeftColumnCollapsed &&
    css`
      width: 56px;
    `}

    ${hideInPrintMixin()}
  `
);

const StyledRightColumnContainer = styled("div")<{ $isLeftColumnCollapsed: boolean }>(
  ({ theme, $isLeftColumnCollapsed }) => css`
    position: absolute;
    top: 80px;
    right: 0;
    width: ${$isLeftColumnCollapsed ? 585 : 385}px;
    padding: 0 24px;
    transition: ${theme.mixins.layoutTransition("width")};
  `
);

const StyledRightColumnAltContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-shrink: 1;
    z-index: ${theme.zIndex.appBar - 1};
  `
);

const StyledTopBarContainer = styled("div")<{ $isLeftColumnCollapsed: boolean; $hasShadow: boolean }>(
  ({ theme, $isLeftColumnCollapsed, $hasShadow }) => css`
    position: absolute;
    top: 0;
    left: ${$isLeftColumnCollapsed ? 56 : 240}px;
    right: 15px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.palette.objective.light.white};

    z-index: ${theme.zIndex.appBar - 1};
    padding: 0 24px;
    ${theme.mixins.flexGap("8px")}

    & > * {
      display: flex;
    }

    ${theme.breakpoints.up("xxl")} {
      right: ${$isLeftColumnCollapsed ? 600 : 400}px;
    }

    ${$hasShadow && theme.mixins.headerShadow()}
    
    transition:${theme.mixins.layoutTransition(["left", "box-shadow", "right"])};
    
    @media print {
      left: 0;
      right: 0;
    }
  `
);

export default ThreeColumnsLayout;
