import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PersonalInfoForm from "components/ParticipantEditDialog/PersonalInfoForm";
import { unwrapResult } from "@reduxjs/toolkit";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import ParticipantTypeAndAccessForm from "components/ParticipantEditDialog/ParticipantTypeAndAccessForm";
import { useToast } from "hooks/useToast";
import {
  createParticipant,
  fetchParticipants,
  selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
  updateParticipant,
} from "store/domain-data/participant/participant";
import { selectMustInviteSelectedParticipantTypes } from "store/domain-data/participant-type/participantType";
import {
  selectCurrentParticipant,
  selectCurrentParticipantTypes,
  selectIsParticipantChanged,
  selectParticipantType,
  setAccessLevel,
  startEditParticipant,
  setPersonalInfoFormFields,
} from "store/app-state/participant-buffer/participantBuffer";
import isEmpty from "lodash/isEmpty";
import {
  InvitationStatus,
  IParticipantEntity,
  IParticipantPersonalInfoFormData,
  IParticipantTypeApplicationEntity,
  ParticipantAccessLevel,
} from "models/Participant.model";
import { createContact, fetchContactsForCurrentUser, updateContact } from "store/domain-data/contact/contact";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import { fetchQualificationTypes } from "store/domain-data/qualification-type/qualificationType";
import ButtonLoadingIcon from "components/ButtonLoadingIcon/ButtonLoadingIcon";
import { selectIsLoadingThunks } from "store/app-state/loading/loading";
import { usePostHog } from "posthog-js/react";
import { Button, Step, StepIconProps, StepLabel, Stepper, useTheme } from "@mui/material";
import Guard from "../Guard/Guard";
import Box from "../Box/Box";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import ParticipantLicences from "./ParticipantLicences";
import OwnerGroupDetails from "components/ParticipantEditDialog/owner-group-form/OwnerGroupDetails";
import { css } from "@emotion/react";
import { fetchApplicationAccessRequest } from "store/domain-data/application-access-request/applicationAccessRequest";

export enum ParticipantDialogSteps {
  RolesAndAccess = "Roles and access",
  ContactDetails = "Contact details",
  OwnersGroup = "Owners group",
  LicenseDetails = "License details",
}

type Props = {
  applicationId: number;
  participantId: number; // If participantId is 0, it means we are going to create a participant
  initialType?: IParticipantTypeApplicationEntity;
  onClose: (created?: boolean) => any;
  initialPersonalValues?: IParticipantPersonalInfoFormData;
  allowSearch?: boolean;
};

const ParticipantEditDialog: React.FC<Props> = ({
  applicationId,
  participantId,
  initialType,
  onClose,
  initialPersonalValues,
  allowSearch = true,
}) => {
  // Common
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useStoreDispatch();
  const { toastSuccess, toastError } = useToast();
  const postHog = usePostHog();

  const [shouldSaveAsContact, setShouldSaveAsContact] = React.useState(false);
  const [shouldUpdateContactLibrary, setShouldUpdateContactLibrary] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const currentParticipantTypes = useStoreSelector((state) => selectCurrentParticipantTypes(state));

  const visibleParticipantSteps = React.useMemo(() => {
    const currentTypesMap = currentParticipantTypes.map((type) => type.name);
    if (currentTypesMap.includes("OwnerContact")) {
      return [
        ParticipantDialogSteps.RolesAndAccess,
        ParticipantDialogSteps.ContactDetails,
        ParticipantDialogSteps.OwnersGroup,
        ParticipantDialogSteps.LicenseDetails,
      ];
    }

    return [
      ParticipantDialogSteps.RolesAndAccess,
      ParticipantDialogSteps.ContactDetails,
      ParticipantDialogSteps.LicenseDetails,
    ];
  }, [currentParticipantTypes]);

  const currentStepEnum = React.useMemo(() => {
    return visibleParticipantSteps[currentStep];
  }, [currentStep, visibleParticipantSteps]);

  const participantSteps = React.useMemo(() => {
    const stepNames: string[] = [];
    for (const step of visibleParticipantSteps) {
      stepNames.push(step.toString());
    }
    return stepNames;
  }, [visibleParticipantSteps]);

  const nextStep = React.useMemo(() => {
    return visibleParticipantSteps[currentStep + 1]?.toString() || "";
  }, [currentStep, visibleParticipantSteps]);
  const hasPreviousStep = React.useMemo(() => {
    return currentStep !== 0;
  }, [currentStep]);

  const handleNextStep = React.useCallback(() => {
    setCurrentStep((value) => value + 1);
  }, []);

  const handlePreviousStep = React.useCallback(() => {
    setCurrentStep((value) => value - 1);
  }, []);

  const participantTypesOfCurrentUserOnApplicationBasedOnApplicationId = useStoreSelector((state) =>
    selectParticipantTypesOfCurrentUserOnApplicationBasedOnApplicationId(state, applicationId)
  );

  // Derived data
  const [dialogInitialised, setDialogInitialised] = React.useState(false);

  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));

  const mustInviteSelectedParticipantsTypes = useStoreSelector((state) =>
    selectMustInviteSelectedParticipantTypes(state, { selectedParticipantTypes: currentParticipant.participantTypes })
  );

  const isParticipantChanged = useStoreSelector(selectIsParticipantChanged);

  const isNewParticipant = React.useMemo(() => !Boolean(participantId), [participantId]);

  const isLoading = useStoreSelector((state) => selectIsLoadingThunks(state, [createParticipant, updateParticipant]));

  // Form defaults to invalid for new participants
  const [isFormValid, setIsFormValid] = React.useState(!isNewParticipant);

  const isPersonalInfoValid = React.useMemo(() => {
    return isFormValid && (!!currentParticipant.email || isEmpty(mustInviteSelectedParticipantsTypes));
  }, [isFormValid, currentParticipant.email, mustInviteSelectedParticipantsTypes]);

  const isSectionCompleted = React.useMemo(() => {
    switch (currentStepEnum) {
      case ParticipantDialogSteps.RolesAndAccess:
        return true;
      case ParticipantDialogSteps.ContactDetails:
        return isPersonalInfoValid;
      case ParticipantDialogSteps.OwnersGroup:
        return true;
      case ParticipantDialogSteps.LicenseDetails:
        return true;
    }
  }, [isPersonalInfoValid, currentStepEnum]);

  const isSaveAllowed = isPersonalInfoValid && isParticipantChanged;

  const hasInvitationBeenSent = React.useCallback(
    (savedParticipant: IParticipantEntity) => {
      // Display success toast when participant invite status currently none or error, and changed to sent
      return (
        (currentParticipant.invitation === InvitationStatus.None ||
          currentParticipant.invitation === InvitationStatus.Error) &&
        (savedParticipant.invitation === InvitationStatus.Unregistered ||
          savedParticipant.invitation === InvitationStatus.Queued ||
          savedParticipant.invitation === InvitationStatus.Accepted)
      );
    },
    [currentParticipant.invitation]
  );

  const hasInvitationFailed = React.useCallback(
    (savedParticipant: IParticipantEntity) => {
      // Display error toast when participant invite status not already error, and becomes error
      return (
        currentParticipant.invitation !== InvitationStatus.Error &&
        savedParticipant.invitation === InvitationStatus.Error
      );
    },
    [currentParticipant.invitation]
  );

  // Callbacks
  const saveParticipant = React.useCallback(async () => {
    const payload = {
      ...currentParticipant,
      participantTypes: currentParticipant.participantTypes.map((type) => type.name),
    };

    if (isNewParticipant) {
      return await dispatch(createParticipant({ applicationId, payload: payload })).then(unwrapResult);
    } else {
      return await dispatch(updateParticipant({ applicationId, participantId, payload: payload })).then(unwrapResult);
    }
  }, [currentParticipant, isNewParticipant, dispatch, applicationId, participantId]);

  const handleClickFinish = React.useCallback(async () => {
    if (!isSaveAllowed) {
      return;
    }

    try {
      const savedParticipant = await saveParticipant();

      const personalInfoFormValues = {
        firstName: savedParticipant.firstName,
        lastName: savedParticipant.lastName,
        organisation: savedParticipant.organisation,
        phoneCountryCode: savedParticipant.phoneCountryCode,
        phone: savedParticipant.phone,
        email: savedParticipant.email,
        address1: savedParticipant.address.address1,
        address2: savedParticipant.address.address2,
        city: savedParticipant.address.city,
        state: savedParticipant.address.state,
        country: savedParticipant.address.country,
        zipCode: savedParticipant.address.zipCode,
        fullAddress: savedParticipant.address.fullAddress,
        isManualAddress: savedParticipant.address.isManualAddress,
      };
      if (shouldSaveAsContact) {
        await dispatch(
          createContact({ formValues: personalInfoFormValues, qualificationValues: savedParticipant.qualifications })
        ).then(unwrapResult);
      }

      if (shouldUpdateContactLibrary) {
        await dispatch(
          updateContact({
            contactId: currentParticipant.libraryContactId,
            formValues: personalInfoFormValues,
            qualificationValues: savedParticipant.qualifications,
          })
        ).then(unwrapResult);
      }

      if (hasInvitationBeenSent(savedParticipant)) {
        toastSuccess(t(`Invitation has been sent to {{email}}`, { email: savedParticipant.email }));
        postHog?.capture(`user sent-participant-invitation`, {
          participantTypes: participantTypesOfCurrentUserOnApplicationBasedOnApplicationId || "non-participant",
        });
      } else if (hasInvitationFailed(savedParticipant)) {
        toastError(
          t(`Invitation has failed to send to {{email}}. Please try to re-send later`, {
            email: savedParticipant.email,
          })
        );
      }

      // Load updated participants (BE handles migrating participant types between participants) and access requests
      await Promise.all([
        dispatch(fetchParticipants({ applicationId })).then(unwrapResult),
        dispatch(fetchApplicationAccessRequest()).then(unwrapResult),
      ]);

      onClose(true);
    } catch (e) {
      toastError(t(`Failed to save participant. Please try again later`));
    }
  }, [
    isSaveAllowed,
    saveParticipant,
    shouldSaveAsContact,
    shouldUpdateContactLibrary,
    hasInvitationBeenSent,
    hasInvitationFailed,
    dispatch,
    applicationId,
    onClose,
    currentParticipant.libraryContactId,
    toastSuccess,
    t,
    postHog,
    participantTypesOfCurrentUserOnApplicationBasedOnApplicationId,
    toastError,
  ]);

  const handleFormValidated = React.useCallback((valid: boolean) => {
    setIsFormValid(valid);
  }, []);

  const handleGetStepIcons = React.useCallback(
    (stepIconProps: StepIconProps) => {
      const { active, completed } = stepIconProps;
      const isFutureStep = !active && !completed;
      const fontIcon = active ? ODL_ICONS.FORMAT_EDIT : ODL_ICONS.STATUS_CHECKMARK;
      const ownerState = {
        active,
        completed,
      };

      let color: string = "";
      if (active) {
        color = theme.palette.objective.light.white;
      } else if (completed) {
        color = theme.palette.objective.blue.main;
      } else {
        color = theme.palette.objective.dark.neutral;
      }

      return (
        <StyledStepIconContainer ownerState={ownerState} color={color}>
          {isFutureStep && <StyledStepperText ownerState={ownerState}>{String(stepIconProps.icon)}</StyledStepperText>}
          {!isFutureStep && <FontIcon name={fontIcon} />}
        </StyledStepIconContainer>
      );
    },
    [theme.palette.objective.blue.main, theme.palette.objective.dark.neutral, theme.palette.objective.light.white]
  );

  React.useEffect(() => {
    setDialogInitialised(false);
    const initData = async () => {
      await dispatch(startEditParticipant({ applicationId, participantId: participantId || undefined }));
      if (initialType && initialType.minimumAccessLevel) {
        dispatch(selectParticipantType(initialType));
        dispatch(setAccessLevel(initialType.minimumAccessLevel));
      }
      await dispatch(fetchContactsForCurrentUser());
      await dispatch(fetchQualificationTypes());
      if (initialPersonalValues) {
        dispatch(setPersonalInfoFormFields(initialPersonalValues));

        // Set access level to read only by default for access requested
        dispatch(setAccessLevel(ParticipantAccessLevel.ReadOnly));
      }
      setDialogInitialised(true);
    };
    initData();
  }, [applicationId, participantId, initialType, initialPersonalValues, dispatch]);

  const handleChangeSaveAsContact = React.useCallback((value: boolean) => {
    setShouldSaveAsContact(value);
  }, []);
  const handleUpdateContactLibrary = React.useCallback((value: boolean) => {
    setShouldUpdateContactLibrary(value);
  }, []);

  return (
    <Dialog
      open={true}
      data-testid={"ParticipantEditDialog"}
      title={isNewParticipant ? t(`Add a participant`) : t(`Edit a person on the application`)}
      onClose={() => onClose()}
    >
      {dialogInitialised && (
        <StyledDialogContent>
          <StyledStepperContainer>
            <Stepper activeStep={currentStep} alternativeLabel>
              {participantSteps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={handleGetStepIcons}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </StyledStepperContainer>
          {currentStepEnum === ParticipantDialogSteps.ContactDetails && (
            <PersonalInfoForm onFormValidated={handleFormValidated} allowSearch={allowSearch} />
          )}
          {currentStepEnum === ParticipantDialogSteps.RolesAndAccess && (
            // Disables no access role if this dialog is accessed through participant access request
            <ParticipantTypeAndAccessForm allowNoAccess={!initialPersonalValues} />
          )}
          {currentStepEnum === ParticipantDialogSteps.LicenseDetails && (
            <ParticipantLicences
              shouldSaveAsContact={shouldSaveAsContact}
              shouldUpdateContactLibrary={shouldUpdateContactLibrary}
              onSaveAsContactChanged={handleChangeSaveAsContact}
              onUpdateContactLibrary={handleUpdateContactLibrary}
            />
          )}
          {currentStepEnum === ParticipantDialogSteps.OwnersGroup && <OwnerGroupDetails />}
        </StyledDialogContent>
      )}

      <DialogActions>
        <Guard condition={!isLoading}>
          <DialogActionGroup>
            <Guard condition={hasPreviousStep}>
              <StyledButton
                data-testid={"ParticipantBackButton"}
                onClick={handlePreviousStep}
                startIcon={<FontIcon name={ODL_ICONS.NAVIGATION_CHEVRON_LEFT} />}
              >
                {t(`Back`)}
              </StyledButton>
            </Guard>
          </DialogActionGroup>
          <DialogActionGroup>
            <Guard condition={nextStep}>
              <DialogActionPrimary
                data-testid={"ParticipantNextButton"}
                onClick={handleNextStep}
                endIcon={<FontIcon name={ODL_ICONS.NAVIGATION_CHEVRON_RIGHT} />}
                disabled={!isSectionCompleted}
              >
                {nextStep}
              </DialogActionPrimary>
            </Guard>
            <Guard condition={!nextStep}>
              <DialogActionPrimary
                data-testid={"ParticipantFinishButton"}
                onClick={handleClickFinish}
                disabled={!isSaveAllowed || isLoading}
                endIcon={isLoading ? <ButtonLoadingIcon /> : null}
              >
                {t(`Finish`)}
              </DialogActionPrimary>
            </Guard>
          </DialogActionGroup>
        </Guard>
      </DialogActions>
    </Dialog>
  );
};

const StyledStepIconContainer = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
  color: string;
}>(
  ({ theme, ownerState, color }) => css`
    width: 30px;
    height: 30px;
    background-color: ${ownerState.active ? theme.palette.objective.blue.main : theme.palette.objective.light.white};
    color: ${color};
    border: 2px solid
      ${ownerState.active || ownerState.completed
        ? theme.palette.objective.blue.main
        : theme.palette.objective.dark.neutral};

    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  `
);

const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledDialogContent = styled(DialogContent)(
  ({ theme }) => css`
    padding: 0;
    margin: 0;
    width: 960px;
    min-height: 724px;
    display: flex;
    flex-direction: column;
  `
);
const StyledStepperContainer = styled(Box)(
  ({ theme }) => css`
    border-top: 2px solid ${theme.palette.objective.light.daylight};
    border-bottom: 2px solid ${theme.palette.objective.light.daylight};
    padding-top: 12px;
    padding-bottom: 12px;
  `
);

const StyledStepperText = styled(Box)<{ ownerState: { completed?: boolean; active?: boolean } }>(
  ({ theme, ownerState }) => css`
    font-size: 16px;
    font-weight: ${ownerState.active ? 600 : 400};
    color: ${ownerState.active ? theme.palette.objective.dark.night : theme.palette.objective.dark.neutral};
  `
);

export default ParticipantEditDialog;
