import React from "react";
import { Link, LinkProps, Theme, Typography } from "@mui/material";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { styled } from "@mui/material/styles";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";

import { css } from "@emotion/react";

export enum ActionItemVariant {
  Link = "Link",
  Button = "Button",
}

export type IActionItem = {
  icon: typeof ODL_ICONS[keyof typeof ODL_ICONS] | JSX.Element;
  title: string;
  variant: ActionItemVariant;
  to?: string;
  onClick?: () => void;
  tooltip?: React.ReactNode;
  dialog?: React.ReactNode;
  testId?: string;
  disabled?: Boolean;
};

type Props = {
  item: IActionItem;
};

const ActionItem: React.FC<Props> = ({ item }) => {
  const { icon, title, variant, to, onClick, testId, disabled, dialog } = item;

  if (variant === ActionItemVariant.Button) {
    return (
      <React.Fragment>
        <StyledActionButton
          data-testid={testId || "ActionItem"}
          onClick={onClick}
          role="button"
          disabled={disabled || false}
        >
          {typeof icon === "string" && <FontIcon name={icon} fontSize={20} />}
          {typeof icon === "object" && <SvgIconContainer>{icon}</SvgIconContainer>}
          <StyledActionTitle>{title}</StyledActionTitle>
        </StyledActionButton>
        {dialog}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <StyledActionLink
        data-testid={"ActionItem"}
        component={RouterLink}
        underline="none"
        to={to || "#"}
        disabled={disabled || false}
      >
        {typeof icon === "string" && <FontIcon name={icon} fontSize={20} />}
        {typeof icon === "object" && <SvgIconContainer>{icon}</SvgIconContainer>}
        <StyledActionTitle>{title}</StyledActionTitle>
      </StyledActionLink>
      {dialog}
    </React.Fragment>
  );
};

const SvgIconContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  `
);

const StyledActionTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 400;
  `
);

const StyledActionLink = styled<any>(Link)(
  ({ theme, disabled }: { theme: Theme; disabled: Boolean }) => css`
    display: flex;
    align-items: center;
    padding: 8px 0;
    ${theme.mixins.flexGap("8px")}

    &:hover {
      text-decoration: none;
      color: ${theme.palette.objective.blue.main};

      path {
        fill: ${theme.palette.primary.main};
      }

      .MuiTypography-root {
        text-decoration: underline;
      }
    }

    ${disabled &&
    `
      pointer-events: none;
      color: ${theme.palette.objective.dark.neutral2};
    `}
  `
) as React.FC<LinkProps & RouterLinkProps & { disabled: Boolean }>;

const StyledActionButton = styled("div")(
  ({ theme, disabled }: { theme: Theme; disabled: Boolean }) => css`
    display: flex;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
    color: ${theme.palette.objective.dark.black};
    ${theme.mixins.flexGap("8px")}

    &:hover {
      color: ${theme.palette.objective.blue.main};

      path {
        fill: ${theme.palette.primary.main};
      }
    }

    ${disabled &&
    `
      pointer-events: none;
      color: ${theme.palette.objective.dark.neutral2};
    `}
  `
);

export default ActionItem;
