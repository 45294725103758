import React from "react";
import { useTranslation } from "react-i18next";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectAuthorityEntityByAbbr } from "store/domain-data/authority/authority";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import AlertAction from "components/odl-v2/Alert/AlertAction";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import { Divider } from "@mui/material";
import AuthorityInfoCard from "pages/user-profile/AuthorityInfoCard";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionSecondary from "components/odl-v2/Dialog/DialogActionSecondary";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import { styled } from "@mui/material/styles";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import { updateRegisteredApplicantInvitation } from "store/domain-data/registered-applicant-invitation/registeredApplicantInvitationReducer";
import { RegisteredApplicantInvitationStatus } from "models/RegisteredApplicantInvitation.model";
import { css } from "@emotion/react";

type Props = {
  authorityAbbr: string;
  invitatoinId: number;
};

const RespondToRegisterApplicantInvitationAlert: React.FC<Props> = ({ authorityAbbr, invitatoinId }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const authority = useStoreSelector((state) => selectAuthorityEntityByAbbr(state, authorityAbbr));

  const handleClickOrganisationMergeInvitation = React.useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = React.useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleClickAcceptInvitation = React.useCallback(() => {
    dispatch(
      updateRegisteredApplicantInvitation({
        invitationId: invitatoinId,
        status: RegisteredApplicantInvitationStatus.Accepted,
      })
    );
    setIsDialogOpen(false);
  }, [dispatch, invitatoinId]);

  const handleClickDeclineInvitation = React.useCallback(() => {
    dispatch(
      updateRegisteredApplicantInvitation({
        invitationId: invitatoinId,
        status: RegisteredApplicantInvitationStatus.Rejected,
      })
    );
    setIsDialogOpen(false);
  }, [dispatch, invitatoinId]);

  const respondToRegisterApplicantInvitationAlert = React.useMemo(() => {
    if (!authority) {
      return null;
    }

    return (
      <React.Fragment>
        <OdlAlert
          severity={"info"}
          data-testid={"RespondToRegisterApplicantInvitationAlert"}
          header={t(`Register with {{authorityName}}`, { authorityName: authority.displayName })}
          action={
            <AlertAction
              severity={"info"}
              data-testid={"RespondToRegisterApplicantInvitationAlertLink"}
              onClick={handleClickOrganisationMergeInvitation}
            >
              {t(`Respond`)}
            </AlertAction>
          }
        >
          {t(
            `{{authorityName}} would like to add your to their Registered Applicants register so you can submit applications to them for processing.`,
            { authorityName: authority.displayName }
          )}
        </OdlAlert>
        <Dialog
          title={t(`Respond to registered applicant invitation`)}
          data-testid={"RespondToRegisterApplicantInvitationAlertDialog"}
          open={isDialogOpen}
          onClose={handleCloseDialog}
          maxWidth={false}
          scroll={"body"}
        >
          <DialogContent>
            <StyledContent>
              <StyledParagraph>
                {t(
                  `{{authorityName}} would like to add you to their Registered Applicants register, so you can submit building consent applications to them for processing.`,
                  { authorityName: authority.displayName }
                )}
              </StyledParagraph>
              <StyledParagraph>
                {t(
                  `If you accept, you are registered with {{authorityName}} and they can view your user profile details. When you create an application, you can select {{authorityName}} as the processing authority instead of the defaulted building consent authority.`,
                  { authorityName: authority.displayName }
                )}
              </StyledParagraph>
              <StyledParagraph>
                {t(`If you decline, {{authorityName}} is notified and you do not need to take further action.`, {
                  authorityName: authority.displayName,
                })}
              </StyledParagraph>
              <Divider />
              <AuthorityInfoCard authorityId={authority.id} />
            </StyledContent>
          </DialogContent>
          <StyledDialogActions>
            <DialogActionGroup>
              <DialogActionSecondary data-testid={"DeclineButton"} onClick={handleClickDeclineInvitation}>
                {t(`Decline`)}
              </DialogActionSecondary>
              <DialogActionPrimary data-testid={"AcceptButton"} onClick={handleClickAcceptInvitation}>
                {t(`Accept`)}
              </DialogActionPrimary>
            </DialogActionGroup>
          </StyledDialogActions>
        </Dialog>
      </React.Fragment>
    );
  }, [
    authority,
    handleClickAcceptInvitation,
    handleClickDeclineInvitation,
    handleClickOrganisationMergeInvitation,
    handleCloseDialog,
    isDialogOpen,
    t,
  ]);
  return respondToRegisterApplicantInvitationAlert;
};

const StyledContent = styled("div")(
  ({ theme }) => css`
    max-width: 600px;
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("16px")}
  `
);

const StyledParagraph = styled("p")(
  ({ theme }) => css`
    display: flex;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 16px;
  `
);

const StyledDialogActions = styled(DialogActions)(
  ({ theme }) => css`
    flex-direction: row-reverse;
  `
);

export default RespondToRegisterApplicantInvitationAlert;
