import React from "react";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = {};

const PanelInner = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(({ children, ...props }, ref) => {
  return (
    <StyledPanelContainerInner ref={ref} {...props}>
      {children}
    </StyledPanelContainerInner>
  );
});

const StyledPanelContainerInner = styled("div")(
  ({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-radius: 8px;
    overflow: hidden;
  `
);

export default PanelInner;
