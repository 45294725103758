import React from "react";
import { styled } from "@mui/material/styles";
import { disabledMixin } from "styles/mixins/disabledMixin";
import { AlertColor } from "@mui/lab/Alert";
import { Button } from "@mui/material";

type Props = React.ComponentProps<typeof Button> & {
  severity: AlertColor;
};

const AlertAction: React.FC<Props> = React.forwardRef(
  ({ children, severity, disabled = false, ...otherProps }, ref) => {
    return (
      <StyledButton variant="outlined" disabled={disabled} ref={ref} data-testid={"AlertAction"} {...otherProps}>
        {children}
      </StyledButton>
    );
  }
);

const StyledButton = styled(Button)(
  ({ theme, disabled }) => `
    ${disabledMixin(disabled)}
    background: none;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    &:focus-visible {
      background: rgba(0, 0, 0, 0.08);
      outline: none;
      border: ${theme.palette.objective.blue.main} solid 1px;
    }
  `
);

export default AlertAction;
