import { enumFromValue } from "utils/enumFromValue";
import { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { defaultTheme } from "styles/defaultTheme";
import { TFunction } from "i18next";

export enum InspectionStatus {
  Pass = "Pass",
  Fail = "Fail",
  InProgress = "InProgress",
  NotApplicable = "N/A",
}

export const InspectionStatusLabels = new Map<string, (t: TFunction) => string>([
  [InspectionStatus.Pass, (t) => t("Pass")],
  [InspectionStatus.Fail, (t) => t("Fail")],
  [InspectionStatus.InProgress, (t) => t("In progress")],
  [InspectionStatus.NotApplicable, (t) => t("N/A")],
]);

export enum InspectionTypeStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Completed = "Completed",
  Fail = "Fail",
}

export const InspectionTypeStatusMetaData = new Map<string, InspectionTypeStatusMetaDataEntity>([
  [InspectionTypeStatus.NotStarted, { statusLabel: (t) => "" }],
  [InspectionTypeStatus.InProgress, { statusLabel: (t) => t("In Progress") }],
  [
    InspectionTypeStatus.Completed,
    {
      statusLabel: (t) => t("Completed"),
      statusIcon: ODL_ICONS.STATUS_CHECKMARK,
      statusIconColour: defaultTheme.palette.objective.green.main,
      showStatusDate: true,
    },
  ],
  [
    InspectionTypeStatus.Fail,
    {
      statusLabel: (t) => t("Fail"),
      statusIcon: ODL_ICONS.STATUS_WARNING_ALT,
      statusIconColour: defaultTheme.palette.objective.red.main,
      showStatusDate: true,
    },
  ],
]);

export type InspectionTypeStatusMetaDataEntity = {
  statusLabel: (t: TFunction) => string;
  statusIcon?: typeof ODL_ICONS[keyof typeof ODL_ICONS];
  statusIconColour?: string;
  showStatusDate?: boolean;
};

export type IRequiredInspectionsEntity = {
  applicationId: number;
  location: string;
  inspectionTypes: IRequiredInspectionTypeEntity[];
};

export type IRequiredInspectionTypeEntity = {
  inspectionTypeId: string;
  inspectionStatus: InspectionTypeStatus;
  inspectionTypeName: string;
  inspectionsRequired: number;
  nextBookingDate?: string;
  inspectionHistory: IRequiredInspectionHistoryEntity[];
  sequence: number;
};

export type IRequiredLocationInspectionTypeEntity = {
  location: string;
} & IRequiredInspectionTypeEntity;

export type IRequiredInspectionHistoryEntity = {
  inspectionDate: string;
  inspectionName: string;
  inspectionResult: InspectionStatus;
  inspectionNotice: string;
};

export const RequiredInspectionsUtil = {
  numberPassed: (type: IRequiredInspectionTypeEntity): number => {
    const inspectionsPassed = type.inspectionHistory.filter(
      (history) =>
        history.inspectionResult === InspectionStatus.Pass ||
        history.inspectionResult === InspectionStatus.NotApplicable
    );
    return inspectionsPassed.length;
  },
  mostRecentInspection: (type: IRequiredInspectionTypeEntity): IRequiredInspectionHistoryEntity | null => {
    if (type.inspectionHistory.length === 0) {
      return null;
    }
    return type.inspectionHistory[0];
  },
  resultLabel: (status: InspectionStatus, t: TFunction) => {
    const statusLabel = InspectionStatusLabels.get(status);
    if (!statusLabel) {
      return t("unknown");
    }
    return statusLabel(t);
  },
};

export const InspectionStatusUtil = {
  parse: (value: string): InspectionStatus => {
    // TODO: BLD-1987 typing issue with this function
    const parsedEnum: any = enumFromValue(InspectionStatus, value, InspectionStatus.Fail);
    return parsedEnum;
  },
};

export const InspectionTypeStatusUtil = {
  parse: (value: string): InspectionTypeStatus => {
    // TODO: BLD-1987 typing issue with this function
    const parsedEnum: any = enumFromValue(InspectionTypeStatus, value, InspectionTypeStatus.Fail);
    return parsedEnum;
  },
};
