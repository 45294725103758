import React from "react";
import PageHeader from "components/odl-v2/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { useStoreSelector } from "store/hooks";
import { selectUserInfoEntityForAuthenticatedUser } from "store/domain-data/user-info/userInfo";
import { css } from "@emotion/react";
import { styled } from "@mui/material/styles";
import ActionSection from "components/ActionSection/ActionSection";
import ActionSectionAlt from "components/ActionSectionAlt/ActionSectionAlt";
import ThreeColumnsLayout from "components/odl-v2/PageLayout/ThreeColumnsLayout";
import PrimarySideNavigation from "components/PrimarySideNavigation/PrimarySideNavigation";
import { PrimarySideNavigationItemType } from "components/PrimarySideNavigation/PrimarySideNavigation.types";
import ApplicationsSection from "pages/dashboard/ApplicationsSection";
import { useApplicationIntentActionItems } from "hooks/useApplicationIntentActionItems";
import StartApplicationSection from "pages/dashboard/StartApplicationSection";
import AlertsSection from "pages/dashboard/AlertsSection";
import { selectIsSideNavigationCollapsed } from "store/app-state/ui/ui";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import { FOCUS_DASHBOARD_ENABLED } from "constants/configs";
import OutstandingApplicationsSection from "pages/dashboard/OutstandingApplicationsSection";

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const currentUser = useStoreSelector(selectUserInfoEntityForAuthenticatedUser);

  const screenWidthMatch = useScreenWidthMatch();
  const {
    actionItems: applicationIntentActionItems,
    applicationAccessRequestApplicantDialog,
  } = useApplicationIntentActionItems();

  const isSideNavigationCollapsed = useStoreSelector(selectIsSideNavigationCollapsed);

  const pageTitle = React.useMemo(() => {
    if (screenWidthMatch.sm) {
      return t(`Welcome to your dashboard {{firstName}}`, { firstName: currentUser?.firstName });
    }

    return t(`Welcome {{firstName}}`, { firstName: currentUser?.firstName });
  }, [currentUser?.firstName, screenWidthMatch.sm, t]);

  if (!currentUser) {
    return null;
  }

  return (
    <ThreeColumnsLayout
      data-testid="DashboardPage"
      pageHeader={<PageHeader isBackButtonVisible={false} title={pageTitle} />}
      isLeftColumnCollapsed={isSideNavigationCollapsed}
      leftColumn={<PrimarySideNavigation activeNavigationItemType={PrimarySideNavigationItemType.Dashboard} />}
      rightColumn={<ActionSection items={screenWidthMatch.md ? applicationIntentActionItems : []} />}
      rightColumnAlt={<ActionSectionAlt items={screenWidthMatch.md ? applicationIntentActionItems : []} />}
    >
      <StyledContainer>
        <AlertsSection />
        {screenWidthMatch.md && <StartApplicationSection />}
        {!FOCUS_DASHBOARD_ENABLED && <ApplicationsSection />}
        {FOCUS_DASHBOARD_ENABLED && <OutstandingApplicationsSection />}
      </StyledContainer>
      {applicationAccessRequestApplicantDialog}
    </ThreeColumnsLayout>
  );
};

const StyledContainer = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    ${theme.mixins.flexGap("24px")}
  `
);

export default DashboardPage;
