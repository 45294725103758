import React, { useRef } from "react";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import HelpCentreDialog from "components/AppHeader/HelpCentreDialog";
import { useTranslation } from "react-i18next";
import { IconButton, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import { WEB_HELP_LINK } from "constants/configs";
import { useDialog } from "hooks/useDialog";
import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { css } from "@emotion/react";

type Props = {};

const HelpNavItem: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { Menu, openMenu, closeMenu } = useMenu();
  const webHelpLinkRef = useRef<HTMLAnchorElement>(null);
  const {
    isDialogOpen: isHelpCentreDialogOpen,
    openDialog: openHelpCentreDialog,
    closeDialog: closeHelpCentreDialog,
  } = useDialog();

  const handleClickWebHelpLink = React.useCallback(() => {
    webHelpLinkRef.current?.click();
  }, []);

  return (
    <div data-testid={"HelpNavItem"}>
      <HoverTooltip title={t(`Help centre`)}>
        <StyledIconButton aria-label={t(`Help centre`)} onClick={openMenu}>
          <StyledFontIcon name={ODL_ICONS.STATUS_HELP} />
        </StyledIconButton>
      </HoverTooltip>

      <Menu data-testid="HelpNavItemPopup">
        <MenuItem
          icon={ODL_ICONS.STATUS_HELP}
          onClick={() => {
            closeMenu();
            handleClickWebHelpLink();
          }}
        >
          {t(`Online help`)}
        </MenuItem>
        <MenuItem
          icon={ODL_ICONS.TECH_HEADSET}
          onClick={() => {
            closeMenu();
            openHelpCentreDialog();
          }}
        >
          {t(`Support information`)}
        </MenuItem>
      </Menu>
      <StyledHiddenLink ref={webHelpLinkRef} href={WEB_HELP_LINK} target={"_blank"} rel="noopener" />
      <HelpCentreDialog isOpen={isHelpCentreDialogOpen} onClose={closeHelpCentreDialog} />
    </div>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    text-transform: none;
    color: ${theme.palette.text.secondary};
    width: 44px;
    height: 44px;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledHiddenLink = styled(Link)(
  ({ theme }) => css`
    display: none;
  `
);

export default HelpNavItem;
