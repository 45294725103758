import React from "react";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = {
  children: React.ReactNode;
};

const EmptyText: React.FC<Props> = React.forwardRef(({ children }, ref: React.Ref<HTMLParagraphElement>) => {
  return <StyledEmptyText ref={ref}>{children}</StyledEmptyText>;
});

const StyledEmptyText = styled("p")(
  ({ theme }) => css`
    text-align: center;
    color: ${theme.palette.text.secondary};
    font-size: 14px;
    line-height: 150%;
    padding: 24px;
  `
);

export default EmptyText;
