import React from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import AlertAction from "components/odl-v2/Alert/AlertAction";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { css } from "@emotion/react";

type Props = {
  onClickMute: () => void;
  onClickGotoProfile: () => void;
};

const ProfileIncompleteAlert: React.FC<Props> = ({ onClickMute, onClickGotoProfile }) => {
  const { t } = useTranslation();

  return (
    <OdlAlert
      severity={"warning"}
      data-testid={"ProfileIncompleteAlert"}
      action={
        <React.Fragment>
          <AlertAction severity={"warning"} onClick={onClickGotoProfile}>
            {t(`Go to Profile`)}
          </AlertAction>
          <StyledIconButton onClick={onClickMute} aria-label={t(`Close`)}>
            <StyledCloseIcon $disabled={false} name={"i-navigation-close"} />
          </StyledIconButton>
        </React.Fragment>
      }
    >
      {t(`Your personal information is incomplete.`)}
      &nbsp;
      {t(`Please add the missing details to your profile.`)}
    </OdlAlert>
  );
};

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
  `
);

const StyledCloseIcon = styled(FontIcon, { shouldForwardProp: (prop) => prop !== "$disabled" })<{
  $disabled: boolean;
}>(
  ({ theme, $disabled }) => css`
    color: ${theme.palette.objective.dark.night};
    ${$disabled &&
    `
      color: ${theme.palette.objective.light.allspice};
    `}
  `
);

export default ProfileIncompleteAlert;
