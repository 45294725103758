import React from "react";
import { ActionItemVariant, IActionItem } from "components/ActionSection/ActionItem";
import { useHistory } from "react-router";
import { useMenu } from "components/odl-v2/Menu/functions/useMenu";
import MenuItem from "components/odl-v2/Menu/MenuItem";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useScreenWidthMatch } from "hooks/useScreenWidthMatch";
import ActionItemTooltip from "components/ActionSection/ActionItemTooltip";
import { css } from "@emotion/react";

type Props = {
  items: IActionItem[];
};

const ActionSectionAlt: React.FC<Props> = ({ items }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const screenWidthMatch = useScreenWidthMatch();

  const { Menu, openMenu, closeMenu } = useMenu();

  const handleClickItem = React.useCallback(
    (item: IActionItem) => {
      if (item.variant === ActionItemVariant.Button && item.onClick) {
        closeMenu();
        item.onClick();
        return;
      }

      if (item.variant === ActionItemVariant.Link && item.to) {
        history.push(item.to);
        return;
      }
    },
    [history, closeMenu]
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledButton
        data-testid={"PopupMenuTriggerActions"}
        color="primary"
        onClick={openMenu}
        endIcon={screenWidthMatch.md ? <FontIcon name={"i-navigation-caret-down"} /> : null}
      >
        {screenWidthMatch.md ? t(`Actions`) : ""}
      </StyledButton>

      {items.map((item, idx) => item.dialog)}

      <Menu data-testid={"PopupMenu"}>
        {items.map((item) => (
          <ActionItemTooltip key={item.title} title={item.tooltip ?? ""} disableHoverListener={!item.tooltip}>
            <StyledMenuItem
              icon={item.icon}
              onClick={() => handleClickItem(item)}
              disabled={!!item.disabled}
              data-testid={item.testId}
              tooltip=""
            >
              {item.title}
            </StyledMenuItem>
          </ActionItemTooltip>
        ))}
      </Menu>
    </React.Fragment>
  );
};

const StyledButton = styled(Button)(
  ({ theme }) => css`
    text-transform: none;
  `
);

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    & .icon::before {
      color: ${theme.palette.objective.blue.main};
    }
  `
);

export default ActionSectionAlt;
