import React from "react";
import { css, Theme } from "@mui/material";
import { objectivePalette } from "styles/objectivePalette";
import { styled } from "@mui/material/styles";
import { UserAvatarType } from "components/UserAvatar/UserAvatar.types";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";

type Props = {
  rectSize: number;
  fontSize: number;
  type?: UserAvatarType;
};

const UserGroupAvatar: React.FC<Props> = ({ rectSize, fontSize, type = UserAvatarType.Solid }) => {
  return (
    <StyledUserGroupAvatar $rectSize={rectSize} $fontSize={fontSize} $type={type}>
      <FontIcon name={"i-user-events"} fontSize={fontSize} />
    </StyledUserGroupAvatar>
  );
};

const StyledUserGroupAvatar = styled("div", {
  shouldForwardProp: (prop) => !["$rectSize", "$fontSize", "$type"].includes(prop.toString()),
})(
  ({
    theme,
    $rectSize,
    $fontSize,
    $type,
  }: {
    theme: Theme;
    $rectSize: number;
    $fontSize: number;
    $type?: UserAvatarType;
  }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${$rectSize}px;
    height: ${$rectSize}px;
    border-radius: ${$rectSize}px;
    font-size: ${$fontSize}px;
    display: flex;
    background-color: ${objectivePalette.background.freshMango};
    ${$type === UserAvatarType.Solid &&
    `
      border: none;
      background-color: ${objectivePalette.background.freshMango};
    `}
    ${$type === UserAvatarType.Outlined &&
    `
      border: 2px solid ${objectivePalette.background.freshMango};
      background-color: transparent;
    `}
  `
);
export default UserGroupAvatar;
