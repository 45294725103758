import React from "react";
import { capitalize, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useFormikContext } from "formik";
import Guard from "components/Guard/Guard";
import { TextField } from "@mui/material";
import trim from "lodash/trim";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

const nanoid = require("nanoid");

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

const FormikTextInput: React.FC<Props> = ({ label, fieldName, disabled = false, required = false, onBlur }) => {
  const { touched, errors, handleChange, handleBlur: formikHandleBlur, setFieldValue, values } = useFormikContext<
    any
  >();

  const inputId = React.useMemo(() => fieldName + "-" + nanoid(5), [fieldName]);
  const hasError = touched[fieldName] && Boolean(errors[fieldName]);
  const handleBlur = React.useCallback(
    (evt: React.FocusEvent<HTMLInputElement>) => {
      // Trim text input
      setFieldValue(fieldName, trim(evt.target.value));

      // Custom blur handler
      if (onBlur) {
        onBlur(evt);
      }

      // General blur handler
      formikHandleBlur(evt);
    },
    [formikHandleBlur, onBlur, setFieldValue, fieldName]
  );

  return (
    <FormControl
      fullWidth={true}
      error={hasError}
      hiddenLabel={true}
      required={required}
      disabled={disabled}
      variant={"outlined"}
    >
      <StyledInputLabel htmlFor={inputId} shrink={false}>
        {label}
      </StyledInputLabel>

      <Guard condition={disabled}>
        <TextField id={inputId} name={fieldName} type={"text"} value={values[fieldName]} disabled={true} />
      </Guard>

      <Guard condition={!disabled}>
        <TextField
          id={inputId}
          name={fieldName}
          type={"text"}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[fieldName]}
          error={hasError}
        />
      </Guard>

      {hasError && (
        <FormHelperText error={true} data-testid={`InputError${capitalize(fieldName)}`}>
          {errors[fieldName]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikTextInput;

const StyledInputLabel = styled(InputLabel)(
  ({ theme }) => css`
    font-size: 14px;
    font-weight: 600;
    color: ${theme.palette.text.primary};
  `
);
