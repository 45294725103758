import React from "react";
import { styled } from "@mui/material/styles";
import PanelInner from "components/odl-v2/Layout/PanelInner";
import Guard from "components/Guard/Guard";
import { css } from "@emotion/react";

export type PanelProps = {
  "data-testid"?: string;
  hasInnerContainer?: boolean;
  hasError?: boolean;
  thickness?: "thin" | "normal" | "bold";
};

const Panel = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PanelProps & React.HTMLAttributes<HTMLDivElement>>
>(
  (
    {
      children,
      "data-testid": dataTestId,
      hasInnerContainer = true,
      hasError = false,
      thickness = "thin",
      ...otherProps
    },
    ref
  ) => {
    return (
      <StyledPanelContainerOuter
        data-testid={dataTestId || "Panel"}
        $hasError={hasError}
        $thickness={thickness}
        ref={ref}
        {...otherProps}
      >
        <Guard condition={hasInnerContainer} fallback={children}>
          <PanelInner>{children}</PanelInner>
        </Guard>
      </StyledPanelContainerOuter>
    );
  }
);

const StyledPanelContainerOuter = styled("div", {
  shouldForwardProp: (prop) => !["$hasError", "$thickness"].includes(prop.toString()),
})<{ $hasError: boolean; $thickness: string }>(
  ({ theme, $hasError, $thickness }) => css`
    background-color: ${theme.palette.background.default};
    ${
      $thickness === "thin" &&
      css`
        padding: 24px;
        border-radius: 16px;

        ${theme.breakpoints.down("xs")} {
          padding: 8px;
        }
      `
    }
    ${
      $thickness === "normal" &&
      css`
        padding: 36px;
        border-radius: 16px;

        ${theme.breakpoints.down("xs")} {
          padding: 12px;
        }
      `
    }
    ${
      $thickness === "bold" &&
      css`
        padding: 54px;
        border-radius: 16px;

        ${theme.breakpoints.down("xs")} {
          padding: 16px;
        }
      `
    }
    ${
      $hasError &&
      css`
        background-color: #f7e4e6;
        border: 1px dashed #ff7381;
      `
    }
  `
);

export default Panel;
