import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import FlexBox from "components/FlexBox/FlexBox";
import { styled } from "@mui/material/styles";
import {
  accessLevelMetaData,
  parseParticipantAccessLevel,
  ParticipantAccessLevel,
  rankAccessLevel,
} from "models/Participant.model";
import FontIcon from "@odl/core/components/DataDisplay/FontIcon";
import isEmpty from "lodash/isEmpty";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectAllParticipantTypeEntities } from "store/domain-data/participant-type/participantType";
import { selectCurrentParticipant, setAccessLevel } from "store/app-state/participant-buffer/participantBuffer";
import { css } from "@emotion/react";

type Props = {
  allowNoAccess?: boolean;
};

const ParticipantAccessRadios: React.FC<Props> = ({ allowNoAccess = true }) => {
  // Common
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  // Derived
  const [minimumAllowedAccessLevel, setMinimumAllowedAccessLevel] = React.useState(ParticipantAccessLevel.Unassigned);
  const accessOptions = React.useMemo(() => {
    return Object.entries(accessLevelMetaData)
      .map(([, accessLevel]) => accessLevel)
      .filter((accessLevel) => accessLevel.value !== ParticipantAccessLevel.Unassigned);
  }, []);

  const allParticipantTypes = useStoreSelector((state) => selectAllParticipantTypeEntities(state));

  const currentParticipant = useStoreSelector((state) => selectCurrentParticipant(state));

  const handleMinimumAllowedAccessLevel = React.useCallback(() => {
    // Start Unassigned and increase as we find selected participant types with higher minimum access
    let currMinimumAllowedAccess = ParticipantAccessLevel.Unassigned;

    if (!isEmpty(currentParticipant.participantTypes)) {
      // Determine highest minimum access level amongst the selected participant types
      currentParticipant.participantTypes.forEach((selectedType) => {
        const selectedTypeMinAccess =
          allParticipantTypes.find((type) => type.name === selectedType.name)?.minimumAccessLevel ||
          ParticipantAccessLevel.Unassigned;

        if (rankAccessLevel(currMinimumAllowedAccess) < rankAccessLevel(selectedTypeMinAccess)) {
          currMinimumAllowedAccess = selectedTypeMinAccess;
        }
      });
    }

    setMinimumAllowedAccessLevel(currMinimumAllowedAccess);
  }, [allParticipantTypes, currentParticipant.participantTypes]);

  const handleChangeParticipantAccess = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      dispatch(setAccessLevel(event.target.value as ParticipantAccessLevel));
    },
    [dispatch]
  );

  const checkAccessOptionDisabled = React.useCallback(
    (optionValue: ParticipantAccessLevel | undefined) => {
      const optionAccessLevel = parseParticipantAccessLevel(optionValue);
      if (optionAccessLevel === ParticipantAccessLevel.None && !allowNoAccess) {
        return true;
      }

      return rankAccessLevel(optionAccessLevel) < rankAccessLevel(minimumAllowedAccessLevel);
    },
    [minimumAllowedAccessLevel, allowNoAccess]
  );

  // Effects
  React.useEffect(() => {
    handleMinimumAllowedAccessLevel();
  }, [allParticipantTypes, currentParticipant.participantTypes, handleMinimumAllowedAccessLevel]);

  return (
    <FlexBox direction="column" paddingTop={4} spacing={2}>
      <StyledHeader>
        {t(`Select the level of access to this application`)}
        <StyledAsterisk>*</StyledAsterisk>
      </StyledHeader>
      <RadioGroup
        value={currentParticipant.accessLevel}
        onChange={handleChangeParticipantAccess}
        id={"access-level-radio-group"}
      >
        {accessOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<StyledRadio size={"small"} disabled={checkAccessOptionDisabled(option.value)} />}
            label={
              <FlexBox spacing={2}>
                <FontIcon name={option.icon} />
                {option.title}
              </FlexBox>
            }
          />
        ))}
      </RadioGroup>
    </FlexBox>
  );
};

const StyledRadio = styled(Radio)(
  ({ theme }) => css`
    color: ${theme.palette.objective.dark.neutral};
    &.MuiRadio-colorSecondary.Mui-checked {
      color: ${theme.palette.objective.dark.fiordland};
    }
  `
);

const StyledHeader = styled("span")(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 16px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledAsterisk = styled("span")(
  ({ theme }) => css`
    font-size: 16px;
    padding-left: 4px;
    color: ${theme.palette.objective.red.main};
  `
);

export default ParticipantAccessRadios;
