import React from "react";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";
import { ReactComponent as WarningIconAlt } from "assets/images/status-warning-alt.svg";

type Props = {};

const AlertIcon: React.FC<Props> = ({ ...otherProps }) => {
  return <StyledAlertIcon role={"alert"} name={"i-status-warning-alt"} fontSize={18} {...otherProps} />;
};

export default AlertIcon;

const StyledAlertIcon = styled(WarningIconAlt)(
  ({ theme }) => css`
    width: 18px;
    height: 18px;
    path {
      fill: ${theme.palette.objective.red.main};
    }
  `
);
