import React from "react";
import { styled } from "@mui/material/styles";
import pdfIcon from "assets/images/pdf-icon.svg";
import errorIcon from "assets/images/error-file-icon.svg";
import Guard from "components/Guard/Guard";
import { CircularProgress } from "@mui/material";
import { DocumentStatus } from "models/DocumentStatus.model";
import { css } from "@emotion/react";

type Props = {
  size?: number;
  status?: DocumentStatus;
};

const DocumentIcon: React.FC<Props> = ({ size = 44, status = DocumentStatus.Completed }) => {
  return (
    <StyledIconContainer
      data-testid="DocumentIcon"
      $dimmed={![DocumentStatus.Error, DocumentStatus.Completed].includes(status)}
      $size={size}
    >
      <Guard condition={status === DocumentStatus.Uploading}>
        <CircularProgress size={size} />
      </Guard>

      <Guard condition={status === DocumentStatus.Completed}>
        <StyledImg src={pdfIcon} alt={"Document Icon"} />
      </Guard>

      <Guard condition={[DocumentStatus.Pending, DocumentStatus.Uploaded].includes(status)}>
        <StyledImg src={pdfIcon} alt={"Document Icon"} />
      </Guard>

      <Guard condition={status === DocumentStatus.Error}>
        <StyledImg src={errorIcon} alt={"Document Icon"} />
      </Guard>
    </StyledIconContainer>
  );
};

const StyledImg = styled("img")(
  ({ theme }) => css`
    width: 100%;
    height: auto;
  `
);

const StyledIconContainer = styled("div")<{ $dimmed: boolean; $size: number }>(
  ({ theme, $dimmed, $size }) => css`
    position: relative;
    overflow: hidden;
    opacity: ${$dimmed ? 0.6 : 1};
    width: ${$size}px;
    height: ${$size}px;
  `
);

export default DocumentIcon;
