import React from "react";
import { Formik } from "formik";
import Box from "components/Box/Box";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import { useTranslation } from "react-i18next";
import {
  loginWithCredentials,
  resendVerificationEmail,
  selectIsAuthenticating,
} from "store/app-state/authentication/authentication";
import Spacer from "components/Spacer/Spacer";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import FlexBox from "components/FlexBox/FlexBox";
import AuthenticationNavigationTabs from "pages/authentication/AuthenticationNavigationTabs";
import { AuthenticationType, LoginFormValues } from "pages/authentication/AuthenticationPage.types";
import Guard from "components/Guard/Guard";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import { unwrapResult } from "@reduxjs/toolkit";
import { styled } from "@mui/material/styles";
import LinkAction from "pages/authentication/LinkAction";
import FormikPasswordInput from "components/FormikPasswordInput/FormikPasswordInput";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import FormikSubmitButton from "components/FormikSubmitButton/FormikSubmitButton";
import { Button, Divider, Link } from "@mui/material";
import { PRIVACY_POLICY_LINK, RESEND_VERIFICATION_EMAIL_ENABLED, TERMS_OF_SERVICE_LINK } from "constants/configs";
import { ServiceError, ServiceErrorCode } from "services/ServiceError";
import { selectIsLoadingThunk } from "store/app-state/loading/loading";
import { usePostHog } from "posthog-js/react";
import { hashString } from "utils/hashString";
import { css } from "@emotion/react";

type Props = {};

const defaultFormValues: LoginFormValues = {
  account: "",
  password: "",
};

const loginFormSchema = yup.object().shape({
  account: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
  password: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
});

const LoginSection: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();
  const [loginError, setLoginError] = React.useState<Error | null>(null);
  const [resendVerificationEmailErrorMessage, setResendVerificationEmailErrorMessage] = React.useState("");
  const [resendVerificationEmailInfoMessage, setResendVerificationEmailInfoMessage] = React.useState("");
  const lastSubmittedEmailRef = React.useRef("");
  const isAuthenticating = useStoreSelector(selectIsAuthenticating);
  const isResendingVerificationEmail = useStoreSelector((state) =>
    selectIsLoadingThunk(state, resendVerificationEmail)
  );

  const shouldShowResendVerificationEmail = React.useCallback(
    (formValues: LoginFormValues) => {
      if (!RESEND_VERIFICATION_EMAIL_ENABLED) {
        return false;
      }

      if (
        !loginError ||
        !ServiceError.isServiceError(loginError) ||
        loginError.code !== ServiceErrorCode.UserNotActive
      ) {
        return false;
      }

      return formValues.account === lastSubmittedEmailRef.current;
    },
    [loginError, lastSubmittedEmailRef]
  );

  const handleLoginFormSubmit = React.useCallback(
    async (formValues: LoginFormValues) => {
      setResendVerificationEmailInfoMessage("");
      lastSubmittedEmailRef.current = formValues.account;
      try {
        await dispatch(
          loginWithCredentials({
            username: formValues.account,
            password: formValues.password,
          })
        ).then(unwrapResult);
        posthog?.identify(hashString(formValues.account), {
          email: hashString(formValues.account),
        });
        posthog?.capture("user signed-in");
      } catch (e) {
        setLoginError(e);
      }
    },
    [dispatch, posthog]
  );

  const handleClickResendVerificationEmail = React.useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      try {
        await dispatch(resendVerificationEmail(lastSubmittedEmailRef.current)).then(unwrapResult);
        setLoginError(null);
        setResendVerificationEmailErrorMessage("");
        setResendVerificationEmailInfoMessage(t("A verification email will be sent to this email address."));
        posthog?.capture("user reset-password");
      } catch (e) {
        setResendVerificationEmailInfoMessage("");
        setResendVerificationEmailErrorMessage(t("We cannot send verification email. Please try again later."));
      }
    },
    [dispatch, posthog, t]
  );

  const handleFormChange = React.useCallback(() => {
    // Clear error after value changed
    setLoginError(null);
    setResendVerificationEmailErrorMessage("");
    setResendVerificationEmailInfoMessage("");
  }, []);

  return (
    <Box data-testid="LoginSection">
      <AuthenticationNavigationTabs activeAuthenticationType={AuthenticationType.Login} />

      <Spacer y={4} />

      <FlexBox justifyContent="center">
        <StyledTitle>{t(`Sign in to your account to continue your building application.`)}</StyledTitle>
      </FlexBox>

      <Spacer y={8} />

      <Formik<LoginFormValues>
        initialValues={defaultFormValues}
        validationSchema={loginFormSchema}
        onSubmit={handleLoginFormSubmit}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <FlexBox spacing={6} direction={"column"}>
              <FormikTextInput label={t(`Your email`)} fieldName="account" />
              <FormikPasswordInput label={t(`Password`)} fieldName="password" />
              <Guard condition={loginError && !resendVerificationEmailErrorMessage}>
                <OdlAlert severity={"error"} data-testid="LoginErrorAlert">
                  {t("Your login attempt has failed. Please try again.")}
                </OdlAlert>
              </Guard>
              <Guard condition={resendVerificationEmailErrorMessage}>
                <OdlAlert severity={"error"} data-testid="ResendVerificationEmailErrorAlert">
                  {resendVerificationEmailErrorMessage}
                </OdlAlert>
              </Guard>
              <Guard condition={resendVerificationEmailInfoMessage}>
                <OdlAlert severity={"info"} data-testid="ResendVerificationEmailInfoAlert">
                  {resendVerificationEmailInfoMessage}
                </OdlAlert>
              </Guard>
            </FlexBox>

            <Spacer y={6} />

            <FlexBox justifyContent="flex-end">
              <Guard condition={!shouldShowResendVerificationEmail(values)}>
                <FormikSubmitButton disabled={isAuthenticating} />
              </Guard>
              <Guard condition={shouldShowResendVerificationEmail(values)}>
                <Button
                  variant="contained"
                  type="button"
                  size={"large"}
                  onClick={handleClickResendVerificationEmail}
                  disabled={isResendingVerificationEmail}
                >
                  {t("Resend email")}
                </Button>
              </Guard>
            </FlexBox>

            <Spacer y={6} />

            <FlexBox spacing={4} direction={"column"} alignItems={"flex-start"}>
              <LinkAction
                to="/registration"
                hint={t(`Don't have an account yet?`)}
                action={t(`Create one here`)}
                linkTestId="CreateAccountLink"
              />
              <LinkAction
                to="/forgot-password"
                hint={t(`Forgot your password?`)}
                action={t(`Reset it here`)}
                linkTestId="ForgotPasswordLink"
              />
            </FlexBox>

            <Spacer y={6} />
            <Divider />
            <Spacer y={6} />

            <FlexBox spacing={4} alignItems={"center"}>
              <Link
                variant="body2"
                href={TERMS_OF_SERVICE_LINK}
                color={"textPrimary"}
                target="_blank"
                underline="hover"
                rel="noopener noreferrer"
              >
                {t("Terms of Use")}
              </Link>
              <Link
                variant="body2"
                href={PRIVACY_POLICY_LINK}
                color={"textPrimary"}
                target="_blank"
                underline="hover"
                rel="noopener noreferrer"
              >
                {t("Privacy Policy")}
              </Link>
              <Link
                variant="body2"
                href={"https://support.objective.com/"}
                color={"textPrimary"}
                target="_blank"
                underline="hover"
                rel="noopener noreferrer"
              >
                {t("Get Support")}
              </Link>
            </FlexBox>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const StyledTitle = styled("div")(
  () => css`
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    width: 312px;
  `
);

export default LoginSection;
