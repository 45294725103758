import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import CriticalConfirmationDialog from "components/CriticalConfirmationDialog/CriticalConfirmationDialog";
import { Box } from "@mui/material";
import UserAvatar from "components/UserAvatar/UserAvatar";
import {
  getParticipantAndBusinessName,
  getParticipantInitial,
  getParticipantInitialFromNames,
  getParticipantNameFromNames,
} from "models/Participant.model";
import { useStoreSelector } from "store/hooks";
import { selectParticipantTypeOccurrences } from "store/domain-data/participant-type/participantType";
import { css } from "@emotion/react";

type Props = {
  applicationId: number;
  participantFirstName: string;
  participantLastName: string;
  participantTypeName: string;
  participantTypeDisplayName: string;
  businessName: string;
  isOpen: boolean;
  onClose: (confirmed: boolean) => void;
};

const ParticipantChangeTypeConfirmationDialog: React.FC<Props> = ({
  applicationId,
  participantFirstName,
  participantLastName,
  participantTypeName,
  participantTypeDisplayName,
  businessName,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const participantTypeOccurrences = useStoreSelector((state) =>
    selectParticipantTypeOccurrences(state, {
      applicationId,
      participantTypeName: participantTypeName,
    })
  );

  const participantName = getParticipantNameFromNames(participantFirstName, participantLastName);
  const participantDetailLine = [participantName, businessName].filter((str) => Boolean(str)).join(" - ");

  return (
    <CriticalConfirmationDialog
      title={t(`Role change`)}
      content={
        <StyledContentWrapper>
          <StyledContentTitle id="ConfirmDialog">
            <Trans
              i18nKey="If you continue the role of <strong>{{name}}</strong> will be reassigned"
              defaults="If you continue the role of <strong>{{name}}</strong> will be reassigned"
              components={{
                strong: <strong />,
              }}
              tOptions={{
                name: participantTypeDisplayName,
              }}
            />
          </StyledContentTitle>
          <StyledRoleTitleRow>{t(`From`)}</StyledRoleTitleRow>
          {participantTypeOccurrences &&
            participantTypeOccurrences.participants.map((otherParticipant) => (
              <StyledUser key={otherParticipant.id}>
                <StyledUserAvatar initials={getParticipantInitial(otherParticipant)} rectSize={44} fontSize={18} />
                <StyledUserDescription>
                  <StyledStrongText>{getParticipantAndBusinessName(otherParticipant)}</StyledStrongText>
                </StyledUserDescription>
              </StyledUser>
            ))}
          <StyledRoleTitleRow>{t(`To`)}</StyledRoleTitleRow>
          <StyledUser>
            <StyledUserAvatar
              initials={getParticipantInitialFromNames(participantFirstName, participantLastName)}
              rectSize={44}
              fontSize={18}
            />
            <StyledUserDescription>
              <StyledStrongText>{participantDetailLine}</StyledStrongText>
            </StyledUserDescription>
          </StyledUser>
        </StyledContentWrapper>
      }
      isOpen={isOpen}
      onClose={onClose}
      data-testid={"ConfirmRoleChangeDialog"}
    />
  );
};

const StyledRoleTitleRow = styled(Box)(
  ({ theme }) => css`
    padding: 0 24px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 700;
    background: ${theme.palette.objective.light.day};
  `
);

const StyledStrongText = styled("span")(
  ({ theme }) => css`
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledContentWrapper = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("16px")}
    padding-top:8px;
  `
);

const StyledContentTitle = styled("div")(
  ({ theme }) => css`
    font-size: 16px;
    line-height: 150%;
    color: ${theme.palette.objective.dark.night};
    padding: 0 24px;
  `
);

const StyledUser = styled("div")(
  ({ theme }) => css`
    align-items: center;
    ${theme.mixins.flexGap("16px")}
    display:flex;
    padding: 0 24px;
  `
);

const StyledUserDescription = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
  `
);

const StyledUserAvatar = styled(UserAvatar)(({ theme }) => ``);

export default ParticipantChangeTypeConfirmationDialog;
