import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

const StyledAccordionTitle = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-grow: 1;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: ${theme.palette.text.primary};
  `
);

export default StyledAccordionTitle;
