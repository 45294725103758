import React from "react";
import { styled } from "@mui/material/styles";
import { css } from "@emotion/react";

type Props = {
  header?: React.ReactNode;
  "data-testid"?: string;
};

const Section: React.FC<Props> = ({ header, children, "data-testid": dataTestId }) => {
  return (
    <StyledSectionContainer data-testid={dataTestId || "Section"}>
      {header}
      {children}
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled("section")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("32px")}

    ${theme.breakpoints.down("sm")} {
      ${theme.mixins.flexGap("16px")}
    }
  `
);

export default Section;
