import React, { SyntheticEvent } from "react";
import { CardProps, Slide, SlideProps, Card, CardActionArea, CardContent } from "@mui/material";
import FlexBox from "components/FlexBox/FlexBox";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import {
  selectApplicationEntityById,
  selectCanEditApplicationFormForApplication,
  selectCanUserManageApplicationById,
  selectIsApplicationRequiresAttention,
  updateApplicationUserPreferences,
} from "store/domain-data/application/application";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ApplicationActionsMenu from "components/ApplicationCard/ApplicationActionsMenu";
import Guard from "components/Guard/Guard";
import { useDeleteApplicationDialog } from "components/DeleteApplicationDialog/functions/useDeleteApplicationDialog";
import { selectJurisdictionFormEntityById } from "store/domain-data/jurisdiction-form/jurisdictionForm";
import { ApplicationUtil } from "models/Application.model";
import ApplicationCardIcon from "components/ApplicationCard/ApplicationCardIcon";
import { unwrapResult } from "@reduxjs/toolkit";
import { useToast } from "hooks/useToast";
import { selectCanManageParticipantNotificationPreferenceForApplicationId } from "store/domain-data/participant/participant";
import ApplicationWorkflowBadges from "components/ApplicationWorkflow/ApplicationWorkflowBadges";
import applicationPaymentIcon from "assets/images/application-payment-icon.svg";
import ApplicationNotificationLevelIcon from "components/ApplicationNotificationLevelIcon/ApplicationNotificationLevelIcon";
import { useDuplicateApplicationDialog } from "components/DuplicateApplicationDialog/functions/useDuplicateApplicationDialog";
import { useNewApplicationTemplateDialog } from "components/NewApplicationTemplateDialog/functions/useNewApplicationTemplateDialog";
import { useApplicationFormReviewDialog } from "components/ApplicationFormReviewDialog/functions/useApplicationFormReviewDialog";
import { useNotificationSubscriptionLevelDialog } from "components/NotificationSubscriptionLevelDialog/functions/useNotificationSubscriptionLevelDialog";
import { formatDateStr } from "utils/formatDateStr";
import { DATE_FORMATS } from "constants/configs";
import { selectAuthorityEntityById } from "store/domain-data/authority/authority";
import { usePreviewApplicationDocument } from "components/DocumentPreviewDialog/usePreviewApplicationDocument";
import { fetchApplicationDocument } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { useDownloadApplicationDocument } from "hooks/useDownloadApplicationDocument";

import FontIcon, { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";
import { useNavigate } from "components/RouteController/functions/useNavigate";
import { urlHelper } from "components/RouteController/functions/urlHelper";
import { css } from "@emotion/react";

type Props = CardProps & {
  applicationId: number;
  isCardClickable?: boolean;
};

const slideTimeout: SlideProps["timeout"] = {
  appear: 0,
  enter: 0,
  exit: 300,
};

const ApplicationCard: React.FC<Props> = ({ applicationId, isCardClickable = true, ...otherProps }) => {
  // Common
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { toastError } = useToast();

  // Redux & custom hooks
  const application = useStoreSelector((state) => selectApplicationEntityById(state, applicationId));

  const authority = useStoreSelector((state) => selectAuthorityEntityById(state, application?.authorityId || 0));
  const canEditApplicationFormForApplication = useStoreSelector((state) =>
    selectCanEditApplicationFormForApplication(state, applicationId)
  );

  const jurisdictionForm = useStoreSelector((state) =>
    selectJurisdictionFormEntityById(state, application?.formId || 0)
  );
  const canUpdateNotificationLevel = useStoreSelector((state) =>
    selectCanManageParticipantNotificationPreferenceForApplicationId(state, applicationId)
  );
  const { duplicateApplicationDialog, openDuplicateApplicationDialog } = useDuplicateApplicationDialog(applicationId);
  const { newApplicationTemplateDialog, openNewApplicationTemplateDialog } = useNewApplicationTemplateDialog(
    applicationId
  );

  const {
    deleteApplicationDialog,
    openDeleteApplicationDialog,
    isDeleted,
    deleteApplication,
  } = useDeleteApplicationDialog(applicationId, {
    deleteWhenDialogClose: false,
  });

  const { downloadApplicationDocument } = useDownloadApplicationDocument(applicationId);

  const { applicationFormReviewDialog, openApplicationFormReviewDialog } = useApplicationFormReviewDialog(
    applicationId
  );

  const {
    notificationSubscriptionLevelDialog,
    openNotificationSubscriptionLevelDialog,
  } = useNotificationSubscriptionLevelDialog(applicationId);

  const isDeleteApplicationActionVisible = useStoreSelector((state) =>
    selectCanUserManageApplicationById(state, applicationId)
  );

  const { previewApplicationDocument, applicationDocumentPreviewDialog } = usePreviewApplicationDocument(applicationId);

  // Only show action menus when there is at least 1 possible action
  const isApplicationActionMenuVisible = isDeleteApplicationActionVisible || canUpdateNotificationLevel;

  // Derived data
  const isDraft = application?.status === ApplicationStatus.Draft;

  const isApplicationRequiresAttention = useStoreSelector((state) =>
    selectIsApplicationRequiresAttention(state, applicationId)
  );

  const addressText = React.useMemo(() => {
    if (!application) {
      return "";
    }

    if (authority) {
      return `${application.address.fullAddress} • ${authority.displayName}`;
    }
    return application.address.fullAddress;
  }, [application, authority]);

  const { navigateTo } = useNavigate();

  // Callbacks
  const handleClickCard = React.useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (!isCardClickable) {
        return;
      }

      if (isDraft) {
        navigateTo(urlHelper.getUrlForApplicationFormPage({ applicationId }));
        return;
      }

      navigateTo(urlHelper.getUrlForApplicationDetailsPage({ applicationId }));
    },
    [isCardClickable, isDraft, navigateTo, applicationId]
  );

  const handleArchiveApplication = React.useCallback(async () => {
    const isHidden = !application?.isHidden;
    try {
      await dispatch(updateApplicationUserPreferences({ applicationId, isHidden })).then(unwrapResult);
    } catch (e) {
      toastError(t(`Failed to archive application`));
    }
  }, [application, applicationId, dispatch, t, toastError]);

  const handlePreviewApplicationFormPdf = React.useCallback(async () => {
    const documentName = application?.applicationFormPdf;
    if (!documentName) {
      return;
    }

    try {
      await dispatch(fetchApplicationDocument({ applicationId, documentName })).then(unwrapResult);
      previewApplicationDocument(documentName);
    } catch (e) {
      toastError(t(`Failed to preview application document`));
    }
  }, [application, applicationId, previewApplicationDocument, dispatch, t, toastError]);

  const handleDownloadApplicationFormPdf = React.useCallback(async () => {
    const documentName = application?.applicationFormPdf;
    if (!documentName) {
      return;
    }
    await downloadApplicationDocument(documentName);
  }, [downloadApplicationDocument, application]);

  const handleEditApplicationForm = React.useCallback(() => {
    history.push(`/application-form/${applicationId}`);
  }, [history, applicationId]);

  // Guard
  if (!application) {
    return null;
  }

  return (
    <React.Fragment>
      <Slide
        direction={"left"}
        in={!isDeleted}
        onExited={deleteApplication}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={slideTimeout}
      >
        <Card
          data-testid={`ApplicationCard-${application.id}`}
          data-application-status={application.status}
          onClick={handleClickCard}
          {...otherProps}
        >
          <CardActionArea classes={{ root: isCardClickable ? "" : "clickDisabled" }}>
            <StyledCardContent>
              <StyledApplicationCardIcon
                recordType={application.recordType}
                status={application.status}
                showAttentionIcon={isApplicationRequiresAttention}
              />
              <StyledApplicationPrimaryInformation>
                <StyledApplicationTitle>{jurisdictionForm?.displayName || ""}</StyledApplicationTitle>

                <FlexBox spacing={2} alignItems={"center"}>
                  <StyledApplicationID>{ApplicationUtil.getDisplayId(application)}</StyledApplicationID>
                  <ApplicationNotificationLevelIcon applicationId={applicationId} />
                </FlexBox>

                <StyledApplicationAddress>{addressText}</StyledApplicationAddress>
                <StyledModifiedDate>
                  {t(`Modified on {{date}}`, { date: formatDateStr(application.modifiedDate, DATE_FORMATS.FULL_DATE) })}
                </StyledModifiedDate>
              </StyledApplicationPrimaryInformation>
              <StyledApplicationSecondaryInformation>
                <Guard condition={isApplicationActionMenuVisible}>
                  <ApplicationActionsMenu
                    applicationId={applicationId}
                    onClickDownloadApplicationFormPdf={handleDownloadApplicationFormPdf}
                    onClickDeleteApplication={openDeleteApplicationDialog}
                    onClickPreviewApplicationFormPdf={handlePreviewApplicationFormPdf}
                    onClickDuplicateApplication={openDuplicateApplicationDialog}
                    onClickUpdateNotificationLevel={openNotificationSubscriptionLevelDialog}
                    onClickSaveAsTemplate={openNewApplicationTemplateDialog}
                    onClickArchiveApplication={handleArchiveApplication}
                    onClickViewSubmittedForm={openApplicationFormReviewDialog}
                    onClickEditApplicationForm={handleEditApplicationForm}
                  />
                </Guard>

                <FlexBox alignItems={"flex-end"} spacing={2}>
                  <Guard condition={!canEditApplicationFormForApplication && !isDraft}>
                    <StyledFontIcon name={ODL_ICONS.TOGGLE_LOCKED} fontSize={14} />
                  </Guard>
                  <Guard condition={canEditApplicationFormForApplication && !isDraft}>
                    <FontIcon name={"i-toggle-unlocked"} data-testid={"lock-open-icon"} />
                  </Guard>
                  <Guard condition={application.hasOutstandingPayments}>
                    <StyledImage
                      src={applicationPaymentIcon}
                      alt={"Application has outstanding payments"}
                      data-testid={"ApplicationOutstandingPaymentIcon"}
                    />
                  </Guard>

                  <ApplicationWorkflowBadges applicationId={applicationId} />
                </FlexBox>
              </StyledApplicationSecondaryInformation>
            </StyledCardContent>
          </CardActionArea>
        </Card>
      </Slide>

      {duplicateApplicationDialog}
      {deleteApplicationDialog}
      {newApplicationTemplateDialog}
      {applicationFormReviewDialog}
      {applicationDocumentPreviewDialog}
      {notificationSubscriptionLevelDialog}
    </React.Fragment>
  );
};

const StyledImage = styled("img")(
  ({ theme }) => css`
    max-width: 100%;
    max-height: 100%;
  `
);

const StyledApplicationTitle = styled("div")(
  ({ theme }) => css`
    font-size: 12px;
    line-height: 15.6px;
    color: ${theme.palette.text.tertiary};
  `
);

const StyledApplicationID = styled("div")(
  ({ theme }) => css`
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.text.primary};
  `
);

const StyledApplicationAddress = styled("div")(
  ({ theme }) => css`
    overflow: hidden;
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.palette.text.secondary};
    margin-bottom: 4px;
  `
);

const StyledModifiedDate = styled("div")(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.text.tertiary};

    line-height: 1.4;
  `
);

const StyledFontIcon = styled(FontIcon)(
  ({ theme }) => css`
    line-height: 18px;
    color: ${theme.palette.objective.dark.fiordland};
  `
);

const StyledCardContent = styled(CardContent)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 12px 24px !important;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }
  `
);

const StyledApplicationCardIcon = styled(ApplicationCardIcon)(
  ({ theme }) => css`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  `
);

const StyledApplicationPrimaryInformation = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 110px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: flex-start;
  `
);

const StyledApplicationSecondaryInformation = styled("div")(
  ({ theme }) => css`
    min-height: 44px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("sm")} {
      flex-grow: 1;
    }
  `
);

export default React.memo(ApplicationCard);
