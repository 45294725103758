import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useTheme } from "@mui/material";
import { ResetPasswordFormValues } from "pages/authentication/AuthenticationPage.types";
import { Formik } from "formik";
import Box from "components/Box/Box";
import FlexBox from "components/FlexBox/FlexBox";
import Spacer from "components/Spacer/Spacer";
import FormikTextInput from "components/FormikTextInput/FormikTextInput";
import { Link as RouterLink } from "react-router-dom";
import Guard from "components/Guard/Guard";
import { ServiceError } from "services/ServiceError";
import { resetPassword } from "store/app-state/authentication/authentication";
import { selectIsLoadingThunk } from "store/app-state/loading/loading";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { styled } from "@mui/material/styles";
import OdlAlert from "components/odl-v2/Alert/OdlAlert";
import FormikPasswordInput from "components/FormikPasswordInput/FormikPasswordInput";
import * as yup from "yup";
import { formValidationUtils } from "utils/formValidationUtils";
import FormikSubmitButton from "components/FormikSubmitButton/FormikSubmitButton";
import { hashString } from "utils/hashString";
import { usePostHog } from "posthog-js/react";

type Props = {
  email: string;
};

const resetPasswordFormSchema = yup.object().shape({
  email: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForEmail(), formValidationUtils.getErrorMessageForEmail()),
  code: yup.string().required(formValidationUtils.getErrorMessageForRequiredField()),
  password: yup
    .string()
    .required(formValidationUtils.getErrorMessageForRequiredField())
    .matches(formValidationUtils.getRegexForPassword(), formValidationUtils.getErrorMessageForPassword()),
});

const ResetPasswordSection: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useStoreDispatch();
  const posthog = usePostHog();

  const isResettingPassword = useStoreSelector((state) => selectIsLoadingThunk(state, resetPassword));
  const [resetPasswordServiceError, setResetPasswordServiceError] = React.useState<ServiceError | null>(null);
  const [passwordReset, setPasswordReset] = React.useState(false);

  const defaultResetPasswordValues: ResetPasswordFormValues = React.useMemo(() => {
    return {
      email,
      code: "",
      password: "",
      confirmPassword: "",
    };
  }, [email]);

  const handleSubmit = React.useCallback(
    async (formValues: ResetPasswordFormValues) => {
      try {
        await dispatch(
          resetPassword({
            email: formValues.email,
            code: formValues.code,
            password: formValues.password,
          })
        ).then(unwrapResult);

        setPasswordReset(true);
        posthog?.identify(hashString(formValues.email), {
          email: hashString(formValues.email),
        });
        posthog?.capture("user reset password");
      } catch (e) {
        setResetPasswordServiceError(e);
      }
    },
    [dispatch, posthog]
  );
  const handleFormChange = React.useCallback(() => {
    // Clear error after value changed
    setResetPasswordServiceError(null);
  }, []);

  return (
    <Box data-testid="ResetPasswordSection">
      <Guard condition={!passwordReset}>
        <FlexBox paddingTop={4} spacing={4} direction={"column"}>
          <StyledTitle>{t(`Forgot your password`)}</StyledTitle>
          <Box fontWeight={300} fontSize={16} lineHeight={1.2} textAlign={"center"}>
            {t(`Please enter the code you received in your email and your new password below`)}
          </Box>
        </FlexBox>
        <Spacer y={6} />
        <Formik<ResetPasswordFormValues>
          initialValues={defaultResetPasswordValues}
          validationSchema={resetPasswordFormSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} onChange={handleFormChange}>
              <FlexBox spacing={6} direction={"column"}>
                <FormikTextInput label={"Email"} fieldName={"email"} disabled={true} />
                <FormikTextInput label={"Verification Code"} fieldName={"code"} />
                <FormikPasswordInput label={"New Password"} fieldName={"password"} />
                <Guard condition={resetPasswordServiceError}>
                  <OdlAlert severity={"error"}>{resetPasswordServiceError?.message}</OdlAlert>
                </Guard>
              </FlexBox>

              <Spacer y={4} />
              <FlexBox justifyContent={"flex-end"}>
                <FormikSubmitButton title="Reset my password" disabled={isResettingPassword} />
              </FlexBox>
              <Spacer y={6} />
              <FlexBox spacing={4} direction={"column"} fontWeight={500} color={theme.palette.text.secondary}>
                <FlexBox spacing={1}>
                  {t(`Not your email?`)}
                  <Link component={RouterLink} to="/forgot-password">
                    {t(`Change email here`)}
                  </Link>
                </FlexBox>

                <FlexBox spacing={1}>
                  {t(`Remembered your password?`)}
                  <Link component={RouterLink} to="/">
                    {t(`Login here`)}
                  </Link>
                </FlexBox>
              </FlexBox>
            </form>
          )}
        </Formik>
      </Guard>
      <Guard condition={passwordReset}>
        <FlexBox direction={"column"} alignItems="center" spacing={4} paddingTop={6}>
          <StyledTitle data-testid="ResetPasswordSuccessMessage">
            {t(`Your password has been changed successfully`)}
          </StyledTitle>

          <FlexBox spacing={1} fontSize={18}>
            <Link component={RouterLink} to="/">
              {t(`Click here`)}
            </Link>
            <Box fontWeight={500} color={theme.palette.text.secondary}>
              {t(`to login`)}
            </Box>
          </FlexBox>
        </FlexBox>
      </Guard>
    </Box>
  );
};
const StyledTitle = styled("div")(
  () => `
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
  `
);

export default ResetPasswordSection;
