import React from "react";
import FlexBox from "components/FlexBox/FlexBox";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Dialog from "components/odl-v2/Dialog/Dialog";
import DialogContent from "components/odl-v2/Dialog/DialogContent";
import DialogActions from "components/odl-v2/Dialog/DialogActions";
import DialogActionCancel from "components/odl-v2/Dialog/DialogActionCancel";
import DialogActionGroup from "components/odl-v2/Dialog/DialogActionGroup";
import DialogActionPrimary from "components/odl-v2/Dialog/DialogActionPrimary";
import { css } from "@emotion/react";

type Props = {
  onClickCancel: () => any;
  onClickRename: () => any;
  duplicatedFileNames: string[];
};

const ApplicationDocumentDuplicationDialog: React.FC<Props> = ({
  onClickCancel,
  onClickRename,
  duplicatedFileNames,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={true}
      data-testid={"ApplicationDocumentDuplicationDialog"}
      title={t(`Rename files with the same name?`)}
      onClose={onClickCancel}
      maxWidth={false}
      scroll="body"
    >
      <DialogContent>
        <StyledDialogContent>
          <FlexBox direction={"column"}>
            <div>{t(`The following filenames already exist for the files you are trying to upload:`)}</div>
            <br />
            {duplicatedFileNames.map((fileName) => (
              <div key={fileName}>
                <strong>{fileName}</strong>
              </div>
            ))}
            <br />
            <div>{t(`Do you want to rename these files?`)}</div>
          </FlexBox>
        </StyledDialogContent>
      </DialogContent>

      <DialogActions>
        <DialogActionCancel data-testid="CancelButton" onClick={onClickCancel}>
          {t(`Cancel`)}
        </DialogActionCancel>
        <DialogActionGroup>
          <DialogActionPrimary data-testid="KeepAndRenameButton" onClick={onClickRename}>
            {t(`Keep & Rename`)}
          </DialogActionPrimary>
        </DialogActionGroup>
      </DialogActions>
    </Dialog>
  );
};

const StyledDialogContent = styled("div")(
  ({ theme }) => css`
    line-height: 1.2em;
    padding: 4px;
    display: flex;
    flex-direction: column;
    ${theme.mixins.flexGap("8px")}
  `
);

export default ApplicationDocumentDuplicationDialog;
