import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import { IAddressEntity } from "./Address.model";
import { enumFromValue } from "utils/enumFromValue";
import { IQualificationEntity } from "models/Qualification.model";
import { ODL_ICONS } from "@odl/core/components/DataDisplay/FontIcon";

export type IParticipantPersonalData = {
  loginUserId: number;
  firstName: string;
  lastName: string;
  groupList: string;
  groupName: string;
  organisation: string;
  email: string;
  phoneCountryCode: string;
  phone: string;
  address: IAddressEntity;
};

export type IParticipantTypeEntity = {
  id: number;
  name: string;
  displayName: string;
  displayOrder: number;
  required: boolean;
};

export enum ParticipantTypeExtraNumber {
  Unrestricted = "Unrestricted",
  ExactlyOne = "ExactlyOne",
  MinimumOne = "MinimumOne",
  MaximumOne = "MaximumOne",
}

// TODO: Should not be named with Participant anymore
export enum ParticipantAccessLevel {
  Unassigned = "",
  None = "None",
  ReadOnly = "ReadOnly",
  Edit = "Edit",
  Manage = "Manage",
}

export type IAccessLevel = {
  title: string;
  description: string;
  icon: typeof ODL_ICONS[keyof typeof ODL_ICONS];
  value?: ParticipantAccessLevel;
};

export type IParticipantTypeApplicationEntity = IParticipantTypeEntity & {
  mandatory?: boolean;
  mustInvite?: boolean;
  validationRequired?: boolean;
  number?: ParticipantTypeExtraNumber;
  minimumAccessLevel?: ParticipantAccessLevel;
  maskedBy?: string;
  managedBy?: string;
  enableGroups?: boolean;
};

export enum InvitationStatus {
  None = "None",
  Queued = "Queued",
  Unregistered = "Unregistered",
  Pending = "Pending",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Error = "Error",
}

export type IParticipantEntity = IParticipantPersonalData & {
  id: number;
  applicationId: number;
  participantTypes: IParticipantTypeEntity[];
  accessLevel: ParticipantAccessLevel;
  invitation: InvitationStatus;
  qualifications: IQualificationEntity[];
  primaryContact?: boolean;
};

export type IParticipantLookupResponse = IParticipantPersonalInfoFormData & { qualifications: IQualificationEntity[] };

export type IParticipantPersonalInfoFormData = {
  email: string;
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phone: string;
  organisation: string;
} & IParticipantGroupData &
  IAddressEntity;

export type IParticipantGroupData = {
  groupList?: string;
  groupName?: string;
};

export type IParticipantSearchData = {
  name: string;
  identifier: string;
};

export type ParticipantTypeOccurrences = {
  participantTypeName: string;
  participants: IParticipantEntity[];
};

// TODO: Entity parsing should be moved to Service, Enum parsing should be wrapped with Util
/* SUGG Convert to using ParticipantUtils wrapper */

export const getParticipantNameFromNames = (firstName: string, lastName: string): string => {
  const first = !isEmpty(firstName) ? firstName.trim() : "";
  const last = !isEmpty(lastName) ? lastName.trim() : "";
  return [first, last].join(" ").trim();
};

export const getParticipantName = (participant?: IParticipantEntity): string => {
  if (!participant) {
    return "";
  }

  return getParticipantNameFromNames(participant.firstName, participant.lastName);
};

export const getGroupName = (participant: IParticipantEntity): string => {
  const groupNamesNum = !!participant.groupList ? participant.groupList.split(", ").length : 0;
  const groupName = participant.groupName;
  if (!groupName && groupNamesNum === 0) {
    return getParticipantName(participant);
  }

  const groupOwnerNum = groupNamesNum + 1;
  const name = !!groupName ? groupName : getParticipantName(participant);
  const memberLabel = groupOwnerNum === 1 ? "member" : "members";
  return `${name} (${groupOwnerNum} ${memberLabel})`;
};

export const getParticipantAndBusinessName = (participant?: IParticipantEntity): string => {
  if (!participant) {
    return "";
  }

  const participantName = getParticipantName(participant);
  const businessName = participant.organisation.trim();

  const participantAndBusinessName = [participantName, businessName].filter((str) => Boolean(str)).join(" - ");
  return participantAndBusinessName;
};

export const getParticipantInitialFromNames = (firstName: string, lastName: string): string => {
  const initialPart1 = head(firstName) || "";
  const initialPart2 = head(lastName) || "";
  const initial = [initialPart1, initialPart2].join("");
  return initial.toUpperCase();
};

export const getParticipantInitial = (participant?: IParticipantEntity): string => {
  if (!participant) {
    return "";
  }

  return getParticipantInitialFromNames(participant.firstName, participant.lastName);
};

export const accessLevelMetaData: Record<ParticipantAccessLevel, IAccessLevel> = {
  None: {
    title: "No access",
    description: "No access",
    icon: "i-status-error",
    value: ParticipantAccessLevel.None,
  },
  ReadOnly: {
    title: "Read only",
    description: "Read only access",
    icon: "i-toggle-view",
    value: ParticipantAccessLevel.ReadOnly,
  },
  Edit: {
    title: "Edit",
    description: "Edit access",
    icon: "i-format-edit",
    value: ParticipantAccessLevel.Edit,
  },
  Manage: {
    title: "Manage",
    description: "Manage access",
    icon: "i-user-settings",
    value: ParticipantAccessLevel.Manage,
  },
  "": {
    title: "Unassigned",
    description: "No access assigned",
    icon: "i-status-error",
    value: ParticipantAccessLevel.Unassigned,
  },
};

export const parseInvitationStatus: any = (value: string) =>
  enumFromValue(InvitationStatus, value, InvitationStatus.None);

export const parseParticipantAccessLevelString = (value: string): ParticipantAccessLevel => {
  switch (value) {
    case "None":
      return ParticipantAccessLevel.None;
    case "ReadOnly":
      return ParticipantAccessLevel.ReadOnly;
    case "Edit":
      return ParticipantAccessLevel.Edit;
    case "Manage":
      return ParticipantAccessLevel.Manage;
    default:
      return ParticipantAccessLevel.Unassigned;
  }
};

export const parseParticipantAccessLevel = (value: ParticipantAccessLevel | undefined): ParticipantAccessLevel => {
  if (!value) {
    return ParticipantAccessLevel.Unassigned;
  }
  return value;
};

export const rankAccessLevel = (level?: ParticipantAccessLevel) => {
  switch (level) {
    case ParticipantAccessLevel.ReadOnly:
      return 1;
    case ParticipantAccessLevel.Edit:
      return 2;
    case ParticipantAccessLevel.Manage:
      return 3;
    default:
      return 0;
  }
};

export const parseParticipantTypeExtraNumber = (value: string): ParticipantTypeExtraNumber => {
  switch (value) {
    case "ExactlyOne":
      return ParticipantTypeExtraNumber.ExactlyOne;
    case "MinimumOne":
      return ParticipantTypeExtraNumber.MinimumOne;
    case "MaximumOne":
      return ParticipantTypeExtraNumber.MaximumOne;
    default:
      return ParticipantTypeExtraNumber.Unrestricted;
  }
};

export const PARTICIPANT_TYPE_NAMES = {
  REGISTERED_OWNER: "OwnerContact",
  ADDITIONAL_OWNER: "Owner",
};
