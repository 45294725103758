import React from "react";
import { ApplicationRecordType } from "models/Application.model";
import { useStoreSelector } from "store/hooks";
import { selectJurisdictionFormNameByFormId } from "store/domain-data/jurisdiction-form/jurisdictionForm";
import { selectAuthorityNameByAuthorityId } from "store/domain-data/authority/authority";
import ApplicationCardIcon from "components/ApplicationCard/ApplicationCardIcon";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { ApplicationStatus } from "models/ApplicationStatus.model";
import FlexBox from "components/FlexBox/FlexBox";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import applicationExclamationIcon from "assets/images/application_exclamation_icon.svg";
import applicationPaymentIcon from "assets/images/application-payment-icon.svg";
import { formatDateStr } from "utils/formatDateStr";
import { DATE_FORMATS } from "constants/configs";
import { useNavigate } from "components/RouteController/functions/useNavigate";
import { urlHelper } from "components/RouteController/functions/urlHelper";
import { ApplicationDetailsSections } from "models/ApplicationDetailsSections";
import { selectApplicationV3EntityById } from "store/domain-data/application-v3/applicationV3";
import { css } from "@emotion/react";

type Props = {
  applicationId: number;
};

const OutstandingApplicationCard: React.FC<Props> = ({ applicationId }) => {
  const { t } = useTranslation();
  const { navigateTo } = useNavigate();

  const application = useStoreSelector((state) => selectApplicationV3EntityById(state, applicationId));

  const formDisplayName = useStoreSelector((state) =>
    selectJurisdictionFormNameByFormId(state, application?.formId || 0)
  );
  const authorityDisplayName = useStoreSelector((state) =>
    selectAuthorityNameByAuthorityId(state, application?.authorityId || 0)
  );

  const handleClickCard = React.useCallback(() => {
    if (!application) {
      return;
    }
    if (application.hasOutstandingRFIs) {
      navigateTo(
        urlHelper.getUrlForApplicationDetailsPage({
          applicationId: application.id,
          section: ApplicationDetailsSections.RFIs,
        })
      );
    } else {
      navigateTo(
        urlHelper.getUrlForApplicationDetailsPage({
          applicationId: application.id,
          section: ApplicationDetailsSections.Payments,
        })
      );
    }
  }, [application, navigateTo]);

  const statusLabel = React.useMemo(() => {
    if (!application) {
      return;
    }
    if (application.hasOutstandingRFIs) {
      return t(`Outstanding RFIs`);
    }
    if (application.hasOutstandingPayments) {
      return t("Awaiting Payments");
    }
    return "";
  }, [application, t]);

  if (!application) {
    return null;
  }

  const {
    hasOutstandingRFIs,
    applicationNumber,
    consentNumber,
    hasOutstandingPayments,
    address,
    modifiedDate,
  } = application;

  return (
    <Card onClick={handleClickCard} data-testid={"OutstandingApplicationCard"}>
      <CardActionArea>
        <StyledCardContent>
          <ApplicationCardIcon
            showAttentionIcon={true}
            recordType={ApplicationRecordType.Consent}
            status={ApplicationStatus.InProgress}
          />
          <StyledPrimaryInformation>
            <StyledFormTitle>{formDisplayName}</StyledFormTitle>
            <FlexBox spacing={2} alignItems={"center"}>
              {consentNumber && <StyledApplicationID>{consentNumber}</StyledApplicationID>}
              {!consentNumber && <StyledApplicationID>{applicationNumber}</StyledApplicationID>}
            </FlexBox>
            <StyledAddress>
              {address} • {authorityDisplayName}
            </StyledAddress>
            <StyledModifiedDate>
              {t(`Modified on {{date}}`, { date: formatDateStr(modifiedDate, DATE_FORMATS.FULL_DATE) })}
            </StyledModifiedDate>
          </StyledPrimaryInformation>
          <StyledStatusInformation>
            <StyledStatusLabel>{statusLabel}</StyledStatusLabel>
            <StyledStatusIcons>
              {hasOutstandingRFIs && (
                <StyledImage src={applicationExclamationIcon} alt={"Application has outstanding RFIs"} />
              )}
              {hasOutstandingPayments && (
                <StyledImage src={applicationPaymentIcon} alt={"Application has outstanding Payments"} />
              )}
            </StyledStatusIcons>
          </StyledStatusInformation>
        </StyledCardContent>
      </CardActionArea>
    </Card>
  );
};

const StyledPrimaryInformation = styled("div")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-width: 110px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: flex-start;
  `
);

const StyledFormTitle = styled("div")(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.objective.dark.neutral};
  `
);

const StyledApplicationID = styled("div")(
  ({ theme }) => css`
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: ${theme.palette.text.primary};
  `
);

const StyledAddress = styled("div")(
  ({ theme }) => css`
    overflow: hidden;
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.palette.text.secondary};
    margin-bottom: 4px;
  `
);

const StyledModifiedDate = styled("div")(
  ({ theme }) => css`
    font-size: 12px;
    color: ${theme.palette.text.tertiary};

    line-height: 1.4;
  `
);

const StyledStatusInformation = styled("div")(
  ({ theme }) => css`
    min-height: 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${theme.mixins.flexGap("8px")}
  `
);

const StyledStatusLabel = styled("div")(
  ({ theme }) => css`
    display: flex;
  `
);

const StyledStatusIcons = styled("div")(
  ({ theme }) => css`
    display: flex;
    ${theme.mixins.flexGap("8px")}
  `
);

const StyledImage = styled("img")(
  ({ theme }) => css`
    max-width: 100%;
    max-height: 100%;
  `
);

const StyledCardContent = styled(CardContent)(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 12px 24px !important;
    ${theme.mixins.flexGap("16px")}

    ${theme.breakpoints.down("xs")} {
      flex-wrap: wrap;
    }
  `
);

export default React.memo(OutstandingApplicationCard);
